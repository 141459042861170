import React, { useMemo } from 'react';
import { useSelector } from 'react-redux'
import { Field } from 'react-final-form';
import { get } from 'lodash'

import { companySelector, country as countrySelector, electronicInvoicing } from '../../../../selectors/company'
import { regimes as regimesSelector } from '../../../../selectors/regimes'
import regimesLabel from '../../../countriesData/general/regimesLabel'
import { renderSelect } from '../../fields/V0/Fields';
import { renderField } from '../../fields/V2';
import { getRegimeByKey, getRegimeOptionsByRFC } from '../../../countriesData/mexico/regimes';

const RegimeMexicoSingle = props => {
  const regimes = useSelector(regimesSelector)
  const country = useSelector(countrySelector)
  
  const filteredRegimes = useMemo(() => {
    return regimes.filter(regime => get(regime, "kindOfPerson", '') === "LEGAL_ENTITY")
  }, [regimes, props.values.identification]);

  return <Field
    name="regime"
    options={filteredRegimes}
    component={renderSelect}
    className={"col-12"}
    label={regimesLabel(country)}
    getOptionLabel={option => option.value}
    getOptionValue={option => option.key}
    required
  />
}

const RegimeMexicoMulti = props => {
  const country = useSelector(countrySelector)
  const favoriteRegimeCompany = get(props, 'values.favoriteRegime', '');
  const rfc = get(props, 'values.identification', '') || '';

  const regimeOptions = useMemo(() => {
    if (rfc.length < 12) {
      const defaultRegime = [];
      defaultRegime.push(getRegimeByKey('NO_REGIME'));
      defaultRegime.push(getRegimeByKey('SIMPLIFIED_REGIME'));
      return defaultRegime.map((option) => ({
        text: option.label,
        value: option.value,
      }));
    }

    const options = getRegimeOptionsByRFC(get(props, 'values.identification', ''))?.map(
      (option) => ({
        text: option.label,
        value: option.value,
      })
    );

    return options?.sort((a, b) => {
      if (a.value === 'SIMPLIFIED_REGIME') return -1;
      if (b.value === 'SIMPLIFIED_REGIME') return 1;
      return 0;
    });
  }, [rfc]);

  return (
    <div className="col-12 regime-company">
      <Field
        name="regimes"
        options={regimeOptions}
        component={renderField}
        canFavorite
        fieldType='select'
        multiple
        changeAction={(value) => {
          if (value?.isFavorite) {
            props.form.change('favoriteRegime', value?.value);
          }
        }}
        label={regimesLabel(country)}
        defaultFavoriteOption={favoriteRegimeCompany}
        required
      />
    </div>
  )
}

const RegimeMexico = props => {
  const rfc = get(props, 'values.identification', '') || '';

  if (rfc.length !== 13) {
    return <RegimeMexicoSingle {...props} />
  }
  else {
    return <RegimeMexicoMulti {...props} />
  }
}

const RenderRegime = props => {
  const regimes = useSelector(regimesSelector)
  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing)
  const identificationType = get(props, 'values.identification.type.key', '');

  if (country === 'colombia' && !!isElectronic && identificationType === 'NIT')
    return <Field
      name="regime"
      options={regimes}
      component={renderSelect}
      className="col-12"
      label={regimesLabel(country)}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      required
    />

  if (country === 'mexico' && !!isElectronic)
    return <RegimeMexico {...props} />

  if (country === 'peru')
    return (
      <Field
        name='regime'
        options={regimes}
        component={renderSelect}
        className={'col-12'}
        label={regimesLabel(country)}
        getOptionLabel={(option) => option.value}
        getOptionValue={(option) => option.key}
        required
      />
    );

  if (country === 'costaRica')
    return <Field
      name="regime"
      options={regimes}
      component={renderSelect}
      className={"col-md-6"}
      label={regimesLabel(country)}
      getOptionLabel={option => option.value}
      getOptionValue={option => option.key}
      required
    />

  return null;
}

export default RenderRegime;