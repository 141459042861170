export const allRegimes = {
  NO_REGIME: {
    label: 'Sin régimen',
    value: 'NO_REGIME',
    code: '',
    invoiceType: 'invoice|invoiceLeasehold',
    id: 1,
  },
  BUSINESS_ACTIVITIES_REGIME: {
    label: 'Personas Físicas con Actividades Empresariales y Profesionales',
    value: 'BUSINESS_ACTIVITIES_REGIME',
    code: 612,
    invoiceType: 'invoice|invoiceFees|invoiceExport',
    id: 2,
  },
  FISCAL_INCORPORATION_REGIME: {
    label: 'Incorporación Fiscal',
    value: 'FISCAL_INCORPORATION_REGIME',
    code: 621,
    invoiceType: 'invoice||invoiceExport',
    id: 3,
  },
  LEASEHOLD_REGIME: {
    label: 'Arrendamiento',
    value: 'LEASEHOLD_REGIME',
    code: 606,
    invoiceType: 'invoiceLeasehold',
    id: 5,
  },
  REGIME_OF_THE_TECHNOLOGICAL_PLATFORMS_INCOME_ACTIVITIES: {
    label:
      'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    value: 'REGIME_OF_THE_TECHNOLOGICAL_PLATFORMS_INCOME_ACTIVITIES',
    code: 625,
    invoiceType: 'invoice|invoiceExport',
    id: 6,
  },
  SALARIED_REGIME: {
    label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',
    value: 'SALARIED_REGIME',
    code: 605,
    invoiceType: 'invoice',
    id: 7,
  },
  GENERAL_REGIME_OF_MORAL_PEOPLE_LAW: {
    label: 'General de Ley Personas Morales',
    value: 'GENERAL_REGIME_OF_MORAL_PEOPLE_LAW',
    code: 601,
    invoiceType: 'invoice|invoiceLeasehold|invoiceExport',
    id: 8,
  },
  REGIME_OF_MORAL_PEOPLE_NOT_PROFIT: {
    label: 'Personas Morales con Fines no Lucrativos',
    value: 'REGIME_OF_MORAL_PEOPLE_NOT_PROFIT',
    code: 603,
    invoiceType: 'invoice|invoiceLeasehold|invoiceExport',
    id: 9,
  },
  PRIMARY_SECTOR_REGIME: {
    label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras (AGAPES)',
    value: 'PRIMARY_SECTOR_REGIME',
    code: 622,
    invoiceType: 'invoice|invoiceLeasehold|invoiceExport',
    id: 10,
  },
  REGIME_OF_THE_COORDINATED: {
    label: 'Coordinados',
    value: 'REGIME_OF_THE_COORDINATED',
    code: 624,
    invoiceType: 'invoice|invoiceLeasehold|invoiceExport',
    id: 11,
  },
  REGIME_OF_COOPERATIVE_PRODUCTION_SOCIETIES: {
    label:
      'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    value: 'REGIME_OF_COOPERATIVE_PRODUCTION_SOCIETIES',
    code: 620,
    invoiceType: 'invoice|invoiceLeasehold|invoiceExport',
    id: 12,
  },
  REGIME_OF_TRUST: {
    label: 'Regimen simplificado de confianza (RESICO)',
    value: 'REGIME_OF_TRUST',
    code: 626,
    invoiceType: 'invoice|invoiceLeasehold|invoiceFees|invoiceExport',
    id: 13,
  },
  SIMPLIFIED_REGIME: {
    label: 'Sin obligaciones fiscales',
    value: 'SIMPLIFIED_REGIME',
    code: 616,
    id: 14,
  },
  SOCIETIES_OPTIONAL_REGIME: {
    label: 'Opcional para Grupos de Sociedades',
    value: 'SOCIETIES_OPTIONAL_REGIME',
    code: 623,
    invoiceType: 'invoice|invoiceLeasehold|invoiceExport',
    id: 15,
  },
  DIVIDEND_INCOME: {
    label: 'Ingresos por Dividendos (socios y accionistas)',
    value: 'DIVIDEND_INCOME',
    code: 611,
    kindOfPerson: 'PERSON_ENTITY',
    id: 16,
  },
  INTEREST_INCOME_REGIME: {
    label: 'Ingresos por intereses',
    value: 'INTEREST_INCOME_REGIME',
    code: 614,
    kindOfPerson: 'PERSON_ENTITY',
    invoiceType: 'invoice',
    id: 17,
  },
  OTHER_INCOME_REGIME: {
    label: 'Demás ingresos',
    value: 'OTHER_INCOME_REGIME',
    code: 608,
    kindOfPerson: 'PERSON_ENTITY',
    invoiceType: 'invoice',
    id: 18,
  },
  FOREIGNERS_WITHOUT_PERMANENT_ESTABLISHMENT_REGIME: {
    label:
      'Régimen residentes en el Extranjero sin Establecimiento Permanente en México',
    value: 'FOREIGNERS_WITHOUT_PERMANENT_ESTABLISHMENT_REGIME',
    code: 610,
    invoiceType: 'invoice',
    id: 19,
  },
};

export function getRegimeOptionsByRFC(rfc) {
  const rfcLength = rfc.length;

  if (rfcLength === 12) {
    return [
      allRegimes.NO_REGIME,
      allRegimes.GENERAL_REGIME_OF_MORAL_PEOPLE_LAW,
      allRegimes.REGIME_OF_MORAL_PEOPLE_NOT_PROFIT,
      allRegimes.PRIMARY_SECTOR_REGIME,
      allRegimes.REGIME_OF_THE_COORDINATED,
      allRegimes.REGIME_OF_COOPERATIVE_PRODUCTION_SOCIETIES,
      allRegimes.REGIME_OF_TRUST,
      allRegimes.SIMPLIFIED_REGIME,
      allRegimes.SOCIETIES_OPTIONAL_REGIME,
    ];
  } else if (rfcLength === 13) {
    return [
      allRegimes.NO_REGIME,
      allRegimes.BUSINESS_ACTIVITIES_REGIME,
      allRegimes.FISCAL_INCORPORATION_REGIME,
      allRegimes.LEASEHOLD_REGIME,
      allRegimes.REGIME_OF_THE_TECHNOLOGICAL_PLATFORMS_INCOME_ACTIVITIES,
      allRegimes.SALARIED_REGIME,
      allRegimes.REGIME_OF_TRUST,
      allRegimes.SIMPLIFIED_REGIME,
      allRegimes.DIVIDEND_INCOME,
    ];
  } else {
    return [allRegimes.SIMPLIFIED_REGIME];
  }
}

export function getRegimeOptionsByKeys (keys) {
  return keys.map(key => allRegimes[key])
}

export function getRegimeByKey (key) {
  return allRegimes[key]
}
