import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { country as countrySelector } from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';
import { QRCode, Typography } from '@alegradev/smile-ui-react';
import dayjs from 'dayjs';
const alegraLogo = `
  <svg
    width='38'
    height='38'
    viewBox='0 0 38 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.5392 29.0031C27.6823 29.442 20.2385 30.9447 14.5832 29.406C10.3583 28.2533 4.72331 24.9618 2.84993 23.8258C2.50727 23.618 2.43079 23.1597 2.67881 22.8443L9.62859 14.0064C9.9233 13.6316 10.5049 13.6675 10.7587 14.0714C11.8841 15.8626 14.7332 20.1099 18.1268 22.9997C22.6791 26.8784 26.3572 27.9672 28.5646 28.2192C28.8374 28.2504 29.3962 28.5642 28.5392 29.0031Z'
      fill='#00D6BC'
    />
    <path
      d='M26.5329 25.9247C25.8282 25.7692 20.8028 23.0807 18.2573 19.4857C16.4248 16.8936 14.7769 12.522 14.1724 10.8206C14.0379 10.4422 14.2685 10.0387 14.6596 9.95319L22.4558 8.24933C22.9207 8.14774 23.3479 8.54696 23.2917 9.02104C23.0899 10.7223 22.7864 14.3335 23.3513 17.4689C24.147 21.8922 25.7607 24.2814 26.9197 25.4827C27.063 25.6312 27.2376 26.0801 26.5329 25.9247Z'
      fill='#00D6BC'
    />
    <path
      d='M27.3288 23.8793C27.0064 23.4984 25.3654 19.9144 25.464 16.8722C25.5324 14.8062 26.349 11.9408 26.7578 10.6232C26.8768 10.2396 27.3043 10.0561 27.6672 10.2258L32.1701 12.3303C32.6014 12.5319 32.7006 13.1109 32.3741 13.458C31.5041 14.3828 30.0173 16.0981 29.1019 17.9004C27.6972 20.6637 27.5889 22.6528 27.7253 23.7987C27.7422 23.9403 27.6512 24.2602 27.3288 23.8793Z'
      fill='#00D6BC'
    />
    <path
      d='M29.1953 22.7543C29.1819 22.4652 29.6932 20.2392 30.8111 18.8795C31.5326 18.0031 32.8175 17.0359 33.5036 16.5476C33.7485 16.3733 34.0856 16.4611 34.2237 16.7287L35.4094 19.0275C35.5734 19.3455 35.3669 19.7357 35.0151 19.7956C34.2709 19.9222 33.0944 20.1782 32.1164 20.6427C30.4981 21.4108 29.747 22.2843 29.4055 22.8581C29.3633 22.9291 29.2088 23.0434 29.1953 22.7543Z'
      fill='#00D6BC'
    />
  </svg>
`;
const alegraLogoBase64 = `data:image/svg+xml;base64,${btoa(alegraLogo)}`;
export const SelfInvoice = ({ invoice }) => {
  const country = useSelector(countrySelector);
  if (!get(invoice, 'uniqueCode', null) && country !== COUNTRIES.MEXICO)
    return null;

  if (!get(invoice, 'qrCodeSelfInvoicingContent', null)) return null;

  const formatDate = (date) => {
    if (!date) return null;
    try {
      const dateConverted = dayjs(date);
      return dateConverted.format('DD/MM/YYYY');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className='w-100 text-center py-2'>
      <QRCode
        ecLevel='Q'
        value={get(invoice, 'qrCodeSelfInvoicingContent')}
        logo={alegraLogoBase64}
        size={140}
        logoWidth={30}
        logoHeight={30}
        qrStyle='dots'
        logoPaddingStyle='circle'
        removeQrCodeBehindLogo
      />
      <Typography
        type='body-1-bold'
        text={'Código: ' + get(invoice, 'uniqueCode', null)}
      />
      <p className='text-center body-2-regular mt-2'>
        Escanea el QR para solicitar tu factura o ingresa a{' '}
        <b>aleg.la/mifactura</b> con el código de tu ticket. Válido hasta el
        {` ${formatDate(get(invoice, 'validDateToAssociateInvoice', null))}`}
      </p>
    </div>
  );
};

export default SelfInvoice;
