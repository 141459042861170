import {
  Button,
  SimpleTable,
  Space,
  TableCellImage,
  Typography,
} from '@alegradev/smile-ui-react';
import {
  MemoizedField,
  renderField,
} from '../../../../../components/forms/fields/V2';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { allItems } from '../../../../../selectors/items';
import { I18n } from 'aws-amplify';
import { capitalize, get } from 'lodash';
import { formatMoney } from '../../../../../utils';
import { taxesSelector } from '../../../../../reducers/taxes';
import { IconX } from '@tabler/icons-react';

const productsTransformer = (products) => {
  return products.map((product) => {
    if (!product?.inventory?.unitCost) return null;
    return {
      id: product?.id,
      name: product?.name,
      barcode: product?.barcode,
      reference: product?.reference,
      price: product?.inventory?.unitCost || 0,
      tax: product?.tax,
      quantity: 1,
      observations: '',
      discount: 0,
    };
  });
};

const filterProducts = (inputValue, items) => {
  return productsTransformer(items)
    .filter((product) => product)
    .filter(
      (product) =>
        product?.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
        product?.barcode?.toLowerCase().includes(inputValue.toLowerCase())
    )
    .slice(0, 30);
};

const Products = memo(({ fields }) => {
  const items = useSelector(allItems);
  const taxes = useSelector(taxesSelector.selectAll);

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterProducts(inputValue, items));
    }, 1000);
  };

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('product', 'Producto')),
        accessor: 'product',
        width: '30%',
        Cell: ({ row }) => {
          if (row?.values?.product?.id) {
            const product = row?.values?.product;
            return <TableCellImage text={get(product, 'name')} description={`${formatMoney(get(product, 'price'))}`} />;
          }
          return (
            <MemoizedField
              name={`${fields.name}[${row.index}].product`}
              render={renderField}
              loadOptions={loadOptions}
              defaultOptions
              async
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option}
              fieldType='rselect'
              menuSize='small'
            />
          );
        },
      },
      {
        Header: capitalize(I18n.get('price', 'Precio')),
        accessor: 'product.price',
        width: '10%',
        Cell: ({ row }) => (
          <MemoizedField
            name={`${fields.name}[${row.index}].product.price`}
            render={renderField}
            type='number'
            disabled={
              row?.values?.product
                ? Object.keys(row?.values?.product).length === 0
                : true
            }
          />
        ),
      },
      {
        Header: capitalize(I18n.get('discountPercentage', 'Descuento %')),
        accessor: 'product.discount',
        width: '10%',
        Cell: ({ row }) => (
          <MemoizedField
            name={`${fields.name}[${row.index}].product.discount`}
            render={renderField}
            disabled={
              row?.values?.product
                ? Object.keys(row?.values?.product).length === 0
                : true
            }
            type='number'
          />
        ),
      },
      {
        Header: capitalize(I18n.get('tax', 'Impuesto')),
        accessor: 'product.tax',
        width: '10%',
        Cell: ({ row }) => (
          <MemoizedField
            name={`${fields.name}[${row.index}].product.tax`}
            render={renderField}
            fieldType='rselect'
            options={taxes || []}
            getOptionLabel={(option) =>
              `${option.name} - ${option.percentage}%`
            }
            menuSize='small'
            getOptionValue={(option) => option}
            isDisabled={
              row?.values?.product
                ? Object.keys(row?.values?.product).length === 0
                : true
            }
          />
        ),
      },
      {
        Header: capitalize(I18n.get('quantity', 'Cantidad')),
        accessor: 'product.quantity',
        width: '10%',
        Cell: ({ row }) => (
          <MemoizedField
            name={`${fields.name}[${row.index}].product.quantity`}
            render={renderField}
            type='number'
            disabled={
              row?.values?.product
                ? Object.keys(row?.values?.product).length === 0
                : true
            }
            min={1}
          />
        ),
      },
      {
        Header: capitalize(I18n.get('observations', 'Observaciones')),
        accessor: 'product.observations',
        width: '10%',
        Cell: ({ row }) => (
          <>
            <Space height={6} />
            <MemoizedField
              name={`${fields.name}[${row.index}].product.observations`}
              render={renderField}
              fieldType='textarea'
              placeholder='Observaciones'
              size='small'
              maxHeight={70}
              disabled={
                row?.values?.product
                  ? Object.keys(row?.values?.product).length === 0
                  : true
              }
            />
            <Space height={6} />
          </>
        ),
      },
      {
        Header: capitalize(I18n.get('total', 'Total')),
        accessor: 'product.total',
        width: '10%',
        Cell: ({ row }) => {
          const handleRemoveField = () => {
            fields.remove(row.index);
          };
          const buttonRemove = (
            <div className='table-form-remove-record-button'>
              <Button
                onlyIcon={IconX}
                size='small'
                onClick={handleRemoveField}
                type='button'
                emphasis='text'
              />
            </div>
          );

          if (!row.values.product)
            return (
              <>
                <Typography type='body-3-regular' text={formatMoney(0)} />
                {buttonRemove}
              </>
            );
          const { price, discount, quantity } = row.values.product;
          const [
            productPrice,
            productDiscount,
            productQuantity,
            productTaxPercentage,
          ] = [
            Number(price || 0),
            Number(discount || 0),
            Number(quantity || 1),
            Number(row?.values?.product?.tax?.percentage || 0),
          ];
          const TOTAL_DICOUNT =
            productPrice * (Number(productDiscount || 0) / 100);
          const TOTAL_TAX = productPrice * (productTaxPercentage / 100);
          const TOTAL_PRICE =
            (productPrice + TOTAL_TAX - TOTAL_DICOUNT) * productQuantity;
          return (
            <>
              <Typography
                type='body-3-regular'
                text={formatMoney(TOTAL_PRICE)}
              />
              {buttonRemove}
            </>
          );
        },
      },
    ],
    [fields.name, fields.remove]
  );

  return <SimpleTable columns={columns} data={fields.value || []} />;
});

export default Products;
