import React, { useEffect, useMemo } from 'react'
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash'

import * as mutations from '../../../graphql/mutations'
import { formError } from '../../../utils/errors';
import { companyTransformer } from '../../../utils/transformers';
import { setUser } from '../../../reducers/auth'
import { hasPermissionTo } from '../../../selectors/auth'
import {
  localSettings,
  isOnlyInvoicingPlan,
  country as countrySelector,
  companySelector,
  electronicInvoicing,
  regimes as regimesSelector,
  favoriteRegime
} from '../../../selectors/company'
import initialValues from '../../forms/company/BasicData/initialValues';
import { getCategoryById, defaultInCategory, defaultOutCategory } from '../../../selectors/categories'
import { getCompanyRegime } from '../../../selectors/regimes'
import { allEconomicActivities, mainActivity } from '../../../selectors/economicActivity';
import { toast } from '../../../utils'
import { transform, validate } from '../../forms/company/utils'
import Body from './FormBody';
import Header from '../common/Header';
import Bottom from '../common/Bottom';
import { APIGraphqlSelector } from '../../../selectors/app';
import { COUNTRIES } from '../../../utils/enums/countries';

const Company = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo)
  const posSettings = useSelector(localSettings)
  const country = useSelector(countrySelector)
  const isElectronic = useSelector(electronicInvoicing)
  const onlyInvoicing = useSelector(isOnlyInvoicingPlan)
  const company = useSelector(companySelector)
  const companyRegime = useSelector(getCompanyRegime)
  const companyIncome = useSelector(getCategoryById(posSettings.idCategoryDefaultIncome))
  const defaultIncome = useSelector(defaultInCategory)
  const companyExpense = useSelector(getCategoryById(posSettings.idCategoryDefaultExpense))
  const defaultExpense = useSelector(defaultOutCategory)
  const economicActivities = useSelector(allEconomicActivities);
  const mainEconomicActivity = useSelector(mainActivity)
  const favoriteRegimeCompany = useSelector(favoriteRegime)
  const APIGraphql = useSelector(APIGraphqlSelector);

  useEffect(() => {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/settings/company',
      'virtualPageTitle': 'Company Settings'
    });
  }, [])

  const submit = async values => {
    const transformedValues = transform(values, { country, isElectronic })
    try {
      const company = country === 'costaRica' ? (
        {
          ...companyTransformer(transformedValues),
          economicActivities: economicActivities || [],
          economicActivity: mainEconomicActivity ? mainEconomicActivity.code : ''
        }
      ) : (
        { ...companyTransformer(transformedValues) }
      )
      const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
        user: {
          company: {
            ...company
          }
        }
      }))
      if (!!get(response, 'data.updateUser.id', null))
        dispatch(setUser(get(response, 'data.updateUser')))

      if (!values.noRedirect) {
        toast.success({ title: I18n.get('companyUpdatedSuccessfully', 'compañía configurada con éxito') })
        history.push('/settings')
      }

    } catch (error) {
      return formError(error, I18n.get('updateCompanyError', 'ocurrió un error al intentar actualizar los datos de la compañía'))
    }
  }

  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Form
          onSubmit={submit}
          validate={(values) => validate(values, { country, isElectronic })}
          initialValues={{
            ...initialValues({ country, company, isElectronic }),
            ...posSettings,
            idCategoryDefaultIncome: !onlyInvoicing
              ? !!companyIncome ? companyIncome : defaultIncome
              : defaultIncome,
            idCategoryDefaultExpense: !onlyInvoicing
              ? !!companyExpense ? companyExpense : defaultExpense
              : defaultExpense,
            shiftsEnabled: !onlyInvoicing ? posSettings.shiftsEnabled : false,
            regime: companyRegime,
            favoriteRegime: favoriteRegimeCompany
          }}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, values, form, submitting, submitError, pristine }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Header
                title={<p className="text-title-secundary">{I18n.get('companySetting', 'configuración de empresa')}</p>}
                subtitle={I18n.get('companySettingSubtitle', 'configura tu empresa para una mejor facturación en todas las terminales')}
                error={submitError}
                info={!can('edit', 'company') && I18n.get(('userNotAllowed.company.edit', 'no tienes permisos suficientes para editar la información de la compañía'))}
              />

              <Body
                values={values}
                form={form}
                country={country}
                isElectronic={isElectronic}
              />

              <Bottom
                disabled={
                  (pristine && get(values, 'favoriteRegime', null) === favoriteRegimeCompany) ||
                  !can('edit', 'company')
                }
                submitting={submitting}
                onClose={() => history.push('/settings')}
              />
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

export default Company;