import { useQuery } from '@tanstack/react-query';
import { I18n } from 'aws-amplify';

import { fetchBill, fetchBills } from './queries';
import { billKeys, DEFAULT_QUERY_PARAMS } from './utils';

export const useBillsQuery = (metadata, api) => {
  return useQuery({
    queryKey: [billKeys.all, metadata],
    queryFn: () => fetchBills(metadata, api),
    enabled: !!metadata,
    ...DEFAULT_QUERY_PARAMS({
      errorTitle: I18n.get(
        'BillsLoadError',
        'Sucedió un error cargando las facturas'
      ),
    }),
  });
};

export const useBillQuery = (id, api, raw) => {
  return useQuery({
    queryKey: [billKeys.bill, id],
    queryFn: () => fetchBill(id, api, raw),
    enabled: !!id,
    ...DEFAULT_QUERY_PARAMS({
      errorTitle: I18n.get(
        'billLoadError',
        'Sucedió un error cargando la factura'
      ),
    }),
  });
};
