import { Table } from '@alegradev/smile-ui-react';
import { productsColumns } from './columns';

function BillDetailBodyProducts({ bill }) {
  return (
    <Table
      data={bill?.products || []}
      simple
      hidePagination
      hideToolbar
      columns={productsColumns}
    />
  );
}

export default BillDetailBodyProducts;
