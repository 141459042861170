import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useHistory, useLocation } from 'react-router-dom';

import { keyMap } from '../utils/platform';
import { setSearchProductType } from '../reducers/app';
import { openModal } from '../reducers/modals';
import { openSideModal } from '../reducers/sideModals';
import { hasPermissionTo, userSelector } from '../selectors/auth';
import {
  country as countrySelector,
  shiftsEnabled,
  isOnlyInvoicingPlan,
} from '../selectors/company';
import { itemsCount } from '../selectors/activeInvoice';
import Home from './home/Home';
import Invoices from './invoices/Invoices';
import Shifts from './shifts/Shifts';
import Items from './items/Items';
import Payments from './payments/Payments';
import Refunds from './refunds/Refunds';
import Statistics from './statistics/Statistics';
import Inventory from './inventory/Inventory';
import Settings from './settings/Settings';
import Membership from './membership/Membership';
import { globalState as globalStateSelector } from '../selectors/app';
import store from 'store2';
import { COUNTRIES } from '../utils/enums/countries';
import { ElectronicDocuments } from '../pages/electronicDocuments';
import ContactsRouter from '../pages/contacts/router';
import { GlobalInvoicesRouter } from '../pages/globalInvoices';
import NewInvoices from '../pages/invoices/main';
import { sendNewGTMEvent } from '../reducers/company';
import { get } from 'lodash';
import { initialState } from '../reducers/items';
import { showNewInvoiceModal } from './modals/newInvoice/utils';
import { useNewInvoiceModal } from '../hooks/useNewInvoiceModal';
import DownloadAppPage from '../pages/desktop/download';
import BillsRouter from '../pages/bills/router';
import { checkCanDownloadApp } from '../utils/desktop';
import { useDetectOS } from '../hooks/useDetectOS';

/**
 * Updates the global state in the local storage for persistence. If the number of items exceeds a threshold,
 * it retrieves and preserves certain parts from the stored global state.
 * @param {Object} globalState - The current global state object.
 * @param {Object} store - The store instance managing the global state.
 * @param {number} [threshold=120] - The item count threshold to trigger state preservation.
 * @param {Object} initialStateItems - The initial state of the items reducer.
*/
const updateGlobalState = (globalState, store, threshold = 120, initialStateItems) => {
  let items = get(globalState, 'items', initialStateItems);
  const itemsCount = get(globalState, 'items.ids.length', 0);

  if (itemsCount > threshold) {
    const storedItems = get(store.get('globalState'), "items");
    items = {
      ...items,
      entities: get(storedItems, "entities"),
      filters: get(storedItems, "filters"),
      ids: get(storedItems, "ids")
    }
  }
  store.set('globalState', { ...globalState, items });
}

const Main = () => {
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  let history = useHistory();
  let location = useLocation();
  const isShiftsEnabled = useSelector(shiftsEnabled);
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan);
  const activeInvoiceItems = useSelector(itemsCount);
  const country = useSelector(countrySelector);
  const globalState = useSelector(globalStateSelector);
  const { openNewInvoiceModal } = useNewInvoiceModal();
  const user = useSelector(userSelector);
  const installerType = useDetectOS();

  useEffect(() => {
    updateGlobalState(globalState, store, 120, initialState);
  }, [globalState]);

  configure({ ignoreTags: [] });

  const handlers = {
    GOTO_HOME: (event) => {
      event.preventDefault();
      if (location.pathname !== '/') history.push('/');
    },
    GOTO_PAYMENTS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/payments') history.push('/payments');
    },
    GOTO_REFUNDS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/refunds') history.push('/refunds');
    },
    GOTO_SHIFTS: (event) => {
      event.preventDefault();
      if (location.pathname !== '/shifts' && isShiftsEnabled)
        history.push('/shifts');
    },
    OPEN_SHORTCUT_HELP: (event) => {
      event.preventDefault();
      dispatch(openModal({ modal: 'shortcuts' }));
    },
    OPEN_NEW_ITEM: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(openModal({ modal: 'item' }));
    },
    OPEN_NEW_CLIENT: (event) => {
      event.preventDefault();
      if (location.pathname === '/')
        dispatch(openSideModal({ sideModal: 'contact' }));
    },
    OPEN_PAYMENT_MODAL: (event) => {
      event.preventDefault();
      if (location.pathname === '/' && !!activeInvoiceItems)
        if(showNewInvoiceModal(country)) {
          return openNewInvoiceModal()
        }
        return dispatch(openModal({ modal: 'invoice' }));
    },
    FOCUS_NORMAL_SEARCH: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(setSearchProductType('text'));
    },
    FOCUS_BARCODE_SEARCH: (event) => {
      event.preventDefault();
      if (location.pathname === '/') dispatch(setSearchProductType('barcode'));
    },
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/invoices':
        dispatch(sendNewGTMEvent('pos-invoices-visited'));
        break;
      case '/refunds':
        dispatch(sendNewGTMEvent('pos-refunds-visited'));
        break;
      case '/settings/station':
        dispatch(sendNewGTMEvent('pos-current-station-visited'));
        break;
      case '/settings/stations':
        dispatch(sendNewGTMEvent('pos-stations-visited'));
        break;
      case '/settings':
        dispatch(sendNewGTMEvent('pos-settings-visited'));
        break;
      case '/settings/sellers':
        dispatch(sendNewGTMEvent('pos-sellers-visited'));
        break;
      case '/payments':
        dispatch(sendNewGTMEvent('pos-cash-flow-visited'));
        break;
      case '/download/desktop':
        dispatch(sendNewGTMEvent('pos-download-app-visited'));
        break;
    }
  }, [location]);

  return (
    <div className='main main-new-color'>
      {!onlyInvoicingPlan && (
        <GlobalHotKeys
          keyMap={keyMap(country)}
          handlers={handlers}
          allowChanges
        />
      )}

      <Switch>
        <Route path='/' exact component={Home} />

        {!onlyInvoicingPlan && (
          <Route
            path='/invoices'
            component={country === COUNTRIES.MEXICO ? NewInvoices : Invoices}
          />
        )}

       
        <Route path='/global-invoices' component={GlobalInvoicesRouter} />

        <Route path='/contacts' component={ContactsRouter} />

        <Route path='/bills' component={BillsRouter} />

        {!!isShiftsEnabled &&
          !onlyInvoicingPlan &&
          can('index', 'pos-cashier') && (
            <Route path='/shifts' component={Shifts} />
          )}

        {!onlyInvoicingPlan && <Route path='/items' component={Items} />}

        {!onlyInvoicingPlan && <Route path='/payments' component={Payments} />}

        {!onlyInvoicingPlan && <Route path='/refunds' component={Refunds} />}

        {!onlyInvoicingPlan && (
          <Route path='/statistics' component={Statistics} />
        )}

        {country === COUNTRIES.COLOMBIA && (
          <Route path='/electronic-documents' component={ElectronicDocuments} />
        )}

        <Route path='/plan' component={Membership} />

        <Route path='/inventory' component={Inventory} />

        <Route path='/settings' component={Settings} />

        {checkCanDownloadApp(installerType, user.email) && <Route path='/download/desktop' component={DownloadAppPage} />}

        <GlobalInvoicesRouter />

        <Redirect to='/' />
      </Switch>
    </div>
  );
};

export default Main;
