import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';

import { PageHeading } from '@alegradev/smile-ui-react';

function InvoicesHeader() {
  const history = useHistory();

  return (
    <PageHeading
      title={I18n.get('salesHistory', 'Historial de ventas')}
      breadcrumb={[
        {
          title: I18n.get('incomes', 'Ingresos'),
        },
        {
          title: I18n.get('salesHistory', 'Historial de ventas'),
        },
      ]}
      description={I18n.get(
        'salesHistoryInfo',
        'Gestiona la información de tus ventas y conoce el detalle de cada uno de tus comprobantes.'
      )}
    />
  );
}

export default InvoicesHeader;
