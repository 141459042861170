import { capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { TableCellRegular, TableCellImage } from '@alegradev/smile-ui-react';
import { formatMoney } from '../../../../utils';

export const productsColumns = [
  {
    Header: capitalize(I18n.get('product', 'Producto')),
    accessor: 'item',
    width: '28%',
    Cell: ({ value }) => (
      <TableCellImage image={value?.image} text={value?.name} description="asdas" />
    ),
  },
  {
    Header: capitalize(I18n.get('price', 'Precio')),
    accessor: 'price',
    width: '12%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value)} />,
  },
  {
    Header: capitalize(I18n.get('discount', 'Descuento')),
    accessor: 'discount',
    width: '12%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value)} />,
  },
  {
    Header: capitalize(I18n.get('tax', 'Impuesto')),
    accessor: 'tax',
    width: '12%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value)} />,
  },
  {
    Header: capitalize(I18n.get('quantity', 'Cantidad')),
    accessor: 'quantity',
    width: '12%',
    Cell: ({ value }) => <TableCellRegular value={value?.toString()} />,
  },
  {
    Header: capitalize(I18n.get('observations', 'Observaciones')),
    accessor: 'observations',
    width: '12%',
    Cell: ({ value }) => <TableCellRegular value={value} />,
  },
  {
    Header: capitalize(I18n.get('total', 'Total')),
    accessor: 'total',
    width: '12%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value)} />,
  },
];
