import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';

import { renderField } from '../../../../../components/forms/fields/V2';
import { Grid, Typography } from '@alegradev/smile-ui-react';

function BasicData() {
  const { Wrapper } = Grid;
  return (
    <Wrapper direction={{ lg: 'column' }} gap={10}>
      <Wrapper gap={6} align={{ lg: 'center' }}>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get('billNumber', 'Factura de compra N°')}
        />
        <Field
          name='billNumber'
          component={renderField}
          size='small'
          placeholder={I18n.get('select', 'Seleccionar')}
        />
      </Wrapper>
      <Wrapper gap={6} align={{ lg: 'center' }}>
        <Typography
          variant='tertiary'
          type='body-3-regular'
          text={I18n.get('billBuyDate', 'Fecha de compra:')}
        />
        <Wrapper>
          <Field
            name='billBuyDate'
            size='small'
            fieldType='date'
            component={renderField}
            placeholder={I18n.get('select', 'Seleccionar')}
          />
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
}

export default BasicData;
