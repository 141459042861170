import { createContext, useContext, useState } from 'react';

const InvoiceDetailContext = createContext({
  data: {},
  isEditable: false,
  setIsEditable: () => {},
  refetch: () => null,
});

export function useInvoiceDetail() {
  return useContext(InvoiceDetailContext);
}

function InvoiceDetailProvider({ children, value }) {
  const [isEditable, setIsEditable] = useState(false);
  return (
    <InvoiceDetailContext.Provider
      value={{ isEditable, setIsEditable, data: value }}
    >
      {children}
    </InvoiceDetailContext.Provider>
  );
}

export default InvoiceDetailProvider;
