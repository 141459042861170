import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, debounce } from 'lodash';

import { renderField } from '../../../../../components/forms/fields/V2';
import { Button, Grid, Select, Space } from '@alegradev/smile-ui-react';
import { invoiceClients } from '../../../../../selectors/clients';
import { useDispatch, useSelector } from 'react-redux';
import {
  renderClientIdentification,
  renderClientName,
} from '../../../../../components/home/NewActiveInvoice/client/utils/parseClientInfo';
import { country as countrySelector } from '../../../../../selectors/company';
import { filter } from '../../../../../reducers/clients';
import { openSideModal } from '../../../../../reducers/sideModals';
import { client as clientSelector } from '../../../../../selectors/activeInvoice';
import { setClient } from '../../../../../reducers/activeInvoice';
import { IconPlus } from '@tabler/icons-react';

function ProviderClient(props) {
  const { Col, Row } = Grid;
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const clients = useSelector(invoiceClients);
  const [loadingClients, setLoadingClients] = useState(false);
  const client = useSelector(clientSelector);
  const clientsRef = useRef();

  useEffect(() => {
    clientsRef.current = true;
    return () => (clientsRef.current = false);
  }, []);

  const search = useCallback(
    async (text = '') => {
      if (clientsRef.current) setLoadingClients(true);
      dispatch(filter({ text, limit: 35 }));
      if (clientsRef.current) setLoadingClients(false);
    },
    [dispatch]
  );

  const handleOpenCreateClientSideModal = () => {
    dispatch(
      openSideModal({
        sideModal: 'contact',
        params: { from: 'inventoryInvoice', saveOnOffline: 'false' },
      })
    );
  };

  return (
    <Row spacing='lg'>
      <Col xs={4} md={6} sm={12}>
        <Select
          label={capitalize(I18n.get('provider', 'Proveedor'))}
          placeholder={I18n.get('select', 'Seleccionar')}
          isSearchable
          onInputChange={(value) => debounce(search, 500)(value)}
          isLoading={loadingClients}
          cacheOptions={false}
          options={clients}
          value={client}
          onChange={(client) => [
            dispatch(setClient(client)),
            props?.form?.change('provider', client),
          ]}
          required
          getOptionLabel={(option) =>
            `${renderClientName(option)}${renderClientIdentification(
              option,
              country
            )}`
          }
        />
        <Space height={6} />
        <Button
          text={I18n.get('newProvider', 'Nuevo proveedor')}
          leftIcon={IconPlus}
          size='small'
          onClick={handleOpenCreateClientSideModal}
          emphasis='text'
          type='button'
        />
      </Col>
      <Col xs={4} md={6} sm={12}>
        <Field
          name='provider.identification'
          disabled
          component={renderField}
          label={capitalize(I18n.get('identification', 'Identificación'))}
        />
      </Col>
      <Col xs={4} md={6} sm={12}>
        <Field
          name='provider.phonePrimary'
          component={renderField}
          disabled
          label={capitalize(I18n.get('phone', 'Teléfono'))}
        />
      </Col>
    </Row>
  );
}

export default ProviderClient;
