import { get } from 'lodash';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { getHoursBetweenDates, getLocalDate } from '../../../../utils';
import { isElegibleForApplyRefund } from '../../../../components/invoices/utils';

export const canVoid = ({
  invoice,
  country,
  isShiftEnabled,
  isShiftOpen,
  can,
  currentShift,
  company,
}) => {
  if (!invoice) return false;

  if (
    country === COUNTRIES.MEXICO &&
    (invoice?.numberTemplate?.documentType === 'invoice' ||
      invoice?.associatedInvoice?.originApp === 'SELF_INVOICING')
  )
    return false;

  let result =
    get(invoice, 'status') !== 'void' &&
    (!isShiftEnabled ||
      (isShiftEnabled &&
        isShiftOpen === true &&
        get(invoice, 'idShift') === get(currentShift, 'idLocal')));

  if (['peru'].includes(country))
    result = result && !get(invoice, 'numberTemplate.isElectronic');

  if (country === 'republicaDominicana')
    result =
      result &&
      !get(invoice, 'stamp') &&
      !get(invoice, 'numberTemplate.isElectronic');

  if (
    country === 'panama' &&
    !!get(invoice, 'stamp') &&
    !!get(invoice, 'stamp.date') &&
    get(invoice, 'numberTemplate.isElectronic')
  )
    result =
      result &&
      getHoursBetweenDates(
        get(invoice, 'stamp.date'),
        getLocalDate(new Date(), country)
      ) <= 180;

  if (country === 'colombia') {
    const numerationIsElectronic = get(invoice, 'numberTemplate.isElectronic');
    const invoiceEmissionAccepted = get(
      invoice,
      'stamp.legalStatus',
      ''
    ).includes('STAMPED_AND_ACCEPTED');

    if (
      isElegibleForApplyRefund(invoice, country, company) &&
      invoiceEmissionAccepted
    )
      result = result && true;
    else if (numerationIsElectronic) result = result && false;
  }

  result = result && can('void', 'invoices');

  return result;
};
