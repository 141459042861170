import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get, deburr, lowerCase } from 'lodash';

import { regimesSelector } from '../reducers/regimes'
import { regimes as companyRegimes, companySelector, country } from './company';
import { regime } from './company'
import { COUNTRIES } from '../utils/enums/countries';

export const regimes = createDraftSafeSelector(
  regimesSelector.selectAll,
  regimes => !!regimes ? regimes: []
)

export const getCompanyRegime = createDraftSafeSelector(
  [regimes, regime, companyRegimes, companySelector],
  (regimes, regime, companyRegimes, company) => {
    const identification = get(company, 'identification', '')
    if (get(company, 'country', '') === COUNTRIES.MEXICO && identification.length === 13) {
      if (companyRegimes.length > 0) {
        return companyRegimes?.join('%s%') || ''
      }
    }

    // En mexico el regimen con el key BUSINESS_ACTIVITIES_REGIME en AC se guarda el value como la desciption
    if (country === COUNTRIES.MEXICO) {
      const mexRegime = regimes.find(r => get(r, 'key', '') === 'BUSINESS_ACTIVITIES_REGIME')
      if (regime !== '' && lowerCase(deburr(regime)) === lowerCase(deburr(get(mexRegime, 'description', '')))) {
        return !!regimes ? mexRegime : null
      }
    }
    
    return !!regimes
      ? regimes.find(r => lowerCase(deburr(get(r, 'value', ''))) === lowerCase(deburr(regime)))
      : null
  }
)