import InvoiceDetailProvider from './context';
import { ModalProvider } from '@alegradev/smile-ui-react';
import '../_invoices.scss';
import InvoiceDetailForm from './form';

function InvoiceDetail({ value, fetchData, closePartibleFn, fromTableAction }) {
  return (
    <ModalProvider>
      <InvoiceDetailProvider value={value}>
        <InvoiceDetailForm
          value={value}
          fetchData={fetchData}
          closePartibleFn={closePartibleFn}
          fromTableAction={fromTableAction}
        />
      </InvoiceDetailProvider>
    </ModalProvider>
  );
}

export default InvoiceDetail;
