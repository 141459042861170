import { useMemo } from 'react';
import { PageHeading, useModal } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { IconPencil, IconPrinter, IconTrash } from '@tabler/icons-react';
import { format } from 'date-fns';
import { handleDeleteBill } from '../main/table/actions';
import { useDeleteBillMutation } from '../services/mutations';
import { hasPermissionTo } from '../../../selectors/auth';
import { useSelector } from 'react-redux';

function BillDetailHeader({ bill }) {
  const history = useHistory();
  const can = useSelector(hasPermissionTo);
  const { mutateAsync } = useDeleteBillMutation();
  const { closeModal, openModal } = useModal();

  console.log({bill})

  const actions = useMemo(() => {
    const partialActions = [
      {
        text: I18n.get('print', 'Imprimir'),
        leftIcon: IconPrinter,
        variant: 'secondary',
      },
    ];

    if (bill?.toPay > 0 && can('edit', 'bills')) {
      partialActions.push({
        text: I18n.get('edit', 'Editar'),
        leftIcon: IconPencil,
        action: () => history.push(`/bills/bill/${bill?.id}`),
      });
    }

    if (bill?.toPay === bill?.total && can('delete', 'bills')) {
      partialActions.push({
        text: I18n.get('delete', 'Eliminar'),
        overflowIcon: IconTrash,
        variant: 'secondary',
        action: () =>
          handleDeleteBill({
            bill,
            closeModal,
            openModal,
            handleDeleteBill: mutateAsync,
          }),
      });
    }

    return partialActions;
  }, [bill]);

  const title = useMemo(
    () =>
      `${I18n.get('', 'Factura de compra')} ${bill?.number !== '-' ? bill?.number : bill?.id}`,
    [bill]
  );

  const supTitle = useMemo(
    () =>
      `${I18n.get('', 'Fecha de compra')} ${format(new Date(bill?.createdAt || new Date()), 'dd/MM/yyyy')}`,
    [bill]
  );

  return (
    <PageHeading
      breadcrumb={[
        {
          title: I18n.get('shopping', 'Compras'),
          action: () => history.push('/bills'),
        },
        { title },
      ]}
      title={title}
      supTitle={supTitle}
      actions={actions}
    />
  );
}

export default BillDetailHeader;
