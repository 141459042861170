import {
  Avatar,
  Button,
  Divider,
  Grid,
  Space,
  Typography,
  useModal,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { formatMoney } from '../../../utils';
import { format } from 'date-fns';
import BillDetailBodyProducts from './products/Products';
import BillDetailBodyProviderPayment from './providerPayment/ProviderPayment';
import { IconPencil, IconPrinter, IconTrash } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';
import { handleDeleteBill } from '../main/table/actions';
import { useDeleteBillMutation } from '../services/mutations';
import { hasPermissionTo } from '../../../selectors/auth';
import { useSelector } from 'react-redux';

function BillDetailBody({ bill, partible = false }) {
  const history = useHistory();
  const can = useSelector(hasPermissionTo);
  const { closeModal, openModal } = useModal();
  const { mutateAsync } = useDeleteBillMutation();
  const { Wrapper, Row, Col } = Grid;

  const renderInformation = ({ label, value }) => (
    <Col xs={4} sm={6}>
      <Typography variant='secondary' type='label-2' text={label} />
      <Space height={4} />
      <Typography variant='secondary' type='body-3-regular' text={value} />
    </Col>
  );

  return (
    <Wrapper
      direction={{ lg: 'column' }}
      backgroundColor='white'
      rounded={{ lg: 'lg' }}
      padding={{ lg: 'lg' }}
    >
      {partible && (
        <>
          <Wrapper justify={{ lg: 'between' }}>
            <Wrapper direction={{ lg: 'column' }}>
              <Typography
                type='body-1-bold'
                text='Compra N° 214214'
                variant='secondary'
              />
              <Typography
                type='body-3-regular'
                text='Creada el 08/06/2024 6:33 pm'
                variant='tertiary'
              />
            </Wrapper>
            <Wrapper>
              <Button onlyIcon={IconPrinter} emphasis='text' size='small' />
              {can('edit', 'bills') && bill?.toPay > 0 && (
                <Button
                  onlyIcon={IconPencil}
                  emphasis='text'
                  size='small'
                  onClick={() => history.push(`/bills/bill/${bill?.id}`)}
                />
              )}
              {can('delete', 'bills') && bill?.toPay === bill?.total && (
                <Button
                  onlyIcon={IconTrash}
                  emphasis='text'
                  size='small'
                  onClick={() =>
                    handleDeleteBill({
                      bill,
                      closeModal,
                      openModal,
                      handleDeleteBill: mutateAsync,
                    })
                  }
                />
              )}
            </Wrapper>
          </Wrapper>
        </>
      )}

      <Space height={10} />

      <Typography
        type='body-2-bold'
        variant='secondary'
        text={I18n.get('generalInfo', 'Datos generales')}
      />

      <Space height={10} />

      <Wrapper direction={{ lg: 'column' }} gap={10}>
        {bill?.provider && (
          <Row spacing='lg'>
            <Col xs={4} sm={6}>
              <Typography
                variant='secondary'
                type='label-2'
                text={I18n.get('provider', 'Proveedor')}
              />
              <Space height={4} />
              <Wrapper gap={6} align={{ lg: 'center' }}>
                <Avatar
                  text={bill?.provider?.name}
                  size='sm'
                  variant='secondary'
                />
                <Typography
                  variant='secondary'
                  type='body-3-regular'
                  text={bill?.provider?.name}
                />
              </Wrapper>
            </Col>
            {renderInformation({
              label: I18n.get('identification', 'Identificación'),
              value: bill?.provider?.identificationNumber,
            })}
            {renderInformation({
              label: I18n.get('phone', 'Teléfono'),
              value: bill?.provider?.phone,
            })}
          </Row>
        )}

        <Row spacing='lg'>
          {renderInformation({
            label: I18n.get('currency', 'Moneda'),
            value: bill?.currency?.code || '-',
          })}
          {renderInformation({
            label: I18n.get('expiredDate', 'Fecha de vencimiento'),
            value: format(
              new Date(bill?.expiresAt || new Date()),
              'dd/MM/yyyy'
            ),
          })}
          {renderInformation({
            label: I18n.get('warehouse', 'Bodega'),
            value: bill?.warehouse?.name || '-',
          })}
        </Row>
      </Wrapper>

      <Space height={30} />

      <Typography
        type='body-2-bold'
        variant='secondary'
        text={I18n.get('purchasedProducts', 'Productos comprados')}
      />
      <Space height={10} />
      <BillDetailBodyProducts bill={bill} />

      <Space height={30} />

      <Row>
        <Col xs={8}>
          <Wrapper direction={{ lg: 'column' }}>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('notes', 'Notas')}
            />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={bill?.notes || '-'}
            />
          </Wrapper>
        </Col>
        <Col xs={4}>
          <Wrapper
            backgroundColor='#F8FAFC'
            bordered
            gap={10}
            direction={{ lg: 'column' }}
            padding={{ lg: 'md' }}
            rounded={{ lg: 'lg' }}
            fullWidth
          >
            <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
              <Typography
                type='caption-bold'
                text={I18n.get('subtotal', 'Subtotal')}
              />
              <Typography
                type='body-3-bold'
                text={formatMoney(bill?.subtotal)}
              />
            </Wrapper>
            <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
              <Typography
                type='caption-regular'
                text={I18n.get('disccount', 'Descuento')}
              />
              <Typography
                type='body-3-bold'
                text={formatMoney(bill?.discount)}
              />
            </Wrapper>
            <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
              <Typography
                type='caption-bold'
                text={I18n.get('subtotal', 'Subtotal')}
              />
              <Typography
                type='body-3-bold'
                text={formatMoney(
                  Number(bill?.subtotal) - Number(bill?.discount)
                )}
              />
            </Wrapper>

            {Object.values(bill?.taxes).map((tax) => (
              <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
                <Typography
                  type='caption-regular'
                  text={`${tax?.name} (${tax?.percentage}%)`}
                />
                <Typography type='body-3-bold' text={formatMoney(tax?.total)} />
              </Wrapper>
            ))}
            <Divider margin='0' />
            <Wrapper justify={{ lg: 'between' }} align={{ lg: 'center' }}>
              <Typography
                type='caption-bold'
                text={I18n.get('total', 'Total')}
              />
              <Typography type='body-3-bold' text={formatMoney(bill?.total)} />
            </Wrapper>
          </Wrapper>
        </Col>
      </Row>

      {bill?.providerPayment?.length > 0 && (
        <>
          <Typography
            type='body-2-bold'
            variant='secondary'
            text={I18n.get(
              'paymentMadeToSupplier',
              'Pago realizado al proveedor'
            )}
          />
          <Divider margin={0} />
          <Space height={10} />
          <BillDetailBodyProviderPayment bill={bill} />
        </>
      )}
    </Wrapper>
  );
}

export default BillDetailBody;
