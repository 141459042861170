import { get } from 'lodash';
import { replaceAndParse, toast } from '../../../../utils';
import { I18n } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { getKitMaximumQuantity } from '../../../../reducers/editInvoice';

/**
 * Hook para mostrar un mensaje emergente al usuario en caso de que este
 * intente agregar un producto o kit que ya ha sido vendido.
 *
 * @returns {Object} Un objeto con la función `showLimitReached` que recibe un
 * item como parámetro y muestra un mensaje emergente informando al usuario
 * sobre el límite de ventas alcanzado.
 */
export const useEditInvoice = () => {
  const dispatch = useDispatch();
  const showLimitReached = async (item) => {
    switch (get(item, 'type', 'product')) {
      case 'product':
        toast.warning({
          title: I18n.get(
            'itemLimitWarningTitle',
            'Ya vendiste todas las unidades. 🏁'
          ),
          subtitle: I18n.get(
            'itemLimitWarningSubtitle',
            'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
          ),
        });
        break;
      case 'kit':
        const maxQuantity = await dispatch(getKitMaximumQuantity(item));
        toast.warning({
          title: replaceAndParse(
            I18n.get(
              'itemLimitWarningTitle.kitAvailable',
              '¡Puedes vender máximo {}! ✋'
            ),
            [
              `${maxQuantity} ${maxQuantity > 1 ? I18n.get('kits', 'combosT') : I18n.get('kit', 'combo')}`,
            ]
          ),
          subtitle: replaceAndParse(
            I18n.get(
              'itemLimitWarningSubtitle.kitAvailable',
              'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.'
            ),
            [`<span class="font-weight-bold">${get(item, 'name', '')}</span>`]
          ),
        });
        break;
      default:
        toast.warning({
          title: I18n.get(
            'itemLimitWarningTitle',
            'Ya vendiste todas las unidades. 🏁'
          ),
          subtitle: I18n.get(
            'itemLimitWarningSubtitle',
            'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
          ),
        });
        break;
    }
  };
  return { showLimitReached };
};

export default useEditInvoice;
