import {
  Avatar,
  Button,
  Grid,
  Space,
  TableCellStatus,
  Typography,
  useModal,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { handleSendAlegraPortalLink } from '../../utils/modals';
import { useMemo } from 'react';
import { country as countrySelector } from '../../../../selectors/company';
import checkIfIsDefaultClient from '../../utils/isDefaultClient';
import { useSelector } from 'react-redux';
import { IconSend } from '@tabler/icons-react';

function ContactDetailBodyHeader({ contact, partible }) {
  const { Wrapper } = Grid;
  const country = useSelector(countrySelector);
  const { openModal } = useModal();

  const isDefaultClient = useMemo(
    () => checkIfIsDefaultClient(contact, country),
    [contact, country]
  );

  return (
    <Wrapper
      backgroundColor='white'
      rounded={{ lg: 'xl' }}
      padding={{ lg: partible ? 'sm' : 'lg', sm: 'sm' }}
      align={{ lg: 'center' }}
      direction={{ lg: partible ? 'row' : 'column', sm: 'column' }}
      justify={{ sm: 'center' }}
      gap={partible ? 20 : 0}
    >
      <Avatar size={118} variant='secondary' text={contact?.name} />

      <Wrapper
        direction={{ lg: 'column' }}
        align={{ lg: partible ? 'start' : 'center',  sm: 'center' }}
        gap={6}
      >
        <Typography
          type='body-1-bold'
          text={contact?.name}
          variant='secondary'
          style={{ textAlign: 'center' }}
        />

        <Typography
          type='body-3-regular'
          text={contact?.email || contact?.identification || ''}
          variant='tertiary'
        />

        <TableCellStatus
          active={contact?.status === 'active'}
          activeText={I18n.get('active', 'Activado')}
          inactiveText={I18n.get('inactive', 'Desactivado')}
        />

        {!isDefaultClient && (
          <>
            <Space height={6} />
            <Button
              emphasis='outline'
              leftIcon={IconSend}
              full
              onClick={() => handleSendAlegraPortalLink({ contact, openModal })}
              text={I18n.get(
                'sendAlegraPortalLink',
                'Enviar enlace del Portal Alegra'
              )}
            />
          </>
        )}
      </Wrapper>
    </Wrapper>
  );
}

export default ContactDetailBodyHeader;
