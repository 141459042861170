import { useDispatch, useSelector } from 'react-redux';
import { Icon, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { capitalize, find, get } from 'lodash';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { getMainCurrency } from '../../../../../../selectors/currencies';
import {
  multicurrency,
  country as countrySelector,
} from '../../../../../../selectors/company';
import Button from '../../../../../../components/common/Button';
import { COUNTRIES } from '../../../../../../utils/enums/countries';
import { allRegimes } from '../../../../../../components/countriesData/mexico/regimes';
import { setPrint, setTypeToPrint } from '../../../../../../reducers/print';
import { sendNewGTMEvent } from '../../../../../../reducers/company';

const AutoInvoiceDetailHeader = ({ invoice }) => {
  const country = useSelector(countrySelector);
  const isMulticurrency = useSelector(multicurrency);
  const mainCurrency = useSelector(getMainCurrency);

  const dispatch = useDispatch();

  const getDocumentType = () => {
    return (
      I18n.get('saleTicket', 'Ticket') +
      ' de venta #' +
      get(invoice, 'numberTemplate.fullNumber')
    );
  };

  useEffect(() => {
    if (invoice) {
      dispatch(setPrint({ type: 'invoice', value: invoice }));
    }
  }, [invoice, dispatch]);

  const getLegalStatus = () => {
    const documentType = get(invoice, 'numberTemplate.documentType');
    const globalInvoiceStamp = get(invoice, 'globalInvoice.stamp', null);
    const invoiceStamp = get(invoice, 'stamp.uuid', null);

    if (documentType === 'saleTicket' && !!globalInvoiceStamp) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon='check' color='#16A34A' size='small' />
          <Typography type='body-3-regular' text='Facturado' />
        </div>
      );
    } else if (documentType === 'invoice' && invoiceStamp) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon='check' color='#16A34A' size='small' />
          <Typography type='body-3-regular' text='Timbrado' />
        </div>
      );
    }

    return '--';
  };

  const canSendOrPrint = () => {
    if (!invoice) return false;

    if (
      country !== COUNTRIES.PANAMA ||
      !get(invoice, 'numberTemplate.isElectronic')
    )
      return true;

    const isInvoiceCloses =
      get(invoice, 'stamp.legalStatus', null) === 'STAMPED_AND_ACCEPTED' ||
      get(invoice, 'stamp.legalStatus', null) ===
        'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS';
    return isInvoiceCloses;
  };

  const detailActions = () => {
    const actions = [];

    if (canSendOrPrint()) {
      actions.push(
        <Button
          variant='ghost'
          size='icon'
          onClick={() => [
            dispatch(openModal({ modal: 'sendEmail', params: { invoice } })),
            dispatch(
              sendNewGTMEvent('pos-sale-managed', {
                id: get(invoice, 'id', ''),
                action: 'email',
              })
            ),
          ]}
        >
          <Icon icon='share' />
        </Button>
      );
      actions.push(
        <Button
          variant='ghost'
          size='icon'
          onClick={() => [
            dispatch(setTypeToPrint('invoice')),
            dispatch(
              sendNewGTMEvent('pos-sale-managed', {
                id: get(invoice, 'id', ''),
                action: 'print',
              })
            ),
          ]}
        >
          <Icon icon='printer' />
        </Button>
      );
    }

    return actions;
  };
  return (
    <>
      <div className='mb-4'>
        <div className='w-100 d-flex mb-5 justify-content-between'>
          <div>
            <Typography
              type='body-1-bold'
              variant='secondary'
              text={getDocumentType()}
            />
            <Typography
              type='body-3-regular'
              variant='tertiary'
              text={I18n.get('', 'Autofacturada el {{date}}').replace(
                '{{date}}',
                dayjs(get(invoice, 'associatedInvoice.date', null)).format(
                  'DD/MM/YYYY h:mm a'
                )
              )}
            />
          </div>
          <div className='d-flex gap-3'>{detailActions()}</div>
        </div>
        <div className='w-100 d-flex justify-content-between'>
          <Typography
            type='body-1-bold'
            variant='secondary'
            text={I18n.get('', 'Información general')}
          />
        </div>
        <div className='row w-100'>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Cliente')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={get(invoice, 'client.name')}
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'RFC')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={get(invoice, 'client.identification')}
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Régimen fiscal')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={
                get(invoice, 'regimeClient')
                  ? allRegimes[get(invoice, 'regimeClient')].label
                  : allRegimes.SIMPLIFIED_REGIME.label
              }
            />
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={capitalize(I18n.get('dueDate', 'Fecha de vencimiento'))}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={dayjs(get(invoice, 'dueDate', null)).format('DD/MM/YYYY')}
            />
          </div>
          {!!isMulticurrency && (
            <div className='col-md-4 col-sm-12 mt-4'>
              <Typography
                type='label-2'
                variant='secondary'
                text={I18n.get('currency', 'Moneda')}
              />
              <div className='mt-1' />
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={
                  !!get(invoice, 'currency.code')
                    ? get(invoice, 'currency.code')
                    : get(mainCurrency, 'code')
                }
              />
            </div>
          )}
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Estado de timbre')}
            />
            <div className='mt-1' />
            {getLegalStatus()}
          </div>

          <div className='col-md-8 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Folio Fiscal')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={get(invoice, 'associatedInvoice.stamp.uuid', '')}
            />
          </div>
        </div>
      </div>
      <div className='mb-4 mt-5'>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get(
            'productsAndServicesSold',
            'Productos y servicios vendidos'
          )}
        />
      </div>
    </>
  );
};

export default AutoInvoiceDetailHeader;
