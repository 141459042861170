import { Switch, Route } from 'react-router-dom';

import { Main } from './main';
import { Bill } from './bill';
import { BillDetail } from './detail';

function BillsRouter() {
  return (
    <Switch>
      <Route
        path='/bills/bill/:id/detail'
        component={BillDetail}
      />
      <Route
        path='/bills/bill/:id?'
        component={Bill}
      />
      <Route path='/bills' component={Main} />
    </Switch>
  );
}

export default BillsRouter;
