import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import {
  companySelector,
  country as countrySelector,
  regime as regimeSelector,
} from '../../../../selectors/company';

import Typography from '../../../../components/common/Typography';
import { formatCurrencyMoney } from '../../../../utils/format-money';
import { get, isEmpty } from 'lodash';
import { useInvoiceDetail } from '../context';
import { useEffect, useMemo, useState } from 'react';
import { calculateItemsValues } from '../../../../utils';
import { useFormat } from '../../../../hooks/useFormat';

const InvoiceDetailSummary = ({ invoice, values = {} }) => {
  const { data, isEditable } = useInvoiceDetail();
  const [dataInvoice, setDataInvoice] = useState(
    isEmpty(data) ? invoice : data
  );
  const country = useSelector(countrySelector);
  const regime = useSelector(regimeSelector);
  const company = useSelector(companySelector);
  const { fmt, decimal } = useFormat();

  const itemsValues = useMemo(
    () =>
      calculateItemsValues({
        items: dataInvoice?.items || [],
        decimal,
        country,
        company,
        ignoreDisableTaxes: true,
      }),
    [dataInvoice?.items, decimal, country, company, true]
  );

  useEffect(() => {
    if (!isEditable) {
      setDataInvoice(isEmpty(data) ? invoice : data);
    } else {
      setDataInvoice(values);
    }
  }, [isEditable, data, invoice, values]);

  return (
    <div className='invoices-detail-summary'>
      <div className='mb-4 w-100'>
        <div className='row w-100'>
          <div className={`col-md-12 col-sm-12 mt-4`}>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('anotations', 'Observaciones')}
            />
            <div className='mt-1' />
            <Typography
              type='caption-regular'
              variant='secondary'
              text={get(dataInvoice, 'anotation', '')}
            />
          </div>
          <div className={`col-md-12 col-sm-12 mt-5`}>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('termsConditions', 'Términos y condiciones')}
            />
            <div className='mt-1' />
            <Typography
              type='caption-regular'
              variant='secondary'
              text={get(dataInvoice, 'termsConditions', '')}
            />
          </div>
        </div>
      </div>
      <div className='invoices-detail-resume'>
        <div className='d-flex justify-content-between'>
          <Typography type='caption-bold' text='Subtotal' variant='secondary' />
          <Typography
            type='body-3-bold'
            text={itemsValues.subtotal.toFormat(decimal, fmt)}
            variant='secondary'
          />
        </div>
        <div className='d-flex justify-content-between'>
          <Typography
            type='caption-bold'
            text='Descuento'
            variant='secondary'
          />
          <Typography
            type='body-3-bold'
            text={itemsValues.discount.toFormat(decimal, fmt)}
            variant='secondary'
          />
        </div>
        <div className='d-flex justify-content-between'>
          <Typography type='caption-bold' text='Subtotal' variant='secondary' />
          <Typography
            type='body-3-bold'
            text={itemsValues.discSubtotal.toFormat(decimal, fmt)}
            variant='secondary'
          />
        </div>
        {itemsValues.taxes.map((tax, index) => (
          <div className='d-flex justify-content-between' key={index}>
            <Typography
              type='caption-bold'
              text={tax.name}
              variant='secondary'
            />
            <Typography
              type='body-3-bold'
              text={tax.value.toFormat(decimal, fmt)}
              variant='secondary'
            />
          </div>
        ))}
        <div className='dashed-line' />
        <div className='d-flex justify-content-between'>
          <Typography type='body-2-bold' text='Total' variant='secondary' />
          <Typography
            type='body-1-bold'
            text={formatCurrencyMoney(
              get(itemsValues, 'total'),
              country,
              decimal
            )}
            variant='secondary'
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetailSummary;
