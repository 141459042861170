import db from './database'
import { SearchItems } from '../utils/search'
import { matchesProperty, find, get, has, sortBy,omitBy, isNull, isUndefined } from 'lodash'

let favoriteItems = [];

export const bulkPut = async (items, callback) => {
  items = items.map(item => {
    const barcodeField = find(item.customFields, matchesProperty('key', 'barcode'));
    return {
      ...item,
      // reference: JSON.parse(item.reference),
      isFavorite: favoriteItems.includes(item.id),
      barcode: !!barcodeField ? barcodeField.value.toUpperCase().replace(/\s/g, '') : null
    }
  })
  await db.items.bulkPut(items)

  if (!!callback) callback()
}

export const put = item => {
  let itemValues = omitBy(
    { ...item },
    (value) => isNull(value) || isUndefined(value)
  );
  if (itemValues) {
    const barcodeField = find(
      itemValues.customFields,
      matchesProperty('key', 'barcode')
    );
    itemValues.barcode = !!barcodeField
      ? barcodeField.value.toUpperCase().replace(/\s/g, '')
      : null;
    itemValues.isFavorite = favoriteItems.includes(itemValues.id);
  }

  return db.items.put(itemValues);
}

export const total = () => db.items.count()

export const filter = async ({ text, categories, limit, noNegativeSale }) => {
  try {
    let allItems = (!!categories && !!categories.length)
      ? await db.items
        .where('itemCategory.id')
        .anyOf(categories)
        .toArray()
      : await db.items
        .toArray()

    const favorites = sortBy(allItems.filter(item => !!item.isFavorite && item.status === 'active'), 'name')
    const rest = sortBy(allItems.filter(item => !item.isFavorite && item.status === 'active'), 'name')

    if (!text)
      return favorites.concat(rest).slice(0, limit)

    let favoritemItemSearch = new SearchItems();
    let itemSearch = new SearchItems();
    favoritemItemSearch.addDocuments(favorites);
    itemSearch.addDocuments(rest);

    const results = favoritemItemSearch.search(text).concat(itemSearch.search(text)).slice(0, limit)

    if (noNegativeSale)
      return results.filter(item => !(!item?.inventory?.negativeSale && item?.inventory?.availableQuantity !== null && item?.inventory?.availableQuantity <= 0))

    return results

  } catch (error) {
    return []
  }
}


const search = (items, tokens) => {
  return items
  .filter(item => {
    const nameAndReference = (get(item, 'name', '') || '') + ' ' +
      (get(item, 'reference', '') || '') + ' ' + 
      (get(item, 'reference.reference', '') || '');
    const normalizedNameAndReference= nameAndReference.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    return tokens.every(token => normalizedNameAndReference.toLowerCase().includes(token));
  })
}

export const specialFilter = async ({ text, categories, limit, noNegativeSale }) => {
  try {
    const allItems = (!!categories && !!categories.length)
      ? await db.items
        .where('itemCategory.id')
        .anyOf(categories)
        .toArray()
      : await db.items.toArray()

    let favorites = allItems.filter(item => !!item.isFavorite && item.status === 'active')
    let rest = allItems.filter(item => !item.isFavorite && item.status === 'active')

    if (!text) {
      favorites = sortBy(favorites, 'name')
      rest = sortBy(rest, 'name')
      return favorites.concat(rest).slice(0, limit)
    }
    
    const normalizedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const tokens = normalizedText.toLowerCase().split(' ');
    
    favorites = sortBy(search(favorites, tokens), 'name')
    rest = sortBy(search(rest, tokens), 'name')
    
    const results = favorites.concat(rest).slice(0, limit)

    if (noNegativeSale)
      return results.filter(item => !(!item?.inventory?.negativeSale && item?.inventory?.availableQuantity !== null && item?.inventory?.availableQuantity <= 0))

    return results
  } catch (error) {
    console.log(error)
    return []
  }
}

export const setFavorites = favorites => {
  favoriteItems = favorites.map(({ id }) => {
    db.items.update(+id, { isFavorite: true })
    return +id;
  })
}

export const findByBarcode = async barcode => {
  try {
    barcode = (barcode || '').toUpperCase().replace(/\s/g, '');
    const itemWithBarcode = (await db.items.where('barcode').equals(barcode).toArray())[0];
    if (!!itemWithBarcode)
      return itemWithBarcode

    const itemsWithRef = await db.items.filter(item => {
      if (has(item, 'reference.reference'))
        return get(item, 'reference.reference') === barcode
      return get(item, 'reference') === barcode
    })
    if (!!itemsWithRef)
      return itemsWithRef.first()
    return null;
  } catch {
    return null;
  }
}

export const clear = () => db.items.clear()

export const update = (id, changes) => db.items.update(id, changes)

export const getItem = async (id) => {
  if (db && db.items) {
    return await db.items.get(id);
  } else {
    null;
  }
};

export const getAll = async () => {
  try {
    return await db.items.toArray()
  } catch {
    return [];
  }
}

export const remove = (id) => db.items.delete(id)

export const bulkDelete = (keys) => db.items.bulkDelete(keys)