import { Icon, TableCellRegular, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import BigNumber from 'bignumber.js';
import { get, size } from 'lodash';
import InvoiceDetailAutoInvoiceModal from '../modal';

const InvoiceDetailAutoInvoicesTableColumns = (
  decimal,
  fmt,
  openModal,
  closeModal,
  data
) => {
  return [
    {
      Header: I18n.get('number', 'Número'),
      accessor: 'number',
      width: '20%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <button
            onClick={() => {
              return openModal({
                title: '  ',
                hideCloseButton: true,
                name: 'AutoInvoice',
                component: InvoiceDetailAutoInvoiceModal,
                size: 'large',
                onClose: closeModal,
                props: {
                  invoice: data,
                },
              });
            }}
          >
            <div className='invoices-auto-invoice-number d-flex align-items-center'>
              <Typography type='caption-bold' text={get(original, 'number')} />
            </div>
          </button>
        );
      },
    },
    {
      Header: I18n.get('client', 'Cliente'),
      accessor: 'client.name',
      width: '30%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <Typography
            type='body-3-regular'
            text={get(original, 'client.name', '')}
            variant='secondary'
          />
        );
      },
    },
    {
      Header: I18n.get('status', 'Estado'),
      accessor: 'status',
      width: '25%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='d-flex align-items-center'>
            <Icon icon='file-symlink' size='small' color='#94A3B8' />
            <Typography
              type='caption-regular'
              text={get(original, 'status', '')}
              variant='secondary'
            />
          </div>
        );
      },
    },
    {
      Header: I18n.get('', 'Valor total'),
      accessor: 'amount',
      alignment: 'right',
      width: '25%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCellRegular
            value={new BigNumber(original?.amount).toFormat(decimal, fmt)}
          />
        );
      },
    },
  ];
};

export default InvoiceDetailAutoInvoicesTableColumns;
