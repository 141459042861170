import {
  IconEye,
  IconPencil,
  IconPrinter,
  IconTrash,
} from '@tabler/icons-react';
import { I18n } from 'aws-amplify';

export const handleDeleteBill = async ({
  bill,
  handleDeleteBill,
  closeModal,
  openModal,
}) => {
  openModal({
    name: `deleteClient-${bill?.id}`,
    hideCloseButton: true,
    title: I18n.get('deleteBull', '¿Deseas eliminar esta factura?'),
    description: I18n.get(
      'deleteBillDescription',
      'Al confirmar que deseas eliminar la factura, se borrará de forma definitiva de tus facturas.'
    ),
    primaryAction: {
      text: I18n.get('confirmDeleteBill', 'Si, deseo eliminarla'),
      onClick: () => [
        closeModal(`deleteBill-${bill?.id}`),
        handleDeleteBill(bill?.id),
      ],
      variant: 'destructive',
    },
    secondaryAction: {
      text: I18n.get('keepBill', 'Conservar factura'),
      onClick: () => closeModal(`deleteBill-${bill?.id}`),
    },
  });
};

export const actions = ({
  row,
  history,
  closeModal,
  openModal,
  mutateAsync,
  can,
}) => {
  const actions = [
    {
      title: I18n.get('viewDetail', 'Ver detalle'),
      icon: IconEye,
      type: 'option',
      action: () => history.push(`/bills/bill/${row.id}/detail`),
    },
    {
      title: I18n.get('print', 'Imprimir'),
      icon: IconPrinter,
      type: 'option',
    },
  ];

  if (row?.totalPaid > 0) {
    actions.push({
      title: I18n.get('edit', 'Editar'),
      icon: IconPencil,
      type: 'option',
      action: () => history.push(`/bills/bill/${row.id}`),
      disabled: !can('edit', 'bills'),
    });
  }

  if (row?.totalPaid === 0) {
    actions.push({
      title: I18n.get('delete', 'Eliminar'),
      icon: IconTrash,
      destructive: true,
      type: 'option',
      disabled: !can('delete', 'bills'),
      action: () =>
        handleDeleteBill({
          bill: row,
          closeModal,
          openModal,
          handleDeleteBill: mutateAsync,
        }),
    });
  }

  return actions;
};
