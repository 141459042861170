import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  Avatar,
  Button,
  Divider,
  Grid,
  Space,
  Toast,
  Typography,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { transform } from './utils/transformer';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import { Accounting } from './accounting';
import { AdvancedData } from './advancedData';
import BasicData from './basicData/BasicData';

import {
  address,
  country as countrySelector,
  electronicInvoicing,
} from '../../../../selectors/company';
import { hasPermissionTo } from '../../../../selectors/auth';

import { ContactFormProvider } from './FormProvider';
import { validate, validationPreviousCreation } from './utils/validations';
import { setClient } from '../../../../reducers/activeInvoice';
import { useCreateContactMutation } from '../../services/mutations';
import { replaceOfflineClient, saveClient } from '../../../../reducers/clients';
import { useContact } from '../ContactProvider';
import { formError } from '../../../../utils/errors';
import { closeSideModal } from '../../../../reducers/sideModals';
import { useEffect } from 'react';
import { get } from 'lodash';
import DuplicatedContactModal from '../../sideModal/DuplicatedContact';
import { sendNewGTMEvent } from '../../../../reducers/company';
import {
  getDefaultAccountReceivableCategory,
  getDefaultDebtToPayCategory,
} from '../../../../selectors/categories';
import { COUNTRIES } from '../../../../utils/enums/countries';

function ContactForm({
  saveOnOffline = false,
  from = 'page',
  setValid,
  setSubmitAction,
  hasSubmitAction,
}) {
  const { Wrapper, Col, Row } = Grid;
  const { editable, contact, ignoreRepeated } = useContact();
  const { mutateAsync, isLoading } = useCreateContactMutation();
  const history = useHistory();
  const dispatch = useDispatch();
  const error = useSelector((state) =>
    get(state, 'sideModals.contact.params.error', null)
  );
  const country = useSelector(countrySelector);
  const isElectronic = useSelector(electronicInvoicing);
  const can = useSelector(hasPermissionTo);
  const defaultAccountReceivableCategory = useSelector(
    getDefaultAccountReceivableCategory
  );
  const companyAddress = useSelector(address);
  const defaultDebtToPayCategory = useSelector(getDefaultDebtToPayCategory);

  const submit = async (values) => {
    const transformedValues = transform(values, {
      country,
      isElectronic,
      contact,
      ignoreRepeated,
      defaultAccountReceivableCategory,
      defaultDebtToPayCategory,
    });

    try {
      validationPreviousCreation(transformedValues, { country, isElectronic });
      if (
        (saveOnOffline && !editable) ||
        (saveOnOffline && ignoreRepeated && !editable)
      ) {
        if (!!contact && !ignoreRepeated) {
          dispatch(replaceOfflineClient(contact.id, transformedValues));
        } else {
          const client = await dispatch(saveClient(transformedValues));
          await dispatch(setClient(client));
        }
        dispatch(closeSideModal({ sideModal: 'contact' }));
      } else {
        await mutateAsync({
          ...transformedValues,
          editable,
          from,
        });
        dispatch(closeSideModal({ sideModal: 'contact' }));
      }
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'createClientError',
          'hubo un error en la creación del contacto'
        )
      );
    }
  };

  useEffect(() => {
    if (!editable) {
      dispatch(
        sendNewGTMEvent('pos-contact-creation-started', {
          origin: from === 'page' ? 'contact' : 'contactModule',
        })
      );
    }
  }, [editable]);

  return (
    <Form
      onSubmit={submit}
      validate={(values) => validate(values, { country, isElectronic })}
      mutators={{
        setValues: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, values, form, submitting, errors, submitError }) => {
        const disabled =
          Object.keys(errors).length > 0 ||
          submitting ||
          (editable ? !can('edit', 'contacts') : !can('add', 'contacts'));

        const componentProps = {
          values,
          form,
          from,
          country,
          isElectronic,
          companyAddress,
          newSellerAction: can('add', 'sellers')
            ? () => setModals({ ...modals, seller: true })
            : null,
          newPriceListAction: () => setModals({ ...modals, priceList: true }),
          newPaymentTermAction: () =>
            setModals({ ...modals, paymentTerm: true }),
        };

        useEffect(() => {
          if (setValid) setValid(!disabled);
        }, [disabled]);

        useEffect(() => {
          if (setSubmitAction && !hasSubmitAction)
            setSubmitAction(handleSubmit);
        }, [handleSubmit]);

        return (
          <ContactFormProvider from={from}>
            <form
              noValidate
              onSubmit={handleSubmit}
              className='w-full contact-form'
            >
              <div className='contact-form-body'>
                <Row spacing='md'>
                  {from === 'page' && (
                    <Col xs={'hidden'} md={12}>
                      <Wrapper
                        backgroundColor='white'
                        bordered
                        rounded={{ lg: 'xl' }}
                        direction={{ lg: 'column' }}
                        gap={0}
                      >
                        <Wrapper
                          padding={{ lg: 'xl' }}
                          direction={{ lg: 'column' }}
                          align={{ lg: 'center' }}
                          gap={10}
                        >
                          <Avatar
                            size={120}
                            variant='secondary'
                            text={values?.firstName || 'A'}
                          />
                          <Typography
                            text={
                              values?.firstName
                                ? [
                                    values?.firstName,
                                    values?.secondName,
                                    values?.lastName,
                                  ].join(' ')
                                : I18n.get('clientName', 'Nombre del cliente')
                            }
                            style={{ textAlign: 'center' }}
                            type='body-1-bold'
                            variant='tertiary'
                          />
                          <Typography
                            text={values?.identification?.number || ''}
                            style={{ textAlign: 'center' }}
                            type='body-3-regular'
                            variant='tertiary'
                          />
                        </Wrapper>
                      </Wrapper>
                    </Col>
                  )}
                 
                  <Col xs={from === 'page' ? 8 : 12} md={12}>
                    <Accordion
                      noPadding={from === 'sideModal'}
                      variant={from === 'page' ? 'bordered' : 'default'}
                      items={[
                        {
                          key: 'generalInfo',
                          title: I18n.get('generalInfo', 'información general'),
                          titleSize: 'large',
                          description: I18n.get(
                            'client.generalInfoSubtitles',
                            'Ingresa la información principal de tu cliente'
                          ),
                          component: BasicData,
                          componentProps,
                        },
                        {
                          key: 'advancedOptions',
                          title: I18n.get(
                            'client.advancedOptions',
                            'opciones avanzadas'
                          ),
                          titleSize: 'large',
                          description: I18n.get(
                            'client.advancedOptionsSubtitles',
                            'puedes configurar los datos tributarios de tu cliente, plazo de pago, vendedor y lista de precio'
                          ),
                          component: AdvancedData,
                          componentProps,
                        },
                        {
                          key: 'accountingOptions',
                          title: I18n.get(
                            'accountingOptions',
                            'configuración contabilidad'
                          ),
                          titleSize: 'large',
                          description: I18n.get(
                            'client.accountingOptionsSubtitles',
                            'Elige la cuenta contable en la que se registrarán las cuentas por cobrar de tus ventas a crédito'
                          ),
                          hidden: country === COUNTRIES.MEXICO,
                          component: Accounting,
                          componentProps,
                        },
                      ]}
                    />
                  </Col>
                  {from === 'page' && (
                    <Col xs={4} md={'hidden'}>
                      <Wrapper
                        backgroundColor='white'
                        bordered
                        rounded={{ lg: 'xl' }}
                        direction={{ lg: 'column' }}
                        gap={0}
                      >
                        <Wrapper
                          padding={{ lg: 'xl' }}
                          direction={{ lg: 'column' }}
                          align={{ lg: 'center' }}
                          gap={10}
                        >
                          <Avatar
                            size={120}
                            variant='secondary'
                            text={values?.firstName || 'A'}
                          />
                          <Typography
                            text={
                              values?.firstName
                                ? [
                                    values?.firstName,
                                    values?.secondName,
                                    values?.lastName,
                                  ].join(' ')
                                : I18n.get('clientName', 'Nombre del cliente')
                            }
                            style={{ textAlign: 'center' }}
                            type='body-1-bold'
                            variant='tertiary'
                          />
                          <Typography
                            text={values?.identification?.number || ''}
                            style={{ textAlign: 'center' }}
                            type='body-3-regular'
                            variant='tertiary'
                          />
                        </Wrapper>
                        <Divider margin='0' />
                        <Wrapper gap={10} padding={{ lg: 'md' }}>
                          <Button
                            emphasis='outline'
                            type='button'
                            onClick={() => history.push('/contacts')}
                            text={I18n.get('cancel', 'Cancelar')}
                          />
                          <Button
                            full
                            disabled={disabled}
                            loading={isLoading || submitting}
                            text={I18n.get('saveContact', 'Guardar contacto')}
                          />
                        </Wrapper>
                      </Wrapper>
                    </Col>
                  )}
                  {from === 'page' && (
                    <Col xs={'hidden'} md={12}>
                      <Wrapper justify={{ lg: 'end' }} padding={{ lg: 'md' }}>
                        <Wrapper gap={10}>
                          <Button
                            emphasis='outline'
                            type='button'
                            onClick={() => history.push('/contacts')}
                            text={I18n.get('cancel', 'Cancelar')}
                          />
                          <Button
                            full
                            disabled={disabled}
                            loading={isLoading || submitting}
                            text={I18n.get('saveContact', 'Guardar contacto')}
                          />
                        </Wrapper>
                      </Wrapper>
                    </Col>
                  )}
                </Row>
              </div>

              {['sideModal', 'inventoryInvoice'].includes(from) && (
                <div className='contact-form-footer'>
                  <div className='contact-form-footer-divider' />
                  <Wrapper direction={{ lg: 'column' }}>
                    <Space height={5} />
                    {!!submitError && (
                      <Toast shadow={false} type='error' title={submitError} />
                    )}
                    {!!error && (
                      <Toast shadow={false} type='error' title={error} />
                    )}
                    {!editable && !can('add', 'contacts') && (
                      <Toast
                        shadow={false}
                        type='warning'
                        title={I18n.get(
                          'userNotAllowed.contacts.add',
                          'no tienes permisos para agregar clientes'
                        )}
                      />
                    )}
                    {editable && !can('edit', 'contacts') && (
                      <Toast
                        shadow={false}
                        type='warning'
                        title={I18n.get(
                          'userNotAllowed.contacts.edit',
                          'no tienes permisos para editar clientes'
                        )}
                      />
                    )}
                    <Space height={5} />
                    <Wrapper gap={10}>
                      <Button
                        full
                        emphasis='outline'
                        type='button'
                        onClick={() =>
                          dispatch(closeSideModal({ sideModal: 'contact' }))
                        }
                        text={I18n.get('cancel', 'Cancelar')}
                      />
                      <Button
                        full
                        disabled={disabled}
                        loading={isLoading || submitting}
                        text={I18n.get('saveContact', 'Guardar contacto')}
                      />
                    </Wrapper>
                    <Space height={2} />
                  </Wrapper>
                </div>
              )}
            </form>
            <DuplicatedContactModal />
          </ContactFormProvider>
        );
      }}
    </Form>
  );
}

export default ContactForm;
