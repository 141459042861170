import React from 'react'
import { I18n } from '@aws-amplify/core';
import { get, isObject } from 'lodash';
import { useSelector } from 'react-redux';

import { companySelector, country as countrySelector, dateFormat as dateFormatSelector } from '../../../selectors/company'
import {
  getID,
  getDV,
  renderAddress,
  getRegime,
  getIVACondition,
  getIibbCondition,
  getActivityStartDate
} from './utilities';
import { COUNTRIES } from '../../../utils/enums/countries';
import { getRegimeByKey, getRegimeOptionsByKeys } from '../../countriesData/mexico/regimes';

const countriesToShowTradeName = [COUNTRIES.COLOMBIA, COUNTRIES.PANAMA, COUNTRIES.MEXICO];

const Company = ({ invoice, setting: { align, template } }) => {
  const { stamp, economicActivity } = invoice;
  const company = useSelector(companySelector);
  const country = useSelector(countrySelector);
  const dateFormat = useSelector(dateFormatSelector);

  return (
    <div className={`w-100 text-${align} py-2 d-flex flex-column ${template === 'modern' ? 'border-top' : ''}`}>

      {
        country === 'spain' && (
          !!get(company, 'localSettings.tradeName', null) ? (
            <>
              <h3 className="h3 font-weight-bold">{get(company, 'localSettings.tradeName', null)}</h3>
              <p>{get(company, 'name', null)}</p>
            </>
          ) : (
            <h3 className="h3 font-weight-bold">{get(company, 'name')}</h3>
          )
        )
      }

      {
        country !== 'spain' && (
          (countriesToShowTradeName.includes(country)) && !!get(company, 'tradeName', null)
            ?
            <>
              <h3 className="h3 font-weight-bold">{get(company, 'tradeName')}</h3>
              <p>{get(company, 'name')}</p>
            </>

            : <h3 className="h3 font-weight-bold">{get(company, 'name')}</h3>
        )
      }

      {
        <p>
          {`${getID(company)} `}
          {getDV(company, country) ?
            <>
              <span className="font-weight-bold">{I18n.get('dv', 'DV')}: </span> {getDV(company, country)}
            </> : null}
        </p>
      }

      {country === 'costaRica' && !!economicActivity &&
        <p>
          <strong>{I18n.get('economicActivity', 'actividad económica')}</strong>
          {isObject(economicActivity) ? get(economicActivity, 'value', '') : economicActivity}
        </p>
      }

      {renderAddress(company, country)}

      {!!get(company, 'phone', null) &&
        <p>
          <strong>{I18n.get('phone', 'teléfono')}</strong>
          {get(company, 'phone')}
        </p>
      }
      {!!get(company, 'email', null) &&
        <p>
          {get(company, 'email')}
        </p>
      }
      {!!get(company, 'website', null) &&
        <p>
          <strong>{I18n.get('website', 'sitio web')}</strong>
          {get(company, 'website')}
        </p>
      }

      {!!getIVACondition(company, country) && (
        <p>
          <strong>{I18n.get('ivaCondition', 'Condición de IVA')}</strong>
          {getIVACondition(company, country)}
        </p>
      )}

      {!!getActivityStartDate(company, country, dateFormat) && (
        <p>
          <strong>{I18n.get('activityStartDate', 'Inicio de actividades')}</strong>
          {getActivityStartDate(company, country, dateFormat)}
        </p>
      )}

      {!!getIibbCondition(company, country) && (
        <p>
          <strong>{I18n.get('iibbCondition', 'Condición IIBB')}</strong>
          {getIibbCondition(company, country)}
        </p>
      )}

      {(!!getRegime(company, country) && country !== 'mexico') &&
        <p>
          <strong>{I18n.get('regime', 'régimen')}</strong>
          {getRegime(company, country)}
        </p>
      }

      {(!!regimeMex(company, country) && country === 'mexico') &&
        <p>
          <strong>{I18n.get('regime', 'régimen')}</strong>
          {regimeMex(company, invoice, country)}
        </p>
      }
    </div>
  )
};

const regimeMex = (company, invoice, country) => {
  const invoiceRegime = get(getRegimeByKey(get(invoice, 'regime', null)), 'label', null);
  if (!!invoiceRegime) {
    return invoiceRegime;
  }
  const favoriteRegime = get(company, 'localSettings.favoriteRegime', null);
  if (!!favoriteRegime) {
    return get(getRegimeByKey(favoriteRegime), 'label', null);
  }

  const regimes = get(company, 'regimes', []) || [];
  if (regimes.length > 0) {
    return get(getRegimeByKey(regimes[0]), 'label', null);
  }
  return getRegime(company, country)
}

export default Company;