import { capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { TableCellRegular, Typography } from '@alegradev/smile-ui-react';
import { formatMoney } from '../../../../utils';
import { format } from 'date-fns';

export const billsColumns = [
  {
    Header: capitalize(I18n.get('number', 'Número')),
    accessor: 'numberTemplate.fullNumber',
    partiblePosition: 'top-left',
    width: '15%',
    Cell: ({ value }) => <TableCellRegular value={value} />,
    PartibleComponent: ({ value }) => (
      <Typography
        type='body-3-regular'
        text={`${capitalize(I18n.get('number', 'Número'))}: ${value}`}
      />
    ),
  },
  {
    Header: capitalize(I18n.get('provider', 'Proveedor')),
    accessor: 'provider',
    partiblePosition: 'bottom-left',
    width: '15%',
    Cell: ({ value }) => <TableCellRegular value={value?.name} />,
    PartibleComponent: ({ value }) => (
      <Typography
        type='caption-regular'
        text={`${capitalize(I18n.get('provider', 'Proveedor'))}: ${value?.name}`}
      />
    ),
  },
  {
    Header: capitalize(I18n.get('createdAt', 'Creación')),
    accessor: 'date',
    partiblePosition: 'bottom-right',
    width: '15%',
    Cell: ({ value }) => (
      <TableCellRegular
        value={format(new Date(value || new Date()), 'dd/MM/yyyy')}
      />
    ),
    PartibleComponent: ({ value }) => (
      <Typography
        type='caption-regular'
        text={format(new Date(value || new Date()), 'dd/MM/yyyy')}
      />
    ),
  },
  {
    Header: capitalize(I18n.get('expiresAt', 'Vencimiento')),
    accessor: 'dueDate',
    width: '15%',
    Cell: ({ value }) => (
      <TableCellRegular
        value={format(new Date(value || new Date()), 'dd/MM/yyyy')}
      />
    ),
  },
  {
    Header: capitalize(I18n.get('total', 'Total')),
    accessor: 'total',
    partiblePosition: 'top-right',
    width: '15%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value || 0)} />,
    PartibleComponent: ({ value }) => (
      <Typography type='body-3-regular' text={formatMoney(value)} />
    ),
  },
  {
    Header: capitalize(I18n.get('paid', 'Pagado')),
    accessor: 'totalPaid',
    width: '15%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value || 0)} />,
  },
  {
    Header: capitalize(I18n.get('toPay', 'Por pagar')),
    accessor: 'balance',
    width: '15%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value || 0)} />,
  },
];
