/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($user: UserInput) {
    updateUser(user: $user) {
      channel
      id
      idGlobal
      company {
        id
        name
        identification
        phone
        website
        economicActivity
        economicActivities {
          id
          code
          value
          main
        }
        email
        regime
        regimes
        ivaCondition
        applicationVersion
        registryDate
        timezone
        profile
        decimalPrecision
        multitax
        isActiveNoIvaDay
        showNoIvaDaysOnItems
        legalRepresentative {
          name
          identification
          email
        }
        mandateSignatureUrl
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        currency {
          code
          symbol
          exchangeRate
          name
          status
        }
        multicurrency
        decimalSeparator
        invoicePreferences {
          defaultAnotation
          defaultTermsAndConditions
        }
        logo
        origin
        kindOfPerson
        nameObject {
          firstName
          secondName
          lastName
        }
        identificationObject {
          type
          number
          kindOfPerson
          dv
          nationalityKindOfPerson
        }
        seenTutorialWizard
        firstPOSInvoice
        subscription
        settings {
          canStampInvoices
          electronicInvoicing
          canCreateAiuInvoice
          canCreateExportInvoice
          showSaleTickets
          isActiveAutomationElectronicInvoice
          automationElectronicInvoiceIdResolution
          barcode
        }
        localSettings {
          idCategoryDefaultIncome
          idCategoryDefaultExpense
          shiftsEnabled
          shiftsConfigured
          multicurrencyEnabled
          automationFEEnabled
          showReminder5UVT
          ticketItemsDescription
          ticketCompanyLogo
          ticketUnitPrice
          printFormat
          printTemplate
          printAlignment
          printRightMargin
          printLeftMargin
          customPhrase
          imageOrQr
          notifications
          printItemFullLine
          tradeName
          printUnitOfMeasure
          printTotalLines
          showWompiIntegrationAvailable
          taxesIncludedInTotal
          showNumerationBlockNotification
          seenTutorialWizardPOS
          tipEnabled
          tipSuggested
          tipSuggestedValue
          tipAccount
          tipAccountPurchases
          tipAdditionalChargeId
          favoriteRegime
        }
        providers
        numberIibb
        conditionIibb
        activityStartDate
        monthIncome {
          income
          invoices
        }
        numberIibb
        conditionIibb
        activityStartDate
        dateFormat
        tradeName
        sector
        userNotifications {
          notifications
        }
      }
      hubspot {
        id
        cookieIdentify
        cookieMessages
        token
        tokenExpiration
      }
      membership {
        planName
        planKeyword
        dateStart
        dateEnd
        nextPaymentDate
        payRetryDate
        isPaid
      }
      permissions
      name
      lastName
      email
      role
      status
      position
      language
      seenWelcome
      seenNoIVADayNotification
      seenColombianNormChangeNotification
    }
  }
`;
export const deleteUserNotification = /* GraphQL */ `
  mutation DeleteUserNotification($notification: String) {
    deleteUserNotification(notification: $notification) {
      notifications
    }
  }
`;
export const createStation = /* GraphQL */ `
  mutation CreateStation($station: StationInput!) {
    createStation(station: $station) {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idInvoiceNumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const deleteStation = /* GraphQL */ `
  mutation DeleteStation($id: ID!) {
    deleteStation(id: $id) {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idInvoiceNumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const updateStation = /* GraphQL */ `
  mutation UpdateStation($station: StationInput!) {
    updateStation(station: $station) {
      channel
      id
      idCompany
      idLocal
      idCash
      idDebit
      idCredit
      idTransfer
      idCostCenter
      idNumberTemplate
      idFENumberTemplate
      idInvoiceNumberTemplate
      idRefundNumberTemplate
      idCashReceiptNumberTemplate
      idWarehouse
      idPriceList
      name
      closeBank
      baseBank
      pendingInvoicesEnabled
      pendingInvoicesPrefix
      isActive
      seller
      users {
        id
        idCompany
        idUser
        idStation
      }
    }
  }
`;
export const changeStationUsers = /* GraphQL */ `
  mutation ChangeStationUsers($idStation: Int!, $userIds: [Int]!) {
    changeStationUsers(idStation: $idStation, userIds: $userIds) {
      id
      idCompany
      idUser
      idStation
    }
  }
`;
export const updateLastSyncDatetime = /* GraphQL */ `
  mutation UpdateLastSyncDatetime {
    updateLastSyncDatetime {
      lastSyncDatetime
    }
  }
`;
export const openShift = /* GraphQL */ `
  mutation OpenShift($shift: OpenShiftInput!) {
    openShift(shift: $shift) {
      channel
      id
      idLocal
      idCompany
      idStation
      start
      end
      startingAmount
      transfer
      refunds
      cash
      debit
      credit
      inPayment
      outPayment
      observations
    }
  }
`;
export const closeShift = /* GraphQL */ `
  mutation CloseShift($shift: CloseShiftInput!) {
    closeShift(shift: $shift) {
      channel
      id
      idLocal
      idCompany
      idStation
      start
      end
      startingAmount
      transfer
      refunds
      cash
      debit
      credit
      inPayment
      outPayment
      observations
      creditSales
      tipsTotalCash
      tipsTotalDebit
      tipsTotalCredit
      tipsTotalTransfer
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice($invoice: InvoiceInput!) {
    createInvoice(invoice: $invoice) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      regime
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
          rate
        }
        total
        applyNoIvaDays
        unit
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        url
        securityCode
        authorizationNumber
        authorizationDate
        tbaiIdentifier
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
      additionalCharges {
        id
        idCharge
        amount
        amountSecondaryCurrency
        categoryPurchases
        categorySales
        charge
        code
        description
        identificationNumber
        thirdPartyName
        typeCharge
      }
      uniqueCode
      qrCodeSelfInvoicingContent
      validDateToAssociateInvoice
    }
  }
`;

export const createPreInvoice = /* GraphQL */ `
  mutation CreatePreInvoice($invoice: InvoiceInput!) {
    createPreInvoice(invoice: $invoice) {
      message
      uuidInvoice
    }
  }
`;

export const createOffline = /* GraphQL */ `
  mutation CreateOffline($uuidInvoice: ID!) {
    createOffline(uuidInvoice: $uuidInvoice) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        unit
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        url
        securityCode
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
      additionalCharges {
        id
        idCharge
        amount
        amountSecondaryCurrency
        categoryPurchases
        categorySales
        charge
        code
        description
        identificationNumber
        thirdPartyName
        typeCharge
      }
    }
  }
`;


export const beforeCreateInvoice = /* GraphQL */ `
  mutation BeforeCreateInvoice($invoice: InvoiceInput!) {
    beforeCreateInvoice(invoice: $invoice) {
      data
    }
  }
`;

export const afterCreateInvoice = /* GraphQL */ `
  mutation AfterCreateInvoice($invoiceData: AfterInvoiceInput!) {
    afterCreateInvoice(invoiceData: $invoiceData) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        url
        securityCode
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
      additionalCharges {
        id
        idCharge
        amount
        amountSecondaryCurrency
        categoryPurchases
        categorySales
        charge
        code
        description
        identificationNumber
        thirdPartyName
        typeCharge
      }
    }
  }
`;

export const handleErrorCreateInvoice = /* GraphQL */ `
  mutation HandleErrorCreateInvoice($errorData: AfterInvoiceInput!) {
    handleErrorCreateInvoice(errorData: $errorData) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        url
        securityCode
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
      additionalCharges {
        id
        idCharge
        amount
        amountSecondaryCurrency
        categoryPurchases
        categorySales
        charge
        code
        description
        identificationNumber
        thirdPartyName
        typeCharge
      }
    }
  }
`;

export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice($invoice: InvoiceInput!) {
    updateInvoice(invoice: $invoice) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      regime
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
      additionalCharges {
        id
        idCharge
        amount
        amountSecondaryCurrency
        categoryPurchases
        categorySales
        charge
        code
        description
        identificationNumber
        thirdPartyName
        typeCharge
      }
    }
  }
`;
export const cancelInvoice = /* GraphQL */ `
  mutation CancelInvoice($id: ID!, $payments: [ID]!, $cause: String, $idStation: ID!) {
    cancelInvoice(id: $id, payments: $payments, cause: $cause, idStation: $idStation) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
    }
  }
`;
export const sendInvoice = /* GraphQL */ `
  mutation SendInvoice($id: ID!, $emails: String) {
    sendInvoice(id: $id, emails: $emails) {
      channel
      id
      idStation
      idLocal
      idShift
      totalReceived
      cashReturned
      anotation
      balance
      barCodeContent
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      datetime
      dueDate
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      operationType
      payments {
        channel
        id
        idStation
        idLocal
        idShift
        number
        date
        amount
        bankAccount {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        categories {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        client {
          channel
          id
          address {
            address
            city
            colony
            country
            department
            description
            district
            township
            geographicCoordinates
            locality
            municipality
            neighborhood
            postalCode
            province
            state
            street
            ubigeoCode
            urbanization
            zipCode
            exteriorNumber
            interiorNumber
          }
          email
          fax
          identification
          identificationObject {
            type
            number
            kindOfPerson
            dv
          }
          kindOfPerson
          mobile
          name
          nameObject {
            firstName
            lastName
          }
          phonePrimary
          phoneSecondary
          regime
          ivaCondition
          observations
          priceList {
            channel
            id
            idPriceList
            name
            price
            type
            main
            status
            percentage
          }
          seller {
            channel
            id
            name
            identification
            observations
            status
          }
          settings {
            sendElectronicDocuments
          }
          statementAttached
          status
          term {
            channel
            id
            days
            name
          }
        }
        costCenter {
          id
          code
          name
          description
          status
        }
        numberTemplate {
          id
          name
          prefix
          nextInvoiceNumber
          maxInvoiceNumber
          invoiceText
          isDefault
          status
          autoincrement
          isElectronic
          documentType
          branchOffice
          branchOfficeCode
          startDate
          endDate
          resolutionNumber
          subDocumentType
          number
          text
          fullNumber
          formattedNumber
          secondaryNumber
        }
        paymentMethod
        observations
        anotation
        status
        type
      }
      paymentMethod
      paymentForm
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      retentions {
        id
        name
        percentage
        amount
      }
      saleConcept
      saleCondition
      saleType
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        authorizationNumber
        authorizationDate
      }
      startDateService
      status
      termsConditions
      type
      total
      totalPaid
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthlyIncome
      monthlyIncomeLimit
      firstInvoice
      printHelp
      localCreationDatetime
      createdAt
      saleTicketHasItemsWithNoIvaDays
      monthIncome {
        income
        invoices
      }
    }
  }
`;
export const createRefund = /* GraphQL */ `
  mutation CreateRefund($refund: RefundInput!) {
    createRefund(refund: $refund) {
      channel
      id
      idLocal
      idStation
      idShift
      anotation
      balance
      barCodeContent
      cause
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      currency {
        code
        symbol
        exchangeRate
        name
        status
      }
      economicActivity
      endDateService
      date
      dateTime
      dueDate
      invoices {
        id
        prefix
        number
        date
        dueDate
        amount
        total
        balance
      }
      items {
        id
        description
        discount
        importRequest
        name
        price
        quantity
        reference
        remission
        tax {
          id
          name
          percentage
          description
          type
          status
        }
        total
        applyNoIvaDays
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      observations
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      refunds {
        id
        number
        date
        account {
          id
          name
          description
          initialBalance
          initialBalanceDate
          number
          status
          type
        }
        amount
        observations
      }
      saleConcept
      saleCondition
      saleType
      stamp {
        uuid
        satSeal
        stampDate
        cfdSeal
        cufe
        cae
        caeDueDate
        caea
        caeaDueDate
        satCertificateNumber
        expeditionPlace
        certificateNumber
        datetime
        date
        paymentMethod
        accountNumber
        legalStatus
        entityResponse
        electronicInvoicingVersion
        barCodeContent
        generateStamp
        mode
        fiscalQrUrl
        authorizationNumber
        authorizationDate
        warnings
      }
      startDateService
      status
      termsConditions
      type
      total
      totalApplied
      warehouse {
        channel
        id
        isDefault
        name
        observations
        status
        costCenter {
          id
          code
          name
          description
          status
        }
        address
      }
      monthIncome {
        income
        invoices
      }
      note
      emissionErrorMessage
      additionalCharges {
          id
          idCharge
          amount
          amountSecondaryCurrency
          categoryPurchases
          categorySales
          charge
          code
          description
          identificationNumber
          thirdPartyName
          typeCharge
        }
      creditNoteOperationType
      simpleCause
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($payment: PaymentInput!) {
    createPayment(payment: $payment) {
      channel
      id
      idStation
      idLocal
      idShift
      number
      date
      amount
      bankAccount {
        id
        name
        description
        initialBalance
        initialBalanceDate
        number
        status
        type
      }
      categories {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      paymentMethod
      observations
      anotation
      status
      type
    }
  }
`;
export const createInvoicePayment = /* GraphQL */ `
  mutation CreateInvoicePayment($payment: InvoicePaymentInput!) {
    createInvoicePayment(payment: $payment) {
      channel
      id
      idStation
      idLocal
      idShift
      number
      date
      amount
      bankAccount {
        id
        name
        description
        initialBalance
        initialBalanceDate
        number
        status
        type
      }
      categories {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      paymentMethod
      observations
      anotation
      status
      type
    }
  }
`;
export const updateInvoicePayment = /* GraphQL */ `
  mutation UpdateInvoicePayment($payment: InvoicePaymentInput!) {
    updateInvoicePayment(payment: $payment) {
      channel
      id
      idStation
      idLocal
      idShift
      number
      date
      amount
      bankAccount {
        id
        name
        description
        initialBalance
        initialBalanceDate
        number
        status
        type
      }
      categories {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      client {
        channel
        id
        address {
          address
          city
          colony
          country
          department
          description
          district
          township
          geographicCoordinates
          locality
          municipality
          neighborhood
          postalCode
          province
          state
          street
          ubigeoCode
          urbanization
          zipCode
          exteriorNumber
          interiorNumber
        }
        email
        fax
        identification
        identificationObject {
          type
          number
          kindOfPerson
          dv
        }
        kindOfPerson
        mobile
        name
        nameObject {
          firstName
          lastName
        }
        phonePrimary
        phoneSecondary
        regime
        ivaCondition
        observations
        priceList {
          channel
          id
          idPriceList
          name
          price
          type
          main
          status
          percentage
        }
        seller {
          channel
          id
          name
          identification
          observations
          status
        }
        settings {
          sendElectronicDocuments
        }
        statementAttached
        status
        term {
          channel
          id
          days
          name
        }
      }
      costCenter {
        id
        code
        name
        description
        status
      }
      numberTemplate {
        id
        name
        prefix
        nextInvoiceNumber
        maxInvoiceNumber
        invoiceText
        isDefault
        status
        autoincrement
        isElectronic
        documentType
        branchOffice
        branchOfficeCode
        startDate
        endDate
        resolutionNumber
        subDocumentType
        number
        text
        fullNumber
        formattedNumber
        secondaryNumber
      }
      paymentMethod
      observations
      anotation
      status
      type
    }
  }
`;
export const createPendingInvoice = /* GraphQL */ `
  mutation CreatePendingInvoice($pendingInvoice: PendingInvoiceInput!) {
    createPendingInvoice(pendingInvoice: $pendingInvoice) {
      channel
      id
      name
      invoice
      idCompany
      idStation
      createdAt
      updatedAt
    }
  }
`;
export const deletePendingInvoice = /* GraphQL */ `
  mutation DeletePendingInvoice($id: ID!) {
    deletePendingInvoice(id: $id) {
      channel
      id
      name
      invoice
      idCompany
      idStation
      createdAt
      updatedAt
    }
  }
`;
export const updatePendingInvoice = /* GraphQL */ `
  mutation UpdatePendingInvoice($pendingInvoice: PendingInvoiceInput!) {
    updatePendingInvoice(pendingInvoice: $pendingInvoice) {
      channel
      id
      name
      invoice
      idCompany
      idStation
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem($item: ItemInput!) {
    createItem(item: $item) {
      channel
      id
      name
      description
      reference
      price {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      tax {
        id
        name
        percentage
        description
        type
        status
      }
      category {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      inventory {
        unit
        availableQuantity
        unitCost
        initialQuantity
        warehouses {
          id
          name
          observations
          isDefault
          address
          status
          initialQuantity
          availableQuantity
          minQuantity
          maxQuantity
          negativeSale
        }
      }
      status
      type
      itemType
      productKey
      tariffHeading
      brand
      model
      itemCategory {
        channel
        id
        name
        description
        status
        image {
          id
          name
          url
        }
      }
      hasNoIvaDays
      customFields {
        id
        name
        key
        value
        description
        defaultValue
        resourceType
        status
        type
        settings
        options
      }
      images {
        id
        name
        url
      }
      kitWarehouse {
        id
        name
        observations
        isDefault
        address
        status
        initialQuantity
        availableQuantity
        minQuantity
        maxQuantity
        negativeSale
      }
      subitems
      variantAttributes
      itemVariants
      predial
      taxCondition {
        value
        description
      }
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem($item: ItemInput!) {
    updateItem(item: $item) {
      channel
      id
      name
      description
      reference
      price {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      tax {
        id
        name
        percentage
        description
        type
        status
      }
      category {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      inventory {
        unit
        availableQuantity
        unitCost
        initialQuantity
        warehouses {
          id
          name
          observations
          isDefault
          address
          status
          initialQuantity
          availableQuantity
          minQuantity
          maxQuantity
          negativeSale
        }
      }
      status
      type
      itemType
      productKey
      tariffHeading
      brand
      model
      itemCategory {
        channel
        id
        name
        description
        status
        image {
          id
          name
          url
        }
      }
      hasNoIvaDays
      customFields {
        id
        name
        key
        value
        description
        defaultValue
        resourceType
        status
        type
        settings
        options
      }
      images {
        id
        name
        url
      }
      kitWarehouse {
        id
        name
        observations
        isDefault
        address
        status
        initialQuantity
        availableQuantity
        minQuantity
        maxQuantity
        negativeSale
      }
      subitems
      variantAttributes
      itemVariants
      predial
      taxCondition {
        value
        description
      }
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem($id: ID!) {
    deleteItem(id: $id) {
      channel
      id
      name
      description
      reference
      price {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      tax {
        id
        name
        percentage
        description
        type
        status
      }
      category {
        id
        idCategoryReference
        idCategoryRule
        name
        children {
          id
          idCategoryReference
          idCategoryRule
          name
          children {
            id
            idCategoryReference
            idCategoryRule
            name
            code
            description
            isOriginal
            nature
            position
            profitAndLossSection
            readOnly
            type
            blocked
          }
          categoryRule {
            id
            key
            name
          }
          code
          description
          isOriginal
          nature
          position
          profitAndLossSection
          readOnly
          type
          blocked
        }
        categoryRule {
          id
          key
          name
        }
        code
        description
        isOriginal
        nature
        position
        profitAndLossSection
        readOnly
        type
        blocked
      }
      inventory {
        unit
        availableQuantity
        unitCost
        initialQuantity
        warehouses {
          id
          name
          observations
          isDefault
          address
          status
          initialQuantity
          availableQuantity
          minQuantity
          maxQuantity
          negativeSale
        }
      }
      status
      type
      itemType
      productKey
      tariffHeading
      brand
      model
      itemCategory {
        channel
        id
        name
        description
        status
        image {
          id
          name
          url
        }
      }
      hasNoIvaDays
      customFields {
        id
        name
        key
        value
        description
        defaultValue
        resourceType
        status
        type
        settings
        options
      }
      images {
        id
        name
        url
      }
      kitWarehouse {
        id
        name
        observations
        isDefault
        address
        status
        initialQuantity
        availableQuantity
        minQuantity
        maxQuantity
        negativeSale
      }
      subitems
      variantAttributes
      itemVariants
      predial
      taxCondition {
        value
        description
      }
    }
  }
`;
export const notifyItemCreate = /* GraphQL */ `
  mutation NotifyItemCreate($id: ID!, $channel: String) {
    notifyItemCreate(id: $id, channel: $channel) {
      channel
      id
    }
  }
`;
export const notifyItemUpdate = /* GraphQL */ `
  mutation NotifyItemUpdate($id: ID!, $channel: String) {
    notifyItemUpdate(id: $id, channel: $channel) {
      channel
      id
    }
  }
`;
export const notifyItemDelete = /* GraphQL */ `
  mutation NotifyItemDelete($id: ID!, $channel: String) {
    notifyItemDelete(id: $id, channel: $channel) {
      channel
      id
    }
  }
`;
export const updateFavoriteItem = /* GraphQL */ `
  mutation UpdateFavoriteItem($item: FavoriteItemInput) {
    updateFavoriteItem(item: $item) {
      id
      isFavorite
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($client: ClientInput!) {
    updateClient(client: $client) {
      channel
      id
      address {
        address
        city
        colony
        country
        department
        description
        district
        township
        geographicCoordinates
        locality
        municipality
        neighborhood
        postalCode
        province
        state
        street
        ubigeoCode
        urbanization
        zipCode
        exteriorNumber
        interiorNumber
      }
      email
      fax
      identification
      identificationObject {
        type
        number
        kindOfPerson
        dv
      }
      kindOfPerson
      mobile
      name
      nameObject {
        firstName
        lastName
      }
      phonePrimary
      phoneSecondary
      regime
      ivaCondition
      observations
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      settings {
        sendElectronicDocuments
      }
      statementAttached
      status
      term {
        channel
        id
        days
        name
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient($client: ClientInput!) {
    createClient(client: $client) {
      channel
      id
      address {
        address
        city
        colony
        country
        department
        description
        district
        township
        geographicCoordinates
        locality
        municipality
        neighborhood
        postalCode
        province
        state
        street
        ubigeoCode
        urbanization
        zipCode
        exteriorNumber
        interiorNumber
      }
      email
      fax
      identification
      identificationObject {
        type
        number
        kindOfPerson
        dv
      }
      kindOfPerson
      mobile
      name
      nameObject {
        firstName
        lastName
      }
      phonePrimary
      phoneSecondary
      regime
      ivaCondition
      observations
      priceList {
        channel
        id
        idPriceList
        name
        price
        type
        main
        status
        percentage
      }
      seller {
        channel
        id
        name
        identification
        observations
        status
      }
      settings {
        sendElectronicDocuments
      }
      statementAttached
      status
      term {
        channel
        id
        days
        name
      }
      creditLimit
    }
  }
`;
export const notifyClientCreate = /* GraphQL */ `
  mutation NotifyClientCreate($id: ID!, $channel: String) {
    notifyClientCreate(id: $id, channel: $channel) {
      channel
      id
    }
  }
`;
export const notifyClientUpdate = /* GraphQL */ `
  mutation NotifyClientUpdate($id: ID!, $channel: String) {
    notifyClientUpdate(id: $id, channel: $channel) {
      channel
      id
    }
  }
`;
export const notifyClientDelete = /* GraphQL */ `
  mutation NotifyClientDelete($id: ID!, $channel: String) {
    notifyClientDelete(id: $id, channel: $channel) {
      channel
      id
    }
  }
`;
export const createItemCategory = /* GraphQL */ `
  mutation CreateItemCategory($itemCategory: ItemCategoryInput!) {
    createItemCategory(itemCategory: $itemCategory) {
      channel
      id
      name
      description
      status
      image {
        id
        name
        url
      }
    }
  }
`;
export const updateItemCategory = /* GraphQL */ `
  mutation UpdateItemCategory($itemCategory: ItemCategoryInput!) {
    updateItemCategory(itemCategory: $itemCategory) {
      channel
      id
      name
      description
      status
      image {
        id
        name
        url
      }
    }
  }
`;
export const deleteItemCategory = /* GraphQL */ `
  mutation DeleteItemCategory($id: ID!) {
    deleteItemCategory(id: $id) {
      channel
      id
      name
      description
      status
      image {
        id
        name
        url
      }
    }
  }
`;
export const createVariant = /* GraphQL */ `
  mutation CreateVariant($variant: VariantInput!) {
    createVariant(variant: $variant) {
      id
      name
      status
      options {
        id
        value
        editable
      }
    }
  }
`;
export const updateVariant = /* GraphQL */ `
  mutation UpdateVariant($variant: VariantInput!) {
    updateVariant(variant: $variant) {
      id
      name
      status
      options {
        id
        value
        editable
      }
    }
  }
`;
export const deleteVariant = /* GraphQL */ `
  mutation DeleteVariant($id: ID!) {
    deleteVariant(id: $id) {
      id
      name
      status
      options {
        id
        value
      }
    }
  }
`;
export const createPriceList = /* GraphQL */ `
  mutation CreatePriceList($priceList: PriceListInput!) {
    createPriceList(priceList: $priceList) {
      channel
      id
      idPriceList
      name
      price
      type
      main
      status
      percentage
    }
  }
`;
export const updatePriceList = /* GraphQL */ `
  mutation UpdatePriceList($priceList: PriceListInput!) {
    updatePriceList(priceList: $priceList) {
      channel
      id
      idPriceList
      name
      price
      type
      main
      status
      percentage
    }
  }
`;
export const deletePriceList = /* GraphQL */ `
  mutation DeletePriceList($id: ID!) {
    deletePriceList(id: $id) {
      channel
      id
      idPriceList
      name
      price
      type
      main
      status
      percentage
    }
  }
`;
export const createWarehouse = /* GraphQL */ `
  mutation CreateWarehouse($warehouse: WarehouseInput!) {
    createWarehouse(warehouse: $warehouse) {
      channel
      id
      isDefault
      name
      observations
      status
      costCenter {
        id
        code
        name
        description
        status
      }
      address
    }
  }
`;
export const updateWarehouse = /* GraphQL */ `
  mutation UpdateWarehouse($warehouse: WarehouseInput!) {
    updateWarehouse(warehouse: $warehouse) {
      channel
      id
      isDefault
      name
      observations
      status
      costCenter {
        id
        code
        name
        description
        status
      }
      address
    }
  }
`;
export const deleteWarehouse = /* GraphQL */ `
  mutation DeleteWarehouse($id: ID!) {
    deleteWarehouse(id: $id) {
      channel
      id
      isDefault
      name
      observations
      status
      costCenter {
        id
        code
        name
        description
        status
      }
      address
    }
  }
`;
export const createPaymentTerm = /* GraphQL */ `
  mutation CreatePaymentTerm($paymentTerm: PaymentTermInput!) {
    createPaymentTerm(paymentTerm: $paymentTerm) {
      channel
      id
      days
      name
    }
  }
`;
export const createSeller = /* GraphQL */ `
  mutation CreateSeller($seller: SellerInput!) {
    createSeller(seller: $seller) {
      channel
      id
      name
      identification
      observations
      status
    }
  }
`;
export const updateSeller = /* GraphQL */ `
  mutation UpdateSeller($seller: SellerInput!) {
    updateSeller(seller: $seller) {
      channel
      id
      name
      identification
      observations
      status
    }
  }
`;
export const deleteSeller = /* GraphQL */ `
  mutation DeleteSeller($id: ID!) {
    deleteSeller(id: $id) {
      channel
      id
      name
      identification
      observations
      status
    }
  }
`;
export const createProviderPayment = /* GraphQL */ `
  mutation CreateProviderPayment($payment: ProviderPaymentInput) {
    createProviderPayment(payment: $payment) {
      channel
      id
      external_id
      merchant_provider_id
      terminal_id
      amount
      created_at
      metadata
      origen
      provider
      requestBody
      resource
      resource_id
      resource_type
      response_body
      state
      updated_at
      code
      type
    }
  }
`;
export const reverseProviderPayment = /* GraphQL */ `
  mutation ReverseProviderPayment($payment: ProviderPaymentInput) {
    reverseProviderPayment(payment: $payment) {
      channel
      id
      external_id
      merchant_provider_id
      terminal_id
      amount
      created_at
      metadata
      origen
      provider
      requestBody
      resource
      resource_id
      resource_type
      response_body
      state
      updated_at
      code
      type
    }
  }
`;
export const updateSubscription = /* GraphQL */ `
  mutation UpdateSubscription($subscription: PlanSubscriptionInput!) {
    updateSubscription(subscription: $subscription) {
      name
      period
    }
  }
`;
export const createCurrency = /* GraphQL */ `
  mutation CreateCurrency($currency: CurrencyInput!) {
    createCurrency(currency: $currency) {
      code
      symbol
      exchangeRate
      name
      status
    }
  }
`;
export const createCustomField = /* GraphQL */ `
  mutation CreateCustomField($customField: CustomFieldInput!) {
    createCustomField(customField: $customField) {
      id
      name
      key
      value
      description
      defaultValue
      resourceType
      status
      type
      settings
      options
    }
  }
`;
export const updateCustomField = /* GraphQL */ `
  mutation UpdateCustomField($customField: CustomFieldInput!) {
    updateCustomField(customField: $customField) {
      id
      name
      key
      value
      description
      defaultValue
      resourceType
      status
      type
      settings
      options
    }
  }
`;
export const deleteCustomField = /* GraphQL */ `
  mutation DeleteCustomField($id: ID!) {
    deleteCustomField(id: $id) {
      id
      name
      key
      value
      description
      defaultValue
      resourceType
      status
      type
      settings
      options
    }
  }
`;
export const createGlobalInvoice = /* GraphQL */ `
mutation CreateGlobalInvoice($globalInvoice: GlobalInvoiceInput!) {
  createGlobalInvoice(globalInvoice: $globalInvoice) {
    id
    status
    stamp {
      uuid
    }
    numberTemplate {
      id
      name
      prefix
      nextInvoiceNumber
      maxInvoiceNumber
      invoiceText
      isDefault
      status
      autoincrement
      isElectronic
      documentType
      branchOffice
      branchOfficeCode
      startDate
      endDate
      resolutionNumber
      subDocumentType
      number
      text
      fullNumber
      formattedNumber
      secondaryNumber
    }
  }
}
`
export const updateGlobalInvoice = /* GraphQL */ `
mutation UpdateGlobalInvoice($globalInvoice: GlobalInvoiceInput!) {
  updateGlobalInvoice(globalInvoice: $globalInvoice) {
    id
    status
    stamp {
      uuid
    }
    numberTemplate {
      id
      name
      prefix
      nextInvoiceNumber
      maxInvoiceNumber
      invoiceText
      isDefault
      status
      autoincrement
      isElectronic
      documentType
      branchOffice
      branchOfficeCode
      startDate
      endDate
      resolutionNumber
      subDocumentType
      number
      text
      fullNumber
      formattedNumber
      secondaryNumber
    }
  }
}
`
export const deleteGlobalInvoice = /* GraphQL */ `
mutation DeleteGlobalInvoice($id: ID!) {
  deleteGlobalInvoice(id: $id) {
    id
  }
}
`

export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod($paymentMethod: PaymentMethodInput!) {
    createPaymentMethod(paymentMethod: $paymentMethod) {
      code
      createdAt
      id
      idLocal
      instructions
      key
      logo {
        id
        url
        is_private
      }
      merchant_id
      name
      qr {
        id
        url
        is_private
      }
      status
      updatedAt
      value
    }
  }
`;

export const togglePaymentMethod = /* GraphQL */ `
  mutation TogglePaymentMethod($paymentMethod: PaymentMethodInput!) {
    togglePaymentMethod(paymentMethod: $paymentMethod) {
      code
      createdAt
      id
      idLocal
      instructions
      key
      logo {
        id
        url
        is_private
      }
      merchant_id
      name
      qr {
        id
        url
        is_private
      }
      status
      updatedAt
      value
  }
}`

export const sendEmail = /* GraphQL */ `
  mutation sendEmail($email: EmailInput!) {
    sendEmail(email: $email) {
      success
      message
    }
  }
`;

export const createBill = /* GraphQL */ `
 mutation CreateBill($bill: BillInput!){
  createBill(bill: $bill){
   id
      warehouse {
        id
        name
      }
      purchases {
        items {
          total
          subtotal
          quantity
          price
          discount
          observations
          name
          id
          tax {
            type
            status
            rate
            percentage
            name
            id
            description
          }
        }
      }
      provider {
        email
        fax
        identification
        id
        phoneSecondary
        phonePrimary
        name
        mobile
        identificationObject {
          number
        }
      }
      observations
      numberTemplate {
        fullNumber
        number
      }
      dueDate
      date
      currency {
        code
        exchangeRate
        name
        status
        symbol
      }
      payments {
        id
	      prefix
	      number
	      date
	      amount
	      paymentMethod
	      observations
	      anotation
	      status
      }
      total
      totalPaid
      balance
  }
}
`;

export const updateBill = /* GraphQL */ `
 mutation UpdateBill($bill: BillInput!){
  updateBill(bill: $bill){
   id
      warehouse {
        id
        name
      }
      purchases {
        items {
          total
          subtotal
          quantity
          price
          discount
          observations
          name
          id
          tax {
            type
            status
            rate
            percentage
            name
            id
            description
          }
        }
      }
      provider {
        email
        fax
        identification
        id
        phoneSecondary
        phonePrimary
        name
        mobile
        identificationObject {
          number
        }
      }
      observations
      numberTemplate {
        fullNumber
        number
      }
      dueDate
      date
      currency {
        code
        exchangeRate
        name
        status
        symbol
      }
      payments {
        id
	      prefix
	      number
	      date
	      amount
	      paymentMethod
	      observations
	      anotation
	      status
      }
      total
      totalPaid
      balance
  }
}
`;

export const deleteBill = /* GraphQL */ `
 mutation DeleteBill($id: ID!){
  deleteBill(id: $id){
   id
      warehouse {
        id
        name
      }
      purchases {
        items {
          total
          subtotal
          quantity
          price
          discount
          observations
          name
          id
          tax {
            type
            status
            rate
            percentage
            name
            id
            description
          }
        }
      }
      provider {
        email
        fax
        identification
        id
        phoneSecondary
        phonePrimary
        name
        mobile
        identificationObject {
          number
        }
      }
      observations
      numberTemplate {
        fullNumber
        number
      }
      dueDate
      date
      currency {
        code
        exchangeRate
        name
        status
        symbol
      }
      payments {
        id
	      prefix
	      number
	      date
	      amount
	      paymentMethod
	      observations
	      anotation
	      status
      }
      total
      totalPaid
      balance
  }
}
`;