import { Container, Space, useModal } from '@alegradev/smile-ui-react';
import InvoicesHeader from './header';
import InvoicesTable from './table';
import AutoInvoiceNotificationModal from '../notification/modal';
import '../../invoices/_invoices.scss';
import useNpsHistorySurvey from './hooks';
import { useCallback, useEffect } from 'react';
import { throttle } from 'lodash';
import useNotification from '../../../hooks/useNotification/hook';
import { POS_INVOICES_FIRST_VIEW } from '../utils/npsUtils';

const Invoices = () => {
  const { openModal, closeModal } = useModal();
  const { handleNps } = useNpsHistorySurvey();

  const { viewed, setViewed } = useNotification({
    notificationName: POS_INVOICES_FIRST_VIEW,
  });

  const throttledHandleNps = useCallback(
    throttle(() => {
      handleNps();
    }, 1000),
    [handleNps]
  );

  useEffect(() => {
    if (!viewed) {
      setViewed();
    }
    throttledHandleNps();
  }, []);
  return (
    <div className='invoices-container'>
      {/* <button
        onClick={() => {
          openModal({
            title: '  ',
            hideCloseButton: false,
            name: 'AutoInvoiceNotificationModal',
            component: AutoInvoiceNotificationModal,
            size: 'medium',
            onClose: closeModal,
          });
        }}
      >
        Hola
      </button> */}
      <InvoicesHeader />
      <Space height={12} />
      <InvoicesTable />
    </div>
  );
};

export default Invoices;
