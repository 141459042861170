import { toastHandler } from '@alegradev/smile-ui-react';
import { handleError } from '../../../utils/errors';

export const billKeys = {
  all: 'allBills',
  bill: 'bill',
};

export const DEFAULT_QUERY_PARAMS = ({ errorTitle }) => ({
  onError: (error) => {
    toastHandler({
      title: errorTitle,
      description: handleError(error),
      type: 'error',
    });
  },
  retry: 4,
  keepPreviousData: true,
  staleTime: 1000 * 60 * 60 * 24,
  refetchOnReconnect: 'always',
});
