import React from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux'
import { get } from 'lodash';

import { activeWarehouses } from '../../../selectors/warehouses'
import { country as countrySelector, decimalSeparator } from '../../../selectors/company';
import { getWarehouseHelpUrl } from '../../../utils';
import { renderSelect, renderField } from '../fields/V0/Fields';
import { renderField as renderNewField } from '../fields/V2';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';

const Inventory = ({ form, values }) => {
  const warehouses = useSelector(activeWarehouses)
  const country = useSelector(countrySelector);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const separator = useSelector(decimalSeparator);

  return (
    <>
      {!!(warehouses.length === 1) && (
        <Field
          name="warehouse"
          component={renderField}
          className="col-6"
          type="number"
          label={capitalize(I18n.get('warehouse', 'bodega'))} validate={value => !value ? capitalize(I18n.get('thisFieldIsRequired', 'Este campo es obligatorio')) : null}
          placeholder={get(warehouses[0], 'name')}
          helpTooltip={(
            <p className='text-white'>
              {I18n.get('productDistributionHelp.init', 'Conoce')}
              <a href={getWarehouseHelpUrl(country)} target="_blank" rel="noreferrer">
                {I18n.get('productDistributionHelp.mid', ' cómo distribuir en diferentes bodegas')}
              </a>
              {I18n.get('productDistributionHelp.end', ' este producto.')}
            </p>
          )}
          disabled
        />
      )}
      {!!(warehouses.length > 1) && (
        <Field
          name="warehouse"
          component={renderSelect}
          className="col-6"
          options={warehouses}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          label={capitalize(I18n.get('warehouse', 'bodega'))}
          menuPosition="absolute"
          required
        />
      )}

      <div className="col-3">
      <Field
        name="initialQuantity"
        component={renderNewField}
        placeholder=''
        fieldType="decimal"
        size="small"
        onDecimalsError={(status) => form?.change('decimalsError', {
          ...values?.decimalsError,
          initialQuantity: status,
        })}
        decimals={isDecimalActive ? 4 : null}
        separator={separator}
        label={capitalize(I18n.get('quantity', 'cantidad'))}
        required
      />
      </div>

      <div className="col-3">
      <Field
        name="unitCost"
        component={renderNewField}
        placeholder=''
        fieldType="decimal"
        size="small"
        onDecimalsError={(status) => form?.change('decimalsError', {
          ...values?.decimalsError,
          unitCost: status,
        })}
        decimals={isDecimalActive ? 6 : null}
        separator={separator}
        label={capitalize(I18n.get('unitCostSimplified', 'costo inicial'))}
        helpTooltip={I18n.get('unitCostHelp', "Indica el valor promedio de adquisición de las unidades iniciales de este producto.")}
        required
      />
      </div>
    </>
  )
}

export default Inventory;
