import { get } from 'lodash';

export const validate = () => {
  return {}
}

export const transform = (values, { country, isElectronic }) => {
  let transformedValues = {
    priceList: get(values, 'priceList', null),
    currency: get(values, 'currency', null),
    numeration: get(values, 'numeration', null),
  }

  if(country === 'costaRica'){
    transformedValues = {
      ...transformedValues,
      economicActivity: get(values, 'economicActivity', null),
    }
  }

  if (country === 'colombia' && isElectronic) {
    transformedValues = {
      ...transformedValues,
      type: get(values, 'type.key', null),
      deliveryTerm: get(values, 'deliveryTerm.key', null),
      operationType: get(values, 'operationType.key', null),
      paymentTerm: get(values, 'paymentTerm', null),
    }
  }

  if (country === 'mexico' && !!get(values, 'regime.value', null)) {
    transformedValues = {
      ...transformedValues,
      regime: get(values, 'regime.value', null),
    }
  }

  return transformedValues
}