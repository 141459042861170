import { Table, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useInvoiceDetail } from '../context';
import { get, isEmpty } from 'lodash';
import { useMemo } from 'react';
import invoiceDetailsColumns from './columns';
import { getFmt } from '../../../../components/invoices/utils';
import { getMainCurrency } from '../../../../selectors/currencies';
import {
  companySelector,
  decimalPrecision,
} from '../../../../selectors/company';
import { useSelector } from 'react-redux';
import InvoiceEditTable from './InvoiceEditTable';

const InvoiceDetailTable = ({ invoice, updateItemPrice }) => {
  const { data } = useInvoiceDetail();
  const dataInvoice = isEmpty(data) ? invoice : data;
  const company = useSelector(companySelector);
  const companyDecimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const decimal = !!get(dataInvoice, 'decimalPrecision', null)
    ? Number(dataInvoice.decimalPrecision)
    : companyDecimal;
  const fmt = getFmt(dataInvoice, mainCurrency);
  const { isEditable } = useInvoiceDetail();

  const columns = useMemo(
    () => invoiceDetailsColumns(decimal, fmt, company, isEditable),
    [decimal, fmt, company, isEditable]
  );
  return (
    <div>
      <div className='d-flex mb-3'>
        <Typography
          type='label-2'
          text={I18n.get('warehouse', 'Almacén') + ': '}
        />
        <Typography
          type='body-3-regular'
          text={get(dataInvoice, 'warehouse.name')}
        />
      </div>
      {isEditable ? (
        <InvoiceEditTable
          items={get(dataInvoice, 'items', [])}
          updateItemPrice={updateItemPrice}
        />
      ) : (
        <Table columns={columns} data={get(dataInvoice, 'items', [])} simple />
      )}
    </div>
  );
};

export default InvoiceDetailTable;
