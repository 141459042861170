import { Icon, TableCellRegular, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import dayjs from 'dayjs';
import { get } from 'lodash';

const InvoiceDetailGlobalInvoicesTableColumns = () => {
  return [
    {
      Header: I18n.get('', 'Folio'),
      accessor: 'numerTemplate.number',
      width: '15%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='invoices-global-invoice-folio d-flex align-items-center'>
            <Typography
              type='caption-bold'
              text={'#' + get(original, 'numberTemplate.number')}
            />
            <Icon icon='arrow-up-right' color='#15803D' size='extraSmall' />
          </div>
        );
      },
    },
    {
      Header: I18n.get('date', 'Fecha'),
      accessor: 'date',
      width: '15%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCellRegular
            value={dayjs(get(original, 'date', null)).format('DD/MM/YYYY')}
          />
        );
      },
    },
    {
      Header: I18n.get('', 'Estado de timbre'),
      accessor: 'status',
      width: '20%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='d-flex align-items-center gap-2'>
            <Icon icon='check' color='#16A34A' size='small' />
            <Typography type='caption-regular' text='Timbrado' />
          </div>
        );
      },
    },
    {
      Header: I18n.get('', 'Folio fiscal'),
      accessor: 'stamp.uuid',
      width: '50%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='d-flex gap-2'>
            <Typography
              type='body-3-regular'
              text={get(original, 'stamp.uuid', '')}
              variant='tertiary'
              maxLength={18}
            />
            <Icon icon='copy' size='small' />
          </div>
        );
      },
    },
  ];
};

export default InvoiceDetailGlobalInvoicesTableColumns;
