import { Grid, Loader, Space } from '@alegradev/smile-ui-react';
import { createContext, useContext } from 'react';
import { useBillQuery } from '../../services/hooks';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../../selectors/app';
import { useParams } from 'react-router-dom';
import { I18n } from 'aws-amplify';

const BillFormContext = createContext({});

function BillFormProvider({ children }) {
  const { Wrapper } = Grid;
  const params = useParams();
  const api = useSelector(APIGraphqlSelector);
  const { isFetching } = useBillQuery(params?.id, api, true);

  if (isFetching) {
    return (
      <Loader
        size='large'
        emphasis='page'
        orientation='vertical'
        title={I18n.get('loadingBill', 'Cargando factura')}
      />
    );
  }

  return (
    <BillFormContext.Provider value={{}}>
      <Wrapper fullWidth fullHeight>
        {children}
      </Wrapper>
      <Space height={20} />
    </BillFormContext.Provider>
  );
}

function useBillForm() {
  return useContext(BillFormContext);
}

export { BillFormProvider, useBillForm };
