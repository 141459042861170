import { PageHeading } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { useParams } from 'react-router-dom';

function BillHeader() {
  const history = useHistory();
  const params = useParams();

  return (
    <PageHeading
      back={{
        title: I18n.get('purchases', 'Compras'),
        action: () => history.push('/bills'),
      }}
      title={
        params?.id
          ? I18n.get('editPurchase', 'Editar compra')
          : I18n.get('newPurchase', 'Nueva compra')
      }
    />
  );
}

export default BillHeader;
