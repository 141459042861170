import { TableCellRegular } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { get } from 'lodash';
export const getPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case 'cash':
      return I18n.get('cash', 'Efectivo');
    case 'credit-card':
      return I18n.get('creditCard', 'Credito');
    case 'debit-card':
      return I18n.get('debitCard', 'Debito');
    case 'transfer':
      return I18n.get('transfer', 'Transferencia');
    default:
      return paymentMethod;
  }
};
const PaymentsReceivedTableColumns = (decimal, fmt) => {
  return [
    {
      Header: I18n.get('number', 'Número'),
      accessor: 'number',
      width: '20%',
      Cell: ({ row }) => {
        const { original } = row;
        return <TableCellRegular value={get(original, 'number')} />;
      },
    },
    {
      Header: I18n.get('date', 'Fecha'),
      accessor: 'date',
      alignment: 'right',
      width: '25%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCellRegular
            value={dayjs(get(original, 'date')).format('MMM D, YYYY')}
          />
        );
      },
    },
    {
      Header: I18n.get('paymentMethod', 'Metodo de pago'),
      accessor: 'paymentMethod',
      alignment: 'right',
      width: '30%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCellRegular
            value={getPaymentMethod(get(original, 'paymentMethod'))}
          />
        );
      },
    },
    {
      Header: I18n.get('total', 'Total'),
      accessor: 'amount',
      alignment: 'right',
      width: '25%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCellRegular
            value={new BigNumber(original?.amount).toFormat(decimal, fmt)}
          />
        );
      },
    },
  ];
};

export default PaymentsReceivedTableColumns;
