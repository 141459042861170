import { Container, EmptyState, Loader, Grid } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { I18n } from 'aws-amplify';

import { useBillQuery } from '../services/hooks';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../selectors/app';
import BillDetailBody from './Body';
import BillDetailHeader from './Header';
import { hasPermissionTo } from '../../../selectors/auth';
import { IconLock } from '@tabler/icons-react';
import { billTransformer } from '../services/transformers';

function BillDetail({ id }) {
  const params = useParams();
  const { Wrapper } = Grid;
  const can = useSelector(hasPermissionTo);
  const api = useSelector(APIGraphqlSelector);
  const { data, isLoading, isFetching } = useBillQuery(
    id || params?.id,
    api,
    true
  );
  const history = useHistory();

  if (isLoading || isFetching) {
    return (
      <Loader
        size='large'
        emphasis='page'
        orientation='vertical'
        title={I18n.get('loadingBill', 'Cargando factura')}
      />
    );
  }

  if (!data) {
    return (
      <Wrapper
        justify={{ lg: 'center' }}
        align={{ lg: 'center' }}
        fullHeight
        fullWidth
      >
        <EmptyState
          title={I18n.get('noBillFound', 'No se encontro la factura')}
          description={I18n.get(
            'noClientFoundDescription',
            'Busca otra factura o recarga la pagina'
          )}
          action={{
            label: I18n.get('goBack', 'Volver'),
            handler: () => history.push('/bills'),
          }}
        />
      </Wrapper>
    );
  }

  if (!can('view', 'bills'))
    return (
      <EmptyState
        hideAction
        generalIcon={IconLock}
        title={I18n.get('youNeedPermissions', 'Necesitas autorización')}
        description={I18n.get(
          'youNeedPermissionsDescription',
          'Tu usuario tiene un perfil limitado, para ingresar a esta sección debes solicitar acceso a un usuario administrador.'
        )}
      />
    );

  return (
    <Container marginTop={30}>
      <BillDetailHeader bill={billTransformer(data)} />
      <BillDetailBody bill={billTransformer(data)} />
    </Container>
  );
}

export default BillDetail;
