import React, { useState, useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, isArray, isNumber, get, isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';

import { multitax, decimalPrecision, country as countrySelector, decimalSeparator } from '../../../selectors/company';
import { activeTaxes } from '../../../selectors/taxes';
import { renderField, renderSelect } from '../fields/V0/Fields';
import { renderField as renderNewField } from '../fields/V2';
import { languageSelector } from '../../../selectors/auth';
import getNoTax from '../../countriesData/general/noTax';
import { useDecimalsVersionsGroup } from '../../../hooks/useDecimalsVersionsGroup';
import { getTaxesLabel } from '../itemSimplified/utils';
import { COUNTRIES } from '../../../utils/enums/countries';
import { validateSelectTaxes } from '../../../utils';
import { countryConfig } from '../../../utils/format-money/countryConfig';

const NewPrice = ({ fieldRefs, ...props }) => {
  const [lastModified, setLastModified] = useState('salePrice');
  const [filteredTaxes, setFilteredTaxes] = useState([]);
  const taxes = useSelector(activeTaxes);
  const isMulti = useSelector(multitax);
  const decimal = useSelector(decimalPrecision);
  const language = useSelector(languageSelector);
  const country = useSelector(countrySelector);
  const separator = useSelector(decimalSeparator);
  const { isDecimalActive } = useDecimalsVersionsGroup();

  const noTax = useMemo(() => getNoTax(language.substring(0, 2)), [language]);

  const allTaxes = useMemo(() => [noTax, ...taxes], [noTax, taxes]);

  const calculateTotalPrice = (taxPercent) => {
    const salePrice = get(props, 'values.salePrice', '0');
    if (!!salePrice) {
      const price = new BigNumber(100).plus(new BigNumber(taxPercent)).dividedBy(100).multipliedBy(salePrice)
        .decimalPlaces(decimal).toNumber();
      if (isNumber(price)) props.form.change('price', '' + price);
    }
  };

  const calculateSalePrice = (taxPercent) => {
    const price = get(props, 'values.price', '0');
    if (!!price) {
      const salePrice = new BigNumber(price).multipliedBy(100).dividedBy(new BigNumber(taxPercent).plus(100))
        .decimalPlaces(isDecimalActive ? 6 : decimal).toNumber();
      if (isNumber(salePrice)) {
        if (!isDecimalActive || Number(salePrice.toFixed(decimal)) !== Number(price).toFixed(decimal))
          props.form.change('salePrice', '' + salePrice);
      }
    }
  };

  const calculateSalePriceWithIEPS = (taxes) => {
    const price = new BigNumber(get(props, 'values.price', '0'));
    const iepsRates = taxes
      .filter(t => get(t, "type", '') === "IEPS")
      .map(t => new BigNumber(t.percentage).dividedBy(100));
    const ivaRates = taxes
      .filter(t => get(t, "type", '') === "IVA")
      .map(t => new BigNumber(t.percentage).dividedBy(100));
    const otherTaxesRates = taxes
      .filter(t => get(t, "type", '') !== "IEPS" && get(t, "type", '') !== "IVA")
      .map(t => new BigNumber(t.percentage).dividedBy(100));

    // Calcular el total de las tasas de IEPS
    const totalIepsRate = iepsRates.reduce((acc, rate) => acc.plus(rate), new BigNumber(0));

    // Calcular el total de las tasas de IVA considerando el IEPS
    const totalIvaRate = ivaRates.reduce((acc, rate) => acc.plus(rate.plus(rate.multipliedBy(totalIepsRate))), new BigNumber(0));

    // Calcular el total de las tasas de otros impuestos
    const totalOtherTaxesRate = otherTaxesRates.reduce((acc, rate) => acc.plus(rate), new BigNumber(0));

    // Calcular el denominador para el precio base
    const denominator = new BigNumber(1).plus(totalIepsRate).plus(totalIvaRate).plus(totalOtherTaxesRate);

    // Calcular el precio base
    const salePrice = price.dividedBy(denominator).decimalPlaces(6).toNumber()
    if (isNumber(salePrice))
      props.form.change('salePrice', '' + salePrice);
  }

  const calculateTotalPriceWithIEPS = (taxes) => {
    const salePrice = new BigNumber(get(props, 'values.salePrice', '0'));

    const iepsValue = taxes.reduce((acc, t) => {
      if (get(t, "type", '') === "IEPS") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(salePrice))
      }
      return acc
    }, new BigNumber(0))

    const totalTaxValue = taxes.reduce((acc, t) => {
      if (get(t, "type", '') === "IVA") {
        return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(iepsValue.plus(salePrice)))
      }
      return acc.plus(new BigNumber(t.percentage).dividedBy(100).multipliedBy(salePrice))
    }, new BigNumber(0))

    const price = new BigNumber(salePrice).plus(totalTaxValue).decimalPlaces(decimal).toNumber();

    if (isNumber(price)) props.form.change('price', '' + price);
  }

  const calculatePriceValues = (lastTouched = null) => {
    let taxes = get(props, 'values.tax', []);
    taxes = isArray(taxes) ? taxes : [taxes];

    const haveIEPStax = taxes.some(t => get(t, "type", '') === "IEPS");
    const haveIVAtax = taxes.some(t => get(t, "type", '') === "IVA");

    if (country === COUNTRIES.MEXICO && haveIEPStax && haveIVAtax) {
      if (!!lastTouched) {
        if (lastTouched === 'price') calculateSalePriceWithIEPS(taxes);
        else calculateTotalPriceWithIEPS(taxes);
        setLastModified(lastTouched);
      } else {
        if (lastModified === 'price') calculateSalePriceWithIEPS(taxes);
        else calculateTotalPriceWithIEPS(taxes);
      }
    } else {
      const totalPercent = !!taxes[0]
        ? taxes.reduce((prevTax, tax) => prevTax + +get(tax, 'percentage'), 0)
        : 0;
      if (!!lastTouched) {
        if (lastTouched === 'price') calculateSalePrice(totalPercent);
        else calculateTotalPrice(totalPercent);
        setLastModified(lastTouched);
      } else {
        if (lastModified === 'price') calculateSalePrice(totalPercent);
        else calculateTotalPrice(totalPercent);
      }
    }
  };

  useEffect(() => {
    if (isMulti && country !== 'peru') {
      let noTaxActive = false;
      const taxes = get(props, 'values.tax', []);
      if (isArray(taxes)) {
        noTaxActive = taxes[taxes.length - 1]?.id === -1;
      } else {
        noTaxActive = true;
      }

      if (noTaxActive) {
        // Check if the last tax is already noTax
        const currentTaxIds = taxes.map(tax => tax.id);
        const desiredTaxIds = [noTax.id];
        if (!isEqual(currentTaxIds, desiredTaxIds)) {
          props.form.change('tax', [noTax]);
        }
      } else if (isArray(props.values.tax)) {
        // Filter out noTax if it's not active
        const filteredTaxes = props.values.tax.filter((tax) => tax.id !== noTax.id);
        const currentTaxIds = props.values.tax.map(tax => tax.id);
        const desiredTaxIds = filteredTaxes.map(tax => tax.id);
        if (!isEqual(currentTaxIds, desiredTaxIds)) {
          props.form.change('tax', filteredTaxes);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTax, props.values.tax]);

  useEffect(() => {
    const filterTaxes = (allTaxes, selectedTaxes) => {
      selectedTaxes = isArray(selectedTaxes) ? selectedTaxes : [selectedTaxes];
      const selectedTaxTypes = new Set(selectedTaxes.map((tax) => tax.type));

      return allTaxes.filter((tax) => {
        return selectedTaxes.some((selectedTax) => tax.id === selectedTax.id) || !selectedTaxTypes.has(tax.type);
      });
    };

    if (country === 'republicaDominicana') {
      setFilteredTaxes(filterTaxes(allTaxes, get(props, 'values.tax', [])));
    } else {
      setFilteredTaxes(allTaxes);
    }
  }, [props.values.tax, country, allTaxes]);

  return (
    <>
      <div className="col-sm-4 col-6  d-flex">
        <div ref={fieldRefs.salePrice}></div>
        <div className="col-11 p-0 pr-2">
        <Field
          name="salePrice"
          component={renderNewField}
          fieldType="decimal"
          placeholder={countryConfig[country]?.currencyPrefix || ''}
          decimals={isDecimalActive ? 6 : null}
          separator={separator}
          required
          onDecimalsError={(status) => props?.form?.change('decimalsError', {
            ...props?.values?.decimalsError,
            salePrice: status,
          })}
          label={capitalize(I18n.get('priceBeforeTaxes', 'precio sin impuestos'))}
          size="small"
          onBlur={() => calculatePriceValues('salePrice')}
        />
        </div>

        <div
          className="text-primary col-1 p-0 text-center"
          style={{
            lineHeight: '25px',
            fontSize: '20px',
            marginTop: '3rem',
          }}
        >
          +
        </div>
      </div>

      <div className="col-sm-4 col-6 d-flex">
        <Field
          name="tax"
          component={renderSelect}
          showFavoriteIcon={false}
          className="col-11 p-0 pr-2"
          options={filteredTaxes}
          getOptionLabel={(option) => getTaxesLabel(country, option)}
          getOptionValue={(option) => option.id}
          label={capitalize(I18n.get('taxes', 'impuestos'))}
          placeholder={I18n.get('none', 'ninguno')}
          isMulti={country === 'peru' ? false : isMulti}
          onInputChange={() => calculatePriceValues()}
          helpTooltip={
            country !== 'peru' && isMulti
              ? I18n.get('taxesHelp', 'Si tu producto tiene varios impuestos, puedes seleccionarlos dando clic sobre el nombre de cada uno en el listado desplegable.')
              : null
          }
          onChange={(values) => validateSelectTaxes({ values, country, isMultitax: isMulti })}
        />

        <div
          className="text-primary col-1 p-0 text-center"
          style={{
            lineHeight: '25px',
            fontSize: '20px',
            marginTop: '3rem',
          }}
        >
          =
        </div>
      </div>

      <div ref={fieldRefs.price}></div>
     <div className="col-sm-4 col-12">
      <Field
        name="price"
        component={renderNewField}
        fieldType="decimal"
        placeholder={countryConfig[country]?.currencyPrefix || ''}
        decimals={isDecimalActive ? decimal : null}
        separator={separator}
        required
        onDecimalsError={(status) => props?.form?.change('decimalsError', {
          ...props?.values?.decimalsError,
          price: status,
        })}
        label={capitalize(I18n.get('finalPrice', 'precio final'))}
        size="small"
        onBlur={() => calculatePriceValues('price')}
      />
     </div>
    </>
  );
};

export default NewPrice;
