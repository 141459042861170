import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { I18n } from '@aws-amplify/core';
import { get, capitalize } from 'lodash';

import { calculateItemsValues } from '../utils'
import { companySelector, country, decimalPrecision, favoriteRegime, membershipPlanSelector, membershipSelector, regimes } from './company';
import { getRegimeByKey } from '../components/countriesData/mexico/regimes';

const exampleItems = [{
  id: '-1',
  name: capitalize(I18n.get('item', 'producto')),
  description: capitalize(I18n.get('description', 'Descripción')),
  reference: null,
  price: '20000.0000',
  tax: [],
  status: 'active',
  type: 'simple',
  quantity: 3
}]

const itemsSelector = state => {
  const isOnboardingOpen = get(state, 'tours.onboarding.isOpen')
  return !!isOnboardingOpen
    ? exampleItems: get(state, 'activeInvoice.items')
}
const clientSelector = state => state.activeInvoice.client;
const priceListSelector = state => state.activeInvoice.priceList;
const currencySelector = state => state.activeInvoice.currency;
const numerationSelector = state => state.activeInvoice.numeration;
const economicActivitySelector = state => state.activeInvoice.economicActivity;
const regime = state => state.activeInvoice.regime;
export const tipSelector = state => state.activeInvoice.tip;
const paymentMethodSelector = state => state.activeInvoice.paymentMethodSelected;
const isPaymentMethodSelectedSelector = state => state.activeInvoice.isPaymentMethodSelected;

export const activeInvoice = createDraftSafeSelector(
  state => state.activeInvoice,
  activeInvoice => activeInvoice
)

export const client = createDraftSafeSelector(
  clientSelector,
  client => !!client ? client : null
)

export const priceList = createDraftSafeSelector(
  priceListSelector,
  priceList => !!priceList ? priceList : null
)

export const currency = createDraftSafeSelector(
  currencySelector,
  currency => !!currency ? currency : null
)

export const numeration = createDraftSafeSelector(
  numerationSelector,
  numeration => !!numeration ? numeration : null
)

export const economicActivity = createDraftSafeSelector(
  economicActivitySelector,
  activity => !!activity ? activity : null
)

export const items = createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items : []
)

export const itemsCount = createDraftSafeSelector(
  itemsSelector,
  items => !!items
    ? items
      .map(item => +get(item, 'quantity', 0))
      .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemQuantity = itemId => createDraftSafeSelector(
  itemsSelector,
  items => !!items
    ? items
      .filter(i => i?.id === itemId)
      .map(item => +get(item, 'quantity', 0))
      .reduce((prev, curr) => prev + curr, 0)
    : 0
)

export const itemByIndex = index => createDraftSafeSelector(
  itemsSelector,
  items => !!items ? items[index] : null
)

export const subtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, priceList: priceListSelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).subtotal
    : 0
)

export const rawSubtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, priceList: priceListSelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).rawSubtotal
    : 0
)

export const discount = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, priceList: priceListSelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).discount
    : 0
)

export const discSubtotal = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, priceList: priceListSelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).discSubtotal
    : 0
)

export const taxes = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector],
  (items, decimal, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, country, company: companySelector, priceList: priceListSelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).taxes
    : 0
)

export const noITBMS = createDraftSafeSelector(
  [itemsSelector, country],
  (items, country) => {
    if (country !== 'panama')
      return false;

    let noTaxes = false;
    items.forEach(item => {
      if (get(item, 'tax', []).length === 0)
        noTaxes = true;
    });

    return noTaxes
  }
)

export const total = createDraftSafeSelector(
  [itemsSelector, decimalPrecision, tipSelector, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector],
  (items, decimal, tips, country, companySelector, priceListSelector, membershipSelector, membershipPlanSelector) => !!items
    ? calculateItemsValues({items, decimal, tips, country, company: companySelector, priceList: priceListSelector, membership: Object.keys(membershipSelector) > 0 ? membershipSelector : membershipPlanSelector}).total
    : 0
)

export const paymentMethod = createDraftSafeSelector(
  paymentMethodSelector,
  (paymentMethod) => (!!paymentMethod ? paymentMethod : null)
);

export const isPaymentMethodSelected = createDraftSafeSelector(
  isPaymentMethodSelectedSelector,
  (isPaymentMethodSelected) => (!!isPaymentMethodSelected ? isPaymentMethodSelected : false)
);

export const settings = createDraftSafeSelector(
  [currency, priceList, numeration],
  (currency, priceList, numeration) => ({
    currency,
    priceList,
    numeration,
    operationType: 'STANDARD',
    type: 'NATIONAL',
    paymentTerm: 1,
    dueDate: null,
    deliveryTerm: null,
  })
)

export const regimeSelector = createDraftSafeSelector(
  [favoriteRegime, regimes, country, regime],
  (favoriteRegime, regimes, country, regime) => {
    if (country !== 'mexico' || !regimes || !regimes.length)
      return null;

    return regime || 
      get(getRegimeByKey(favoriteRegime || get(regimes, '[0]', '')), 'value', null)
  }
)

export const initialRegimeSelector = createDraftSafeSelector(
  [favoriteRegime, regimes, country],
  (favoriteRegime, regimes, country) => {
    if (country !== 'mexico' || !regimes || !regimes.length)
      return null;

    return get(getRegimeByKey(favoriteRegime || get(regimes, '[0]', '')), 'value', null)
  }
)