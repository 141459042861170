import { Form } from 'react-final-form';
import InvoiceDetailHeader from '../content/header';
import InvoiceDetailTable from '../table';
import InvoiceDetailSummary from '../content/summary';
import InvoiceDetailAutoInvoicesTable from '../auto-invoice/table';
import InvoiceDetailGlobalInvoicesTable from '../global-invoices/table';
import PaymentsReceivedTable from '../payments/table';
import InvoiceEditableFooter from '../table/InvoiceEditableFooter';
import { useDispatch, useSelector } from 'react-redux';
import { useFormat } from '../../../../hooks/useFormat';
import { useEffect, useState } from 'react';
import { getInvoiceNumerations } from '../../../../selectors/numerations';
import { activeBanks } from '../../../../selectors/banks';
import {
  APIGraphqlSelector,
  station as stationSelector,
  stationCreditBank,
  stationDebitBank,
  stationTransferBank,
} from '../../../../selectors/app';
import { getMainCurrency } from '../../../../selectors/currencies';
import {
  companySelector,
  country as countrySelector,
  membershipSelector,
} from '../../../../selectors/company';
import { get, isEmpty, last } from 'lodash';
import * as mutations from '../../../../graphql/mutations';
import {
  itemReachedMinQuantity,
  setInvoice,
  singleItemReachedMinQuantityFromValues,
} from '../../../../reducers/editInvoice';
import { toast } from '../../../../utils';
import { graphqlOperation, I18n } from 'aws-amplify';
import { getItem } from '../../../../database/itemsDB';
import { checkStationValues } from '../../../../reducers/app';
import {
  initialValues,
  transform,
} from '../../../../components/invoices/EditInvoice/utils';
import { useInvoiceDetail } from '../context';
import useEditInvoice from '../hooks/useEditInvoice';
import arrayMutators from 'final-form-arrays';
import { formError, handleError } from '../../../../utils/errors';
import BigNumber from 'bignumber.js';

const updateItemPrice = (item, values, decimal) => {
  const priceList = get(values, 'priceList');
  const currency = get(values, 'currency');

  let price = get(item, 'originalPrice', 0);

  if (!get(item, 'priceModified') && !!priceList) {
    const itemPriceList = !!get(item, 'priceLists.length')
      ? get(item, 'priceLists').find(
          (list) => +get(list, 'idPriceList') === +get(priceList, 'id')
        )
      : null;
    if (!!itemPriceList) price = get(itemPriceList, 'price');
  }

  if (!!currency) {
    const exchangeRate = !!get(currency, 'exchangeRate')
      ? +get(currency, 'exchangeRate')
      : 1;
    price = new BigNumber(price)
      .dividedBy(new BigNumber(exchangeRate))
      .decimalPlaces(4)
      .toNumber();
  }

  price = new BigNumber(price).decimalPlaces(decimal).toNumber();

  return { ...item, price };
};
export const InvoiceDetailForm = ({
  value,
  fetchData,
  closePartibleFn,
  fromTableAction = false,
}) => {
  const numerations = useSelector(getInvoiceNumerations);
  const banks = useSelector(activeBanks);
  const debitBank = useSelector(stationDebitBank);
  const creditBank = useSelector(stationCreditBank);
  const transferBank = useSelector(stationTransferBank);
  const mainCurrency = useSelector(getMainCurrency);
  const country = useSelector(countrySelector);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const station = useSelector(stationSelector);
  const membership = useSelector(membershipSelector);
  const company = useSelector(companySelector);
  const { decimal } = useFormat();
  const { setIsEditable } = useInvoiceDetail();
  const [updateInvoiceLoading, setUpdateInvoiceLoading] = useState(false);

  const dispatch = useDispatch();

  const { showLimitReached } = useEditInvoice();

  useEffect(() => {
    if (fromTableAction) {
      setIsEditable(true);
    }
  }, [fromTableAction]);
  const submit = async (values, form) => {
    setUpdateInvoiceLoading(true);
    const items = get(values, 'items').filter(
      (i) => get(i, 'id', null) !== null
    );
    const completeItems = await Promise.all(
      items.map(async (item, index) => {
        return {
          ...(await getItem(parseInt(get(item, 'id', null)))),
          quantity: +get(items[index], 'quantity'),
        };
      })
    );
    dispatch(setInvoice({ ...value, items: completeItems }));

    const limitReachedItems = await Promise.all(
      completeItems.map(async (item, index) => {
        if (
          (await dispatch(
            itemReachedMinQuantity(item, singleItemReachedMinQuantityFromValues)
          )) &&
          get(item, 'quantity') !==
            get(form.getFieldState('items'), `initial[${index}].quantity`)
        ) {
          return item;
        }
      })
    );

    if (!isEmpty(limitReachedItems.filter((i) => i))) {
      showLimitReached(last(limitReachedItems));
      return;
    }
    try {
      const canContinue = await dispatch(
        checkStationValues({ type: 'edit-invoice', values })
      );
      if (!canContinue) return;

      const { updatedInvoice, updatedPayments, newPayments } = transform(
        values,
        {
          invoice: value,
          station,
          form,
          banks: {
            debit: debitBank,
            credit: creditBank,
            transfer: transferBank,
          },
          decimal,
          country,
          company,
          membership,
        }
      );

      try {
        await Promise.all([
          newPayments.length
            ? Promise.all(
                newPayments.map((payment) =>
                  APIGraphql(
                    graphqlOperation(mutations.createInvoicePayment, {
                      payment,
                    })
                  )
                )
              )
            : Promise.resolve(),
          updatedPayments.length
            ? Promise.all(
                updatedPayments.map((payment) =>
                  APIGraphql(
                    graphqlOperation(mutations.updateInvoicePayment, {
                      payment,
                    })
                  )
                )
              )
            : Promise.resolve(),
          APIGraphql(
            graphqlOperation(mutations.updateInvoice, {
              invoice: updatedInvoice,
            })
          ),
        ]);
        toast.success({
          title: I18n.get(
            'editInvoiceSuccessfully',
            '¡Ya se aplicaron tus cambios!'
          ),
          subtitle: I18n.get(
            'editInvoiceSuccessfullyMessage',
            'Puedes visualizar la nueva información desde el detalle de tu factura.'
          ),
        });
        fetchData({
          start: 0,
          limit: 20,
          sortDirection: 'desc',
        });
        setIsEditable(false);
        closePartibleFn();
      } catch (error) {
        toast.error({
          title: I18n.get('editInvoiceError', 'Revisa los datos que cambiaste'),
          subtitle: handleError(
            error,
            I18n.get(
              'editInvoiceErrorMessage',
              'Puedes visualizar la nueva información desde el detalle de tu factura.'
            )
          ),
        });
      }
    } catch (error) {
      return formError(
        error,
        I18n.get(
          'changeInvoiceError',
          'hubo un error al tratar de modificar la factura'
        )
      );
    } finally {
      setUpdateInvoiceLoading(false);
    }
  };

  return (
    <Form
      initialValues={
        get(value, 'offlineStatus', '') === 'error' ||
        get(value, 'offlineStatus', '') === 'pending' ||
        get(value, 'offlineStatus', '') === 'syncing'
          ? value
          : initialValues({
              invoice: value,
              numerations,
              banks,
              debitBank,
              creditBank,
              transferBank,
              mainCurrency,
              country,
            })
      }
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
      }}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, form, values, submitError, error }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div className='invoice-detail-container'>
            <InvoiceDetailHeader
              updateItemPrice={(item, values) =>
                updateItemPrice(item, values, decimal)
              }
            />
            <InvoiceDetailTable
              updateItemPrice={(item, values) =>
                updateItemPrice(item, values, decimal)
              }
            />
            <InvoiceDetailSummary values={values} />
            <InvoiceDetailAutoInvoicesTable />

            {get(value, 'globalInvoice.stamp.uuid', null) && (
              <InvoiceDetailGlobalInvoicesTable />
            )}

            <PaymentsReceivedTable />

            <InvoiceEditableFooter
              updateInvoiceLoading={updateInvoiceLoading}
            />
          </div>
        </form>
      )}
    </Form>
  );
};

export default InvoiceDetailForm;
