import { get } from 'lodash';
import { COUNTRIES } from '../../../utils/enums/countries';

export const canEdit = ({ invoice, can }) => {
  if (!invoice) return false;

  return (
    get(invoice, 'status') === 'open' &&
    !get(invoice, 'stamp') &&
    can('edit-open', 'invoices', true)
  );
};

export const canSendOrPrint = ({ invoice, country }) => {
  if (!invoice) return false;

  if (
    country !== COUNTRIES.PANAMA ||
    !get(invoice, 'numberTemplate.isElectronic')
  )
    return true;

  const isInvoiceCloses =
    get(invoice, 'stamp.legalStatus', null) === 'STAMPED_AND_ACCEPTED' ||
    get(invoice, 'stamp.legalStatus', null) ===
      'STAMPED_AND_ACCEPTED_WITH_OBSERVATIONS';
  return isInvoiceCloses;
};
