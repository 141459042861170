import BigNumber from 'bignumber.js';

export function billTransformer(bill) {
  const dataValues = {
    taxes: {},
    subtotal: parseFloat(bill?.subtotal) || 0,
    discount: parseFloat(bill?.discount) || 0,
    iva: parseFloat(bill?.iva) || 0,
    total: parseFloat(bill?.total) || 0,
    paid: parseFloat(bill?.totalPaid) || 0,
    toPay: parseFloat(bill?.balance) || 0,
  };

  bill?.purchases?.items?.forEach((product) => {
    const [price, quantity, discount] = [
      Number(product?.price || 0),
      Number(product?.quantity || 0),
      Number(product?.discount || 0),
    ];

    const discountedPrice = price * (1 - discount / 100);
    dataValues.subtotal += price * quantity;
    dataValues.discount += (price * discount) / 100;

    const productTaxes = Array.isArray(product?.tax)
      ? product.tax
      : [product?.tax];

    productTaxes.forEach((tax) => {
      if (tax) {
        const taxId = tax.id;
        const taxAmount =
          (discountedPrice * quantity * (Number(tax.percentage) || 0)) / 100;

        if (!dataValues.taxes[taxId]) {
          dataValues.taxes[taxId] = {
            ...tax,
            total: taxAmount,
          };
        } else {
          dataValues.taxes[taxId].total += taxAmount;
        }
      }
    });
  });

  return {
    id: bill?.id || '-',
    number: bill?.numberTemplate?.id || '-',
    provider: {
      name: bill?.provider?.name || '-',
      identificationNumber: bill?.provider?.identification || '-',
      phone:
        bill?.provider?.mobile ||
        bill?.provider?.phonePrimary ||
        bill?.provider?.phoneSecondary ||
        '-',
    },
    createdAt: bill?.date,
    expiresAt: bill?.dueDate,
    currency: bill?.currency,
    warehouse: {
      id: bill?.warehouse?.id || '-',
      name: bill?.warehouse?.name || '-',
    },
    products:
      bill?.purchases?.items?.map((product) => {
        const discountedPrice =
          product.price * (1 - Number(product.discount || 0) / 100);
        return {
          item: {
            image: product?.item?.image || '-',
            name: product?.name || '-',
            price: parseFloat(product?.price) || 0,
            reference: product?.reference || '-',
          },
          price: parseFloat(product?.price) || 0,
          discount: (product.price * (Number(product?.discount) || 0)) / 100,
          tax: Object.values(product.tax || {})
            .map(
              (tax) =>
                (discountedPrice *
                  product?.quantity *
                  (Number(tax.percentage) || 0)) /
                100
            )
            .reduce((p, c) => p.plus(c), new BigNumber(0))
            .toNumber(),
          quantity: parseFloat(product?.quantity) || 0,
          observations: product?.observations || '-',
          total: parseFloat(product?.total) || 0,
        };
      }) || [],
    ...dataValues,
    notes: bill?.notes || '-',
    providerPayment:
      bill?.payments?.length > 0
        ? bill.payments?.map((payment) => ({
            bankAccountName: payment?.bankAccountName || '-',
            total: parseFloat(payment?.amount) || 0,
          }))
        : [],
  };
}
