import { I18n } from 'aws-amplify';
import '../../_invoices.scss';
import {
  Button,
  Divider,
  Tooltip,
  Typography,
} from '@alegradev/smile-ui-react';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useForm, useFormState } from 'react-final-form';
import { get } from 'lodash';
import {
  calculateSingleItemValues,
  calculateSingleItemValuesWithDecimals,
  validateSelectTaxes,
} from '../../../../utils';
import {
  renderField,
  renderSelect,
} from '../../../../components/forms/fields/V0/Fields';
import { filter } from '../../../../database/itemsDB';
import useDecimalsVersionsGroup from '../../../../hooks/useDecimalsVersionsGroup/hook';
import { useFormat } from '../../../../hooks/useFormat';
import { useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../../selectors/auth';
import {
  country as countrySelector,
  multitax,
} from '../../../../selectors/company';
import { activeTaxes } from '../../../../selectors/taxes';
import { getTaxesLabel } from '../../../../components/forms/itemSimplified/utils';
import { IconPlus, IconTrash } from '@tabler/icons-react';

export const InvoiceEditTable = ({ items, updateItemPrice }) => {
  const { values } = useFormState();
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const { decimal, fmt } = useFormat();
  const form = useForm();
  const can = useSelector(hasPermissionTo);
  const isMultitax = useSelector(multitax);
  const taxes = useSelector(activeTaxes);
  const country = useSelector(countrySelector);
  return (
    <>
      <div className='invoice-edit-table-container'>
        <table className='invoice-edit-table'>
          <thead>
            <tr>
              <th className='product'>
                <div className='d-flex h-100 align-items-center'>
                  <Typography
                    text={I18n.get('product', 'Producto')}
                    type='label-3'
                  />
                </div>
              </th>
              <th className='right quantity'>
                <Typography
                  text={I18n.get('quantity', 'Cantidad')}
                  type='label-3'
                />
              </th>
              <th className='right discount'>
                <Typography
                  text={I18n.get('discount', 'Descuento')}
                  type='label-3'
                />
              </th>
              <th className='right tax'>
                <Typography text={I18n.get('tax', 'Impuesto')} type='label-3' />
              </th>
              <th className='right total'>
                <Typography text={I18n.get('total', 'Total')} type='label-3' />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <FieldArray name='items'>
              {({ fields }) =>
                fields.map((name, index) => {
                  const item = get(values, `items.${index}`);
                  const itemValues = isDecimalActive
                    ? calculateSingleItemValuesWithDecimals(item, decimal)
                    : calculateSingleItemValues(item, decimal);

                  return (
                    <tr key={index} className='position-relative w-100'>
                      <td className='product'>
                        <Field
                          name={`${name}.item`}
                          component={renderSelect}
                          loadOptions={async (text) =>
                            await filter({ text, categories: [], limit: 10 })
                          }
                          defaultOptions
                          hideDropdownIndicator
                          placeholder={I18n.get(
                            'selectProducts',
                            'selecciona los productos'
                          )}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          borderRadius='10px'
                          height='3.2rem'
                          fontSize='14px'
                          borderColor='#94A3B866'
                          onChange={(value) => {
                            if (!!value) {
                              const modifiedItem = updateItemPrice(
                                {
                                  ...value,
                                  priceList: get(value, 'price'),
                                  price: get(value, 'price.0.price'),
                                  originalPrice: get(value, 'price.0.price'),
                                  quantity: !!+get(
                                    values,
                                    `items.${index}.quantity`
                                  )
                                    ? +get(values, `items.${index}.quantity`)
                                    : 1,
                                },
                                values
                              );
                              form.change(`items.${index}`, {
                                ...modifiedItem,
                              });
                              return modifiedItem;
                            }
                            return value;
                          }}
                        />
                        <div className='d-flex gap-2 mt-2'>
                          <Tooltip
                            visible={!can('edit-items-prices', 'invoices')}
                            overlay={I18n.get(
                              'userNotAllowed.invoices.edit-items-prices-new',
                              'Necesitas permiso del administrador para editar el precio.'
                            )}
                            placement='bottomRight'
                          >
                            <Field
                              name={`${name}.price`}
                              className='edit-field'
                              component={renderField}
                              type='number'
                              placeholder={fmt.prefix}
                              min='0'
                              disabled={!can('edit-items-prices', 'invoices')}
                              textAlign='right'
                              onBlur={() => {
                                if (!get(values, `items.${index}.price`))
                                  form.change(`items.${index}.price`, '0');
                                else if (
                                  +get(values, `items.${index}.price`) < 0
                                )
                                  form.change(`items.${index}.price`, '0');
                              }}
                            />
                          </Tooltip>
                          <Field
                            name={`${name}.reference`}
                            className='edit-field'
                            component={renderField}
                            textAlign='right'
                            type='text'
                          />
                        </div>
                      </td>
                      <td className='quantity right'>
                        <Field
                          name={`${name}.quantity`}
                          className='edit-field'
                          component={renderField}
                          textAlign='right'
                          type='number'
                          min='0'
                          onBlur={() => {
                            if (!get(values, `items.${index}.quantity`))
                              form.change(`items.${index}.quantity`, '1');
                            else if (
                              +get(values, `items.${index}.quantity`) <= 0
                            )
                              form.change(`items.${index}.quantity`, '1');
                          }}
                        />
                      </td>
                      <td className='discount right'>
                        <Field
                          name={`${name}.discount`}
                          className='edit-field'
                          component={renderField}
                          type='number'
                          textAlign='right'
                          placeholder='%'
                          min='0'
                          onBlur={() => {
                            if (!get(values, `items.${index}.discount`))
                              form.change(`items.${index}.discount`, '0');
                            else if (
                              +get(values, `items.${index}.discount`) < 0
                            )
                              form.change(`items.${index}.discount`, '0');
                          }}
                        />
                      </td>
                      <td className='tax right'>
                        <Field
                          name={`${name}.tax`}
                          className='edit-field'
                          component={renderSelect}
                          options={taxes}
                          getOptionLabel={(option) =>
                            getTaxesLabel(country, option)
                          }
                          getOptionValue={(option) => option.id}
                          isMulti={isMultitax}
                          showFavoriteIcon={false}
                          hideDropdownIndicator
                          placeholder={I18n.get('taxes', 'impuestos')}
                          onChange={(values) =>
                            validateSelectTaxes({ values, country, isMultitax })
                          }
                          borderRadius='10px'
                          height='3.2rem'
                          fontSize='14px'
                          borderColor='#94A3B866'
                        />
                      </td>
                      <td className='right'>
                        <Typography
                          text={itemValues.total.toFormat(decimal, fmt)}
                          type='body-3-regular'
                        />
                      </td>
                      <td>
                        <Button
                          type='button'
                          onlyIcon={IconTrash}
                          emphasis='text'
                          disabled={fields.length <= 1}
                          onClick={() => fields.remove(index)}
                        />
                      </td>
                    </tr>
                  );
                })
              }
            </FieldArray>
          </tbody>
        </table>
      </div>

      <div className='mt-4'>
        <Button
          type='button'
          text={I18n.get('newItemButton', 'Nuevo producto')}
          leftIcon={IconPlus}
          onClick={() =>
            form.mutators.concat('items', [
              {
                quantity: 0,
                tax: [],
                discount: 0,
                price: 0,
              },
            ])
          }
        />
      </div>
    </>
  );
};

export default InvoiceEditTable;
