import { Table } from '@alegradev/smile-ui-react';
import { providerPaymentColumns } from './columns';

function BillDetailBodyProviderPayment({ bill }) {
  return (
    <div style={{ maxWidth: 500 }}>
      <Table
        data={bill?.providerPayment || []}
        simple
        hidePagination
        hideToolbar
        columns={providerPaymentColumns}
      />
    </div>
  );
}

export default BillDetailBodyProviderPayment;
