import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import {
  get,
  set,
  has,
  isNumber,
  isEmpty,
  toNumber,
  isEqual,
  clone,
  add,
} from 'lodash';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone'
import { v4 } from 'uuid';

import * as mutations from '../graphql/mutations';
import {
  country as countrySelector,
  timezone as tzSelector,
  termsAndConditions,
  decimalPrecision,
  address,
  regime as regimeSelector,
  electronicInvoicing as companyElectronicInvoicing,
  idCompanySelector,
  isOnlyInvoicingPlan,
  isActiveNoIvaDay,
  ivaConditionSelector,
  anotation as anotationSelector,
  firstInvoiceCreatedSelector,
  companySelector,
  tipsSelector,
} from '../selectors/company';
import {
  station as stationSelector,
  stationInvoiceNumeration,
  electronicInvoicing,
  APIGraphqlSelector,
  syncingInvoice,
} from '../selectors/app';
import { hasPermissionTo } from '../selectors/auth';
import { getMainCurrency } from '../selectors/currencies';
import {
  total as totalSelector,
  discSubtotal as subtotalSelector,
  subtotal as subtotalToTipSelector,
  economicActivity as economicActivitySelector,
  numeration as numerationSelector,
  tipSelector,
  regimeSelector as invoiceRegimeSelector,
  paymentMethod,
  rawSubtotal,
} from '../selectors/activeInvoice';
import {
  isCashReceiptNumerationActive,
  getNumerationById,
} from '../selectors/numerations';
import { getIVA0Tax } from '../selectors/taxes';
import { closeModal } from '../reducers/modals';
import { sendNewGTMEvent } from '../reducers/company';
import { fetchNumerations } from '../reducers/numerations';
import {
  put,
  getAll,
  update,
  remove,
  updateNumerations,
  getAllInvoicesFe,
  putInvoiceFe,
  getByIdInvoice,
  removeInvoiceFe,
  getByIdInvoiceFe,
  removeAllInvoicesFe,
} from '../database/invoicesDB';
import {
  put as putContact,
  getByOfflineId as getContactByOfflineId,
} from '../database/contactsDB';
import { delay, getInitialState, replaceAndParse, toast } from '../utils';
import { handleError } from '../utils/errors';
import { invoiceTransformer, clientTransformer } from '../utils/transformers';
import { setOfflineStatus, getPOSClient, setSyncingInvoice } from './app';
// import { changeItemInventory, refresh } from './items'
import {
  updateInvoice,
  replaceInvoice,
  updateOfflineInvoices,
} from './invoices';
import { syncOffline as syncClients } from './clients';
import { removeInvoice } from './pendingInvoices';
import { setPrint } from './print';
import { fetchUser, updateCompany } from './auth';
import { sendGTMEvent } from './company';
import { STATUS_IN_PROCESS } from '../utils/invoices';
// import { getItem } from '../database/itemsDB';
import {
  itemExceedsReferenceMax as checkItemExceedsReferenceMax,
  checkNumerationBlock,
} from '../components/home/NewActiveInvoice/utils';
import utc from 'dayjs/plugin/utc';
import { COUNTRIES } from '../utils/enums/countries';
import { allRegimes } from '../components/countriesData/mexico/regimes';
import { syncingClient } from '../selectors/clients';
import { endAction, startAction } from './monitoring';
import { calculateActionTimeSelectorWrapper } from '../selectors/monitoring';
import { stationPriceList } from '../selectors/app';
import { toCamelCase } from '../utils/text';
import { PAYMENT_METHODS } from '../utils/enums/paymentMethods';
import checkIfIsDefaultClient from '../pages/contacts/utils/isDefaultClient';
import { isCountryAvailableToPayInvoiceRefactored } from '../components/modals/invoiceRefactored/utils/invoice';
import { InvoiceCountryStrategyFactory } from '../strategies/Invoice/InvoiceCountryStrategyFactory';
import { toastHandler } from '@alegradev/smile-ui-react';
import { showNewStockFeature } from '../components/home/Items/utils';
import { DOCUMENT_TYPES } from '../utils/enums/documentTypes';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);

const initialState = {
  items: [],
  originalItems: [],
  itemsMap: {},
  client: null,
  priceList: null,
  currency: null,
  numeration: null,
  economicActivity: null,
  operationType: 'STANDARD',
  type: 'NATIONAL',
  paymentTerm: 1,
  deliveryTerm: null,
  tip: {
    firstCheck: false,
    include: false,
    type: 'PERCENTAGE',
    percentage: null,
    value: 0
  },
  itemsOutOfStock: [],
  paymentMethodSelected: null,
  isPaymentMethodSelected: false,
  version: 1
};

const findIndex = (items = [], id) =>
  items.findIndex((item) => get(item, 'id') === id && !get(item, 'modified'));

const updateItemPrice = (item, priceList, currency) => {
  let price = get(item, 'originalPrice', 0);
  if (!get(item, 'priceModified') && !!priceList) {
    const itemPriceList = !!get(item, 'priceLists.length')
      ? get(item, 'priceLists').find(
        (list) => String(get(list, 'idPriceList')) === String(get(priceList, 'id'))
      )
      : null;
    if (!!itemPriceList) price = get(itemPriceList, 'price');
  }
  if (!!currency) {
    const exchangeRate = !!get(currency, 'exchangeRate')
      ? +get(currency, 'exchangeRate')
      : 1;
    price = new BigNumber(price)
      .dividedBy(new BigNumber(exchangeRate))
      .decimalPlaces(4)
      .toNumber();
  }

  return { ...item, price };
};

const changeItemNoIvaField = (item, active, quantity) => {
  return (_disaptch, getState) => {
    const taxIVA0 = getIVA0Tax(getState());

    let hasIVATaxes = false;
    let itemTax = null;
    if (!!get(item, 'tax') && !!get(item, 'tax.length')) {
      hasIVATaxes = !!get(item, 'tax').filter((t) => get(t, 'type') === 'IVA');

      itemTax = get(item, 'tax').map((t) =>
        get(t, 'type') === 'IVA' ? { ...t, noIVA: active } : t
      );

      if (hasIVATaxes && active && !!taxIVA0) {
        itemTax = itemTax.filter((t) => get(t, 'type') !== 'IVA');
        itemTax.push(taxIVA0);
      }
    }

    return {
      ...item,
      quantity,
      tax:
        !!get(item, 'tax') && !!get(item, 'tax.length')
          ? itemTax
          : get(item, 'tax'),
    };
  };
};

const changeItemsNoIVADay = () => {
  return (dispatch, getState) => {
    let items = get(getState(), 'activeInvoice.originalItems');

    let noIVACategories = {};
    let restItems = [];
    let itemsMap = {};

    let finalItems = items.map((item, index) => {
      itemsMap[index] = index;

      if (!!get(item, 'hasNoIvaDays')) {
        if (!!get(item, 'noIvaDaysCategory')) {
          const itemQuantity = +get(item, 'quantity', 0);
          const totalNoIvaQuantity =
            noIVACategories[get(item, 'noIvaDaysCategory')] || 0;

          const noIvaQuantity = Math.min(
            itemQuantity,
            Math.max(3 - totalNoIvaQuantity, 0)
          );
          const ivaQuantity = itemQuantity - noIvaQuantity;

          noIVACategories[get(item, 'noIvaDaysCategory')] =
            totalNoIvaQuantity + itemQuantity;

          if (noIvaQuantity > 0) {
            if (ivaQuantity > 0) {
              itemsMap[items.length + restItems.length] = index;
              restItems.push(
                dispatch(changeItemNoIvaField(item, false, ivaQuantity))
              );
            }

            return dispatch(changeItemNoIvaField(item, true, noIvaQuantity));
          }

          return dispatch(changeItemNoIvaField(item, false, itemQuantity));
        }

        return dispatch(
          changeItemNoIvaField(item, true, get(item, 'quantity'))
        );
      }

      return item;
    });

    finalItems = finalItems.concat(restItems);

    dispatch(setItemsMap(itemsMap));
    dispatch(setItems(finalItems));
  };
};

const showNoIvaDaysInfo = () => {
  toast.warning({
    subtitle: (
      <p className='text-left h3 text-capitalize-first'>
        {I18n.get(
          'noIVADayItemsLimitWarning',
          'Ya este cliente adquirió las 3 unidades de esta categoría que se encuentran exentas y en la próxima se le cobrará el IVA'
        )}
      </p>
    ),
  });
};

const showNoIvaDaysInfoInEdit = () => {
  toast.warning({
    subtitle: (
      <p className='text-left h3 text-capitalize-first'>
        {I18n.get(
          'noIVADayItemsLimitWarningInEdit1',
          'Te recomendamos cobrar este producto con IVA, porque ya seleccionaste las'
        )}{' '}
        <b>
          {I18n.get('noIVADayItemsLimitWarningInEdit2', '3 unidades exentas')}
        </b>{' '}
        {I18n.get(
          'noIVADayItemsLimitWarningInEdit3',
          'que pertenecen a esta categoría'
        )}
      </p>
    ),
  });
};

export const setClient = (client) => {
  return async (dispatch, getState) => {
    const isOldClientIdNIT =
      get(getState(), 'activeInvoice.client.identificationObject.type') ===
      'NIT';
    const isNewClientIdNIT = get(client, 'identificationObject.type') === 'NIT';
    const items = get(getState(), 'activeInvoice.items');
    const originalItems = get(getState(), 'activeInvoice.originalItems');
    await dispatch(_setClient(client));

    const isIVADay = isActiveNoIvaDay(getState());

    if (isIVADay) {
      if (isOldClientIdNIT !== isNewClientIdNIT) {
        if (isOldClientIdNIT) {
          dispatch(changeItemsNoIVADay());

          const newItems = get(getState(), 'activeInvoice.items');
          const newOriginalItems = get(
            getState(),
            'activeInvoice.originalItems'
          );

          if (
            get(originalItems, 'length') === get(newOriginalItems, 'length') &&
            get(items, 'length') !== get(newItems, 'length')
          )
            showNoIvaDaysInfo();
        } else dispatch(setItems(originalItems));
      }
    }
  };
};

export const setNumeration = (numeration) => {
  return async (dispatch, getState) => {
    await dispatch(_setNumeration(numeration));
  };
};

export const singleItemReachedMinQuantityFromValues = (
  item,
  subitemQuantity,
  kitId,
  quantity
) => {
  return async (_, getState) => {
    const warehouseInventory = get(
      get(item, 'inventory.warehouses', []).filter(
        (w) => Number(w.id) === get(getState(), 'app.station.idWarehouse', null)
      ),
      '0',
      {}
    );

    const currentQuantity = get(getState(), 'activeInvoice.items', [])
      .filter((i) => i.id === get(item, 'id', null))
      .reduce((acc, curr) => (acc += Number(get(curr, 'quantity', 0))), 0);

    const currentFormQuantity = !!quantity
      ? quantity
      : get(item, 'quantity', 0);

    const newCurrentQuantity = Number(currentQuantity) - Number(item?.previousQuantity || 0);
    const newCurrentFormQuantity = Number(newCurrentQuantity) + Number(currentFormQuantity);

    const currentQuantityInKits = (fromKit) => {
      const kits = get(getState(), 'activeInvoice.items', []).map((i) => {
        if (
          get(i, 'type') === 'kit' &&
          get(i, 'id') !== (fromKit ? kitId : null)
        ) {
          return {
            quantity: get(i, 'quantity'),
            kitQuantity: get(
              get(i, 'subitems', []).filter(
                (j) => get(j, 'item.id') === get(item, 'id')
              ),
              '0.quantity',
              0
            ),
          };
        }
        return null;
      });
      return kits.reduce(
        (acc, curr) =>
          (acc += get(curr, 'quantity', 0) * get(curr, 'kitQuantity', 0)),
        0
      );
    };

    const availableQuantity = get(
      warehouseInventory,
      'availableQuantity',
      null
    );
    const negativeSale = get(item, 'inventory.negativeSale', true);

    if (subitemQuantity)
      return (
        !negativeSale &&
        availableQuantity !== null &&
        availableQuantity -
        (+currentFormQuantity * +subitemQuantity +
          currentQuantityInKits(true) +
          currentQuantity) <
        0
      );

    return (
      !negativeSale &&
      availableQuantity !== null &&
      availableQuantity - (currentQuantityInKits() + +newCurrentFormQuantity) < 0
    );
  };
};

const singleItemReachedMinQuantity = (item, quantity) => {
  return async (_, getState) => {
    const warehouseInventory = get(
      get(item, 'inventory.warehouses', []).filter(
        (w) => Number(w.id) === get(getState(), 'app.station.idWarehouse', null)
      ),
      '0',
      {}
    );

    const currentQuantity =
      get(getState(), 'activeInvoice.items', [])
        .filter((i) => i.id === get(item, 'id', null))
        .reduce((acc, curr) => (acc += Number(get(curr, 'quantity', 0))), 0);



    const currentQuantityInKits = () => {
      const kits = get(getState(), 'activeInvoice.items', []).map((i) => {
        if (get(i, 'type') === 'kit')
          return {
            quantity: get(i, 'quantity'),
            kitQuantity: get(
              get(i, 'subitems', []).filter(
                (j) => get(j, 'item.id') === get(item, 'id')
              ),
              '0.quantity'
            ),
          };
        return null;
      });
      return kits.reduce(
        (acc, curr) =>
          (acc += get(curr, 'quantity', 0) * get(curr, 'kitQuantity', 0)),
        0
      );
    };

    const availableQuantity = get(
      warehouseInventory,
      'availableQuantity',
      null
    );
    const negativeSale = get(item, 'inventory.negativeSale', true);

    if (quantity)
      return (
        !negativeSale &&
        availableQuantity !== null &&
        availableQuantity -
        (+currentQuantity + currentQuantityInKits() + +quantity) <
        0
      );

    return (
      !negativeSale &&
      availableQuantity !== null &&
      availableQuantity - (+currentQuantity + 1 + currentQuantityInKits()) < 0
    );
  };
};

const currentQuantityInKits = (item, kitId) => {
  return async (_, getState) => {
    const currentNoKitQuantity = get(
      get(getState(), 'activeInvoice.items', []).filter(
        (i) => i.id === get(item, 'id', null)
      ),
      '0.quantity',
      0
    );
    const kits = get(getState(), 'activeInvoice.items', []).map((i) => {
      if (get(i, 'type') === 'kit' && get(i, 'id') !== (kitId ? kitId : null))
        return {
          quantity: get(i, 'quantity'),
          kitQuantity: get(
            get(i, 'subitems', []).filter(
              (j) => get(j, 'item.id') === get(item, 'id')
            ),
            '0.quantity'
          ),
        };
      return null;
    });
    return (
      kits.reduce(
        (acc, curr) =>
          (acc += get(curr, 'quantity', 0) * get(curr, 'kitQuantity', 0)),
        0
      ) + +currentNoKitQuantity
    );
  };
};

export const itemReachedMinQuantity = (item, comparator) => {
  return async (dispatch, _) => {
    comparator = comparator || singleItemReachedMinQuantity;
    if (get(item, 'type', 'product') !== 'kit')
      return await dispatch(comparator(item));
    else {
      const subitems = get(item, 'subitems', []);
      const subitemsAvailability = await Promise.all(
        subitems?.map(async (i) => {
          return await dispatch(
            comparator(i?.item, i?.quantity, item?.id, item?.quantity)
          );
        })
      );
      return !isEmpty(subitemsAvailability.filter((i) => i === true));
    }
  };
};

export const addItem = (item) => {
  return async (dispatch, getState) => {
    const state = getState();
    const country = get(state, 'auth.company.applicationVersion');
    const numberTemplate = get(state, 'activeInvoice.numeration')
      ? get(state, 'activeInvoice.numeration')
      : stationInvoiceNumeration(state);
    const isElectronic = electronicInvoicing(numberTemplate)(state);
    const tip = get(state, 'activeInvoice.tip');
    const localSettings = get(state, 'auth.company.localSettings');
    const tipConfig = {
      enabled: get(localSettings, 'tipEnabled', false),
      suggested: get(localSettings, 'tipSuggested', false),
      suggestedValue: get(localSettings, 'tipSuggestedValue', 0),
    }

    const priceListInvoice = get(state, 'activeInvoice.priceList');
    if (priceListInvoice === null) {
      const priceListStation = stationPriceList(state);
      dispatch(setSettings({ priceList: priceListStation }));
    }

    if (
      country === 'costaRica' &&
      isElectronic &&
      checkItemExceedsReferenceMax(item, true)
    )
      return;
    const checkItemStatus = await dispatch(itemReachedMinQuantity(item));
    if (checkItemStatus) {
      switch (get(item, 'type', 'product')) {
        case 'product':
          if(showNewStockFeature({country})) {
            return toastHandler({
              type: 'error',
              title: I18n.get('productIsOutOfStock', 'El producto se agotó'),
              description: I18n.get(
                'productIsOutOfStock.description',
                'Revisa si tienes compras por registrar o edita el producto y actívale la opción de "Venta en negativo"'
              ),
              shadow: true,
              autoClose: 2000,
            });
          }
          return toast.warning({
            title: I18n.get(
              'itemLimitWarningTitle',
              'Ya vendiste todas las unidades. 🏁'
            ),
            subtitle: I18n.get(
              'itemLimitWarningSubtitle',
              'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
            ),
          });
        case 'kit':
          toast.warning({
            title: I18n.get(
              'itemLimitWarningTitle.kit',
              'Revisa los productos del combo 🔍'
            ),
            subtitle: replaceAndParse(
              I18n.get(
                'itemLimitWarningSubtitle.kit',
                'El Combo "{}" no se puede vender porque tiene productos sin unidades disponibles.'
              ),
              [`<span class="font-weight-bold">${get(item, 'name', '')}</span>`]
            ),
          });
          break;
        default:
          toast.warning({
            title: I18n.get(
              'itemLimitWarningTitle',
              'Ya vendiste todas las unidades. 🏁'
            ),
            subtitle: I18n.get(
              'itemLimitWarningSubtitle',
              'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
            ),
          });
          break;
      }
    } else {
      await dispatch(_addItem(item));
      if (!tip) {
        dispatch(setTip({
          firstCheck: false,
          include: false,
          type: 'PERCENTAGE',
          percentage: null,
          value: 0
        }));
      }
      if (!get(tip, 'firstCheck', false) && !!get(tipConfig, "enabled", false)) {
        dispatch(setTip({
          ...tip,
          firstCheck: true,
          type: 'PERCENTAGE',
          include: !!tipConfig.suggested,
          percentage: tipConfig.suggestedValue,
        }));
      }

      const isClientIdNIT =
        get(state, 'activeInvoice.client.identificationObject.type') === 'NIT';
      const isIVADay = isActiveNoIvaDay(state);
      const items = get(state, 'activeInvoice.items');
      const originalItems = get(state, 'activeInvoice.originalItems');

      if (isIVADay && !isClientIdNIT) {
        dispatch(changeItemsNoIVADay());

        const newItems = get(getState(), 'activeInvoice.items');
        const newOriginalItems = get(getState(), 'activeInvoice.originalItems');

        if (
          get(originalItems, 'length') === get(newOriginalItems, 'length') &&
          get(items, 'length') !== get(newItems, 'length')
        )
          showNoIvaDaysInfo();
      }
    }
  };
};

export const getKitMaximumQuantity = (item) => {
  return async (dispatch, _) => {
    const subitems = get(item, 'subitems');
    const noNegativeSaleSubitems = subitems.filter(
      (i) => get(i, 'item.inventory.negativeSale') === false
    );
    const currentQuantity = await Promise.all(
      noNegativeSaleSubitems.map(async (i) => {
        return await dispatch(currentQuantityInKits(i.item, get(item, 'id')));
      })
    );
    const maxQuantity = Math.floor(
      Math.min(
        ...noNegativeSaleSubitems.map((i, index) => {
          return (
            (+get(i, 'item.inventory.availableQuantity') -
              currentQuantity[index]) /
            +get(i, 'quantity', 1)
          );
        })
      )
    );

    return maxQuantity;
  };
};

export const getKitMaximumQuantityByActiveWarehouse = (item, warehouseId) => {
  return async (dispatch, _) => {
    const subitems = get(item, 'subitems', []);
    const noNegativeSaleSubitems = subitems.filter(
      (i) => get(i, 'item.inventory.negativeSale') === false
    );

    const currentQuantity = await Promise.all(
      noNegativeSaleSubitems.map(async (i) => {
        return await dispatch(currentQuantityInKits(i.item, get(item, 'id')));
      })
    );

    let outOfStockCount = 0;

    const maxQuantityAvailable = Math.floor(
      Math.min(
        ...noNegativeSaleSubitems.map((i, index) => {
          const warehouses = get(i, 'item.inventory.warehouses', []);
          const matchingWarehouse = warehouses.find(
            (wh) => get(wh, 'id') == warehouseId
          );

          const availableQuantity = matchingWarehouse
            ? +get(matchingWarehouse, 'availableQuantity', 0)
            : 0;

          if (availableQuantity === 0) {
            outOfStockCount++;
          }

          return (
            (availableQuantity - currentQuantity[index]) /
            +get(i, 'quantity', 1)
          );
        })
      )
    );

    return { maxQuantityAvailable, outOfStockCount };
  };
};

export const increaseItem = (index) => {
  return async (dispatch, getState) => {
    const country = get(getState(), 'auth.company.applicationVersion');
    const item = get(getState(), `activeInvoice.items.${index}`, {});
    if (await dispatch(itemReachedMinQuantity(item))) {
      switch (get(item, 'type', 'product')) {
        case 'product':
          if(showNewStockFeature({country})){
            return toastHandler({
              type: 'error',
              title: I18n.get('productIsOutOfStock', 'El producto se agotó'),
              description: I18n.get(
                'productIsOutOfStock.description',
                'Revisa si tienes compras por registrar o edita el producto y actívale la opción de "Venta en negativo"'
              ),
              shadow: true,
              autoClose: 2000,
            });
          }
          return toast.warning({
            title: I18n.get(
              'itemLimitWarningTitle',
              'Ya vendiste todas las unidades. 🏁'
            ),
            subtitle: I18n.get(
              'itemLimitWarningSubtitle',
              'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
            ),
          });
        case 'kit':
          if(showNewStockFeature({country})){
            return toastHandler({
              type: 'error',
              title: replaceAndParse(
                I18n.get(
                  'itemLimitWarningTitle.kitAvailable',
                  '¡Puedes vender máximo {}! ✋'
                ),
                [
                  `${get(item, 'quantity')} ${
                    get(item, 'quantity') > 1
                      ? I18n.get('kits', 'combosT')
                      : I18n.get('kit', 'combo')
                  }`,
                ]
              ),
              description: replaceAndParse(
                I18n.get(
                  'itemLimitWarningSubtitle.kitAvailable',
                  'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.'
                ),
                [`${get(item, 'name', '')}`]
              ),
              width: '50rem',
              shadow: true,
              autoClose: 2000,
            });
          }

          return toast.warning({
            title: replaceAndParse(
              I18n.get(
                'itemLimitWarningTitle.kitAvailable',
                '¡Puedes vender máximo {}! ✋'
              ),
              [
                `${get(item, 'quantity')} ${
                  get(item, 'quantity') > 1
                    ? I18n.get('kits', 'combosT')
                    : I18n.get('kit', 'combo')
                }`,
              ]
            ),
            subtitle: replaceAndParse(
              I18n.get(
                'itemLimitWarningSubtitle.kitAvailable',
                'Ten en cuenta que tu Combo "{}" contiene productos con ese número de unidades disponibles.'
              ),
              [`<span class="font-weight-bold">${get(item, 'name', '')}</span>`]
            ),
          });
        default:
          toast.warning({
            title: I18n.get(
              'itemLimitWarningTitle',
              'Ya vendiste todas las unidades. 🏁'
            ),
            subtitle: I18n.get(
              'itemLimitWarningSubtitle',
              'Revisa si tienes una compra pendiente por registrar o edita este producto y activa la opción de ventas en negativo'
            ),
          });
          break;
      }
    } else {
      await dispatch(_increaseItem(index));
      const isClientIdNIT =
        get(getState(), 'activeInvoice.client.identificationObject.type') ===
        'NIT';
      const isIVADay = isActiveNoIvaDay(getState());
      const items = get(getState(), 'activeInvoice.items');
      const originalItems = get(getState(), 'activeInvoice.originalItems');

      if (isIVADay && !isClientIdNIT) {
        dispatch(changeItemsNoIVADay());

        const newItems = get(getState(), 'activeInvoice.items');
        const newOriginalItems = get(getState(), 'activeInvoice.originalItems');

        if (
          get(originalItems, 'length') === get(newOriginalItems, 'length') &&
          get(items, 'length') !== get(newItems, 'length')
        )
          showNoIvaDaysInfo();
      }
    }
  };
};

export const decreaseItem = (index) => {
  return async (dispatch, getState) => {
    await dispatch(_decreaseItem(index));
    const isClientIdNIT =
      get(getState(), 'activeInvoice.client.identificationObject.type') ===
      'NIT';
    const isIVADay = isActiveNoIvaDay(getState());

    if (isIVADay && !isClientIdNIT) dispatch(changeItemsNoIVADay());
  };
};

export const setItemQuantity = ({index, newQuantity}) => {
  return async (dispatch, getState) => {
    await dispatch(_setItemQuantity({index, newQuantity}));
    const isClientIdNIT =
      get(getState(), 'activeInvoice.client.identificationObject.type') ===
      'NIT';
    const isIVADay = isActiveNoIvaDay(getState());

    if (isIVADay && !isClientIdNIT) dispatch(changeItemsNoIVADay());
  }
}

export const updateAvailableQuantity = ({ index, availableQuantity, inventory, itemId }) => {
  return async (dispatch, getState) => {
    await dispatch(_updateAvailableQuantity({ index, availableQuantity, inventory, itemId }));
  };
};

export const removeItem = (index) => {
  return async (dispatch, getState) => {
    const state = getState();
    const country = countrySelector(state);
    await dispatch(_removeItem({index, country}));
    const isClientIdNIT =
      get(getState(), 'activeInvoice.client.identificationObject.type') ===
      'NIT';
    const isIVADay = isActiveNoIvaDay(getState());

    if (isIVADay && !isClientIdNIT) dispatch(changeItemsNoIVADay());
  };
};

export const updateItem = (params) => {
  return async (dispatch, getState) => {
    await dispatch(_updateItem(params));
    const isClientIdNIT =
      get(getState(), 'activeInvoice.client.identificationObject.type') ===
      'NIT';
    const isIVADay = isActiveNoIvaDay(getState());
    const items = get(getState(), 'activeInvoice.items');
    const originalItems = get(getState(), 'activeInvoice.originalItems');

    if (isIVADay && !isClientIdNIT) {
      const item = get(getState(), `activeInvoice.items.${params.index}`);

      if (
        !!get(item, 'hasNoIvaDays') &&
        get(getState(), `activeInvoice.itemsMap.${params.index}`) !==
        params.index &&
        (!get(item, 'tax.length') ||
          (get(item, 'tax.length') === 1 &&
            +get(item, 'tax.0.percentage') === 0))
      )
        showNoIvaDaysInfoInEdit();

      dispatch(changeItemsNoIVADay());

      const newItems = get(getState(), 'activeInvoice.items');
      const newOriginalItems = get(getState(), 'activeInvoice.originalItems');

      if (
        get(originalItems, 'length') === get(newOriginalItems, 'length') &&
        get(items, 'length') !== get(newItems, 'length')
      )
        showNoIvaDaysInfo();
    }
  };
};

export const updateItemByEvent = (item) => {
  return (dispatch, getState) => {
    const items = get(getState(), 'activeInvoice.items');
    const priceList = get(getState(), 'activeInvoice.priceList');
    const currency = get(getState(), 'activeInvoice.currency');

    const index = findIndex(items, item.id);

    if (index >= 0)
      dispatch(
        updateItem({
          index,
          values: updateItemPrice(
            {
              ...item,
              originalPrice: get(item, 'price.0.price', 0),
              price: get(item, 'price.0.price', 0),
              priceLists: get(item, 'price'),
            },
            priceList,
            currency
          ),
        })
      );
  };
};

export const removeItemByEvent = (id) => {
  return (dispatch, getState) => {
    const items = get(getState(), 'activeInvoice.items');

    const index = findIndex(items, id);

    if (index >= 0) dispatch(removeItem(index));
  };
};

export const updateClientByEvent = (client) => {
  return (dispatch, getState) => {
    const currentClientId = +get(getState(), 'activeInvoice.client.id');

    if (get(client, 'id') === currentClientId) dispatch(setClient(client));
  };
};

export const removeClientByEvent = (id) => {
  return (dispatch, getState) => {
    const currentClientId = get(getState(), 'activeInvoice.client.id');

    if (id === currentClientId) dispatch(setClient(null));
  };
};

export const updateNumerationByEvent = (numeration) => {
  return (dispatch, getState) => {
    const currentNumerationId = get(getState(), 'activeInvoice.numeration.id');

    if (get(numeration, 'id') === currentNumerationId) {
      dispatch(setNumeration(numeration));
    }
  };
};

export const removeNumerationByEvent = (id) => {
  return (dispatch, getState) => {
    const currentNumerationId = get(getState(), 'activeInvoice.numeration.id');

    if (id === currentNumerationId) dispatch(setNumeration(null));
  };
};

const appSlice = createSlice({
  name: 'activeInvoice',
  initialState: getInitialState('activeInvoice', initialState),
  reducers: {
    setActiveInvoice: (_state, action) => action.payload,
    _setClient: (state, action) => {
      const payloadPriceList = get(action, 'payload.priceList', null);
      if (payloadPriceList && !isEmpty(payloadPriceList)) {
        state.priceList = {
          ...payloadPriceList,
          id: payloadPriceList.idPriceList ?? payloadPriceList.id
        };
      }
      state.client = action.payload;
      state.items = state.items.map(item => updateItemPrice(item, state.priceList, state.currency));
      state.originalItems = state.originalItems.map(item => updateItemPrice(item, state.priceList, state.currency));
    },
    setTip: (state, action) => {
      return {
        ...state,
        tip: {
          ...state.tip,
          ...action.payload,
        },
      };
    },
    setPaymentMethod: (state, action) => {
      return {
        ...state,
        paymentMethodSelected: action.payload
      };
    },
    setIsPaymentMethodSelected: (state, action) => {
      return {
        ...state,
        isPaymentMethodSelected: action.payload
      };
    },
    _setNumeration: (state, action) => {
      return {
        ...state,
        numeration: action.payload,
      };
    },
    setSettings: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };

      return {
        ...newState,
        items: state.items.map((item) =>
          updateItemPrice(item, newState.priceList, newState.currency)
        ),
        originalItems: state.originalItems.map((item) =>
          updateItemPrice(item, newState.priceList, newState.currency)
        ),
      };
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setItemsMap: (state, action) => {
      state.itemsMap = action.payload;
    },
    _addItem: (state, action) => {
      const index = findIndex(state.items, action.payload.id);
      const itemsMap = get(state, 'itemsMap');

      const quantity =
        index >= 0 ? get(state, `items.${index}.quantity`, 0) : 0;
      const originalQuantity =
        index >= 0
          ? get(
              state,
              `originalItems.${get(itemsMap, `${index}`, 0)}.quantity`,
              0
            )
          : 0;

      if (index < 0) {
        state.items.push(
          updateItemPrice(
            {
              ...action.payload,
              originalPrice: get(action, 'payload.price.0.price', 0),
              price: get(action, 'payload.price.0.price', 0),
              priceLists: get(action, 'payload.price'),
              quantity: quantity + 1,
            },
            state.priceList,
            state.currency
          )
        );

        if (state.originalItems)
          state.originalItems.push(
            updateItemPrice(
              {
                ...action.payload,
                originalPrice: get(action, 'payload.price.0.price', 0),
                price: get(action, 'payload.price.0.price', 0),
                priceLists: get(action, 'payload.price'),
                quantity: quantity + 1,
              },
              state.priceList,
              state.currency
            )
          );
        else
          state.originalItems = [
            updateItemPrice(
              {
                ...action.payload,
                originalPrice: get(action, 'payload.price.0.price', 0),
                price: get(action, 'payload.price.0.price', 0),
                priceLists: get(action, 'payload.price'),
                quantity: quantity + 1,
              },
              state.priceList,
              state.currency
            ),
          ];
      } else {
        set(state, `items.${index}.quantity`, quantity + 1);
        set(
          state,
          `originalItems.${get(itemsMap, `${index}`, 0)}.quantity`,
          originalQuantity + 1
        );
      }
    },
    _addItemOutOfStock: (state, action) => {
      const itemsOutOfStock =  get(state, 'itemsOutOfStock', []);

      if (!itemsOutOfStock.find(item => item.id === action.payload.id)){
        state.itemsOutOfStock.push(action.payload);
      }
    },
    _removeItemOutOfStock: (state, action) => {
      const index = state.itemsOutOfStock.findIndex(
        item => item.id === action.payload.id
      );
      if (index !== -1) {
        state.itemsOutOfStock.splice(index, 1);
      }
    },
    _resetItemsOutOfStock: (state) => {
      state.itemsOutOfStock = [];
    },
    _removeItem: (state, action) => {
      const { index, country } = action.payload;
      const deletedItem = state.items.splice(index, 1)[0];
      const deletedQuantity = get(deletedItem, 'quantity');
      const itemsMap = get(state, 'itemsMap');

      const originalItem = get(
        state,
        `originalItems.${get(itemsMap, `${index}`, 0)}`
      );
      const originalQuantity = get(originalItem, 'quantity', 0);

      if(showNewStockFeature({country})){
        const outOfStockIndex = state.itemsOutOfStock.findIndex(
          (item) => item.id === deletedItem.id
        );
        if (outOfStockIndex !== -1) {
          state.itemsOutOfStock.splice(outOfStockIndex, 1);
        }
      }

      if (get(originalItem, 'quantity') === deletedQuantity) {
        state.originalItems.splice(get(itemsMap, `${index}`, 0), 1);
      }else{
        set(
          state,
          `originalItems.${get(itemsMap, `${index}`, 0)}.quantity`,
          originalQuantity - deletedQuantity
        );
      }

      if (get(state, 'items.length') === 0) state.tip = initialState.tip;
    },
    _increaseItem: (state, action) => {
      const itemsMap = get(state, 'itemsMap');

      const quantity = +get(state, `items.${action.payload}.quantity`);
      const originalQuantity = +get(
        state,
        `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`
      );

      set(state, `items.${action.payload}.quantity`, quantity + 1);
      set(
        state,
        `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`,
        originalQuantity + 1
      );
    },
    _decreaseItem: (state, action) => {
      const itemsMap = get(state, 'itemsMap');

      const quantity = +get(state, `items.${action.payload}.quantity`);
      const originalQuantity = +get(
        state,
        `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`
      );

      if (quantity > 1) {
        set(state, `items.${action.payload}.quantity`, quantity - 1);
        set(
          state,
          `originalItems.${get(itemsMap, `${action.payload}`, 0)}.quantity`,
          originalQuantity - 1
        );
      }
    },
    _setItemQuantity: (state, action) => {
      const { index, newQuantity } = action.payload;
      const itemsMap = get(state, 'itemsMap');

      const parsedQuantity = Number(newQuantity);

      if (parsedQuantity >= 0) {
        set(state, `items.${index}.quantity`, parsedQuantity);
        set(
          state,
          `originalItems.${get(itemsMap, `${index}`, 0)}.quantity`,
          parsedQuantity
        );
      }
    },
    _updateAvailableQuantity: (state, action) => {
      const { availableQuantity, inventory, itemId } = action.payload;
      const itemsMap = get(state, 'itemsMap');
      const items = get(state, 'items', []);

      //check if availableQuantity is undefined or null and if there are items in the state
      if (availableQuantity === undefined || availableQuantity === null || items.length === 0) {
        return;
      }

      const parsedQuantity = Number(availableQuantity);

      // Encontrar el índice del ítem a actualizar
      const itemIndex = items.findIndex(item => item?.id === itemId);
      if (itemIndex === -1) {
        return;
      }

      // Actualizar la cantidad disponible y el inventario
      if (parsedQuantity >= 0) {
        set(state, `items.${itemIndex}.availableQuantity`, parsedQuantity);
        set(state, `items.${itemIndex}.inventory`, inventory);

        const originalItemIndex = get(itemsMap, `${itemIndex}`, 0);
        set(state, `originalItems.${originalItemIndex}.availableQuantity`, parsedQuantity);
        set(state, `originalItems.${originalItemIndex}.inventory`, inventory);
      }
    },
    _updateItem: (state, action) => {
      const itemsMap = get(state, 'itemsMap');
      const index = action.payload.index;
      const originalIndex = get(itemsMap, `${index}`, 0);

      if (
        has(action, 'payload.values.quantity') &&
        isNumber(action.payload.values.quantity)
      )
        action.payload.values.quantity =
          action.payload.values.quantity.toFixed(2);

      set(state, `originalItems.${originalIndex}`, {
        ...get(state, `originalItems.${originalIndex}`),
        ...action.payload.values,
        quantity: has(action, 'payload.values.quantity')
          ? +get(state, `originalItems.${originalIndex}.quantity`) -
            +get(state, `items.${index}.quantity`) +
            +get(action, 'payload.values.quantity')
          : +get(state, `originalItems.${originalIndex}.quantity`),
      });

      set(state, `items.${index}`, {
        ...get(state, `items.${index}`),
        ...action.payload.values,
      });
    },
    clear: (state) => {
      state.items = initialState.items;
      state.originalItems = initialState.originalItems;
      state.itemsMap = initialState.itemsMap;
      state.client = initialState.client;
      state.priceList = initialState.priceList;
      state.currency = initialState.currency;
      state.numeration = initialState.numeration;
      state.economicActivity = initialState.economicActivity;
      state.operationType = initialState.operationType;
      state.type = initialState.type;
      state.paymentTerm = initialState.paymentTerm;
      state.deliveryTerm = initialState.deliveryTerm;
      state.tip = initialState.tip;
      state.itemsOutOfStock = initialState.itemsOutOfStock;
    },
  },
});

const { actions, reducer } = appSlice;

export const {
  setActiveInvoice,
  _setClient,
  _setNumeration,
  setTip,
  setPaymentMethod,
  setIsPaymentMethodSelected,
  setSettings,
  setItems,
  setItemsMap,
  _addItem,
  _removeItem,
  _increaseItem,
  _decreaseItem,
  _updateItem,
  clear,
  _setItemQuantity,
  _updateAvailableQuantity,
  _addItemOutOfStock,
  _resetItemsOutOfStock,
  _removeItemOutOfStock,
} = actions;

export default reducer;

const trackCreatedInvoice = (invoice, companyId) => {
  window.dataLayer.push({
    event: 'eventActivity',
    eventActivityAction: 'invoice / add / *',
    eventActivityLabel: companyId,
  });

  if (!!get(invoice, 'firstInvoice')) {
    window.dataLayer.push({
      event: 'eventApp',
      eventAppCategory: 'Company',
      eventAppAction: 'First Invoice Created',
      eventAppLabel: companyId,
    });

    window.dataLayer.push({
      event: 'facebookEvent',
      fbEventName: 'FirstInvoicePOS',
      fbEventCustomData: companyId,
    });
  }
};

const getLocalIdInvoiceFe = (invoices, currentInvoice) => {
  const _currentInvoice = clone(currentInvoice);
  delete _currentInvoice.id;
  delete _currentInvoice.timestamp;

  for (let i = 0; i < invoices.length; i++) {
    let invoice = clone(invoices[i]);
    const id = invoice.id;

    delete invoice.id;
    delete invoice.timestamp;
    if (isEqual(_currentInvoice, invoice)) return id;
  }

  return null;
};

const invoicingErrorMaxRetryDictionary = {
  3048: 2,
};

export const oldCreateInvoice = invoice => {
  return async (dispatch, getState) => {
    const state = getState();
    const station = stationSelector(state);
    const defaultAnotation = anotationSelector(state);
    const isElectronic = electronicInvoicing(state);
    const firstInvoiceCreated = firstInvoiceCreatedSelector(state);
    const APIGraphql = APIGraphqlSelector(state);
    const company = companySelector(state);

    if (has(invoice, 'client.id') && isNaN(get(invoice, 'client.id')))
      invoice.client = await getContactByOfflineId(invoice.client.id);

    if (isEmpty(invoice.client))
      throw new Error(I18n.get('clientSyncErrorMessage', 'Error en la sincronización del cliente, por favor edite el cliente de la factura antes de sincronizarla nuevamente.'));

    let economicActivity = invoice.economicActivity ? (invoice.economicActivity) : null;
    if (economicActivity && typeof economicActivity === 'object') {
      economicActivity = toNumber(economicActivity.code) || null;
    }

    const showNewSolution = false;
    let response;
    let currentInvoice;
    let nameMutation = '';
    let idPreInvoice = '';
    let invoiceRequest = {
      ...invoiceTransformer({
        idStation: station.id,
        ...invoice,
      }, company),
      economicActivity,
      id: invoice.id
    }

    // GUARDAR Y RECUPERA EN LA DB LOCAL PARA REINTENTAR CON LOS MISMOS DATOS
    const invoicesFe = await getAllInvoicesFe();
    if (!!get(invoice, 'stamp.generateStamp')) {
      if (invoicesFe.length === 0) {
        await putInvoiceFe(invoiceRequest);
      } else {
        invoiceRequest = invoicesFe[0];
      }
    }

    try {
      // guardar la factura en la base de datos POS
      if (invoice.id && !get(invoice, 'stamp.generateStamp') && get(invoice, 'statusInProcess') === STATUS_IN_PROCESS.inFrontend && showNewSolution) {
        const responsePreInvoice = await APIGraphql(graphqlOperation(mutations.createPreInvoice, {
          invoice: invoiceRequest
        }))

        idPreInvoice = get(responsePreInvoice, 'data.createPreInvoice.uuidInvoice', null);

        // actualizar estado de indexDB
        if (idPreInvoice) {
          update(idPreInvoice, { statusInProcess: STATUS_IN_PROCESS.inBackend })
        }
      }

      if (!get(invoice, 'stamp.generateStamp') && invoice.id && showNewSolution) {
        currentInvoice = await getByIdInvoice(invoice.id);
      }

      // sincronizar la factura con AC
      if (!get(invoice, 'stamp.generateStamp') && get(currentInvoice, 'statusInProcess') === STATUS_IN_PROCESS.inBackend && showNewSolution) {
        response = await APIGraphql(graphqlOperation(mutations.createOffline, {
          uuidInvoice: invoice.id
        }))
        nameMutation = 'createOffline';
      } else {
        response = await APIGraphql(graphqlOperation(mutations.createInvoice, {
          invoice: invoiceRequest
        }))
        nameMutation = 'createInvoice';
      }
    } catch (error) {
      dispatch(sendGTMEvent("invoice-created", {
        status: "INVOICING_ERROR",
        errorCode: get(error, "errors.0.errorInfo.code")
      }))

      // detectar timeout (duplicidad de factura FE)
      throw error;
    }

    dispatch(sendGTMEvent(firstInvoiceCreated ? "invoice-created" : "create-first-invoice-pos", {
      status: "INVOICING_SUCCESSFUL",
      isElectronicInvoicer: isElectronic,
      documentType: get(invoice, "numberTemplate.documentType"),
      emissionStatus: get(invoice, "stamp.legalStatus", null),
      isElectronicInvoice: get(invoice, "numberTemplate.isElectronic"),
      paymentTerms: get(invoice, 'paymentForm'),
      paymentMethod: get(invoice, "paymentMethod"),
      currency: get(invoice, "currency.code") || get(invoice, "currency"),
      invoiceTotal: get(invoice, "total"),
      totalItems: get(invoice, "items").reduce((acc, item) => acc + item.quantity, 0),
      notesEdited: get(invoice, "anotation") !== defaultAnotation,
      isFirst: !!get(invoice, 'firstInvoice'),
      offlineInvoicing: !!get(invoice, 'offlineInvoicing', false)
    }))

    trackCreatedInvoice(get(response, `data.${nameMutation}`), idCompanySelector(getState()));

    await dispatch(fetchNumerations({ preloaded: null }))
    await updateNumerations()
    dispatch(updateOfflineInvoices())

    if (has(response, `data.${nameMutation}.monthIncome`))
      dispatch(updateCompany({ monthIncome: get(response, `data.${nameMutation}.monthIncome`) }));

    // try {
    //   const itemsToUpdate = new Map();
    //   const items = get(response, `data.${nameMutation}.items`)

    //   const fullItems = await Promise.all(items.map(({ id }) => {
    //     return getItem(+id)
    //   }))

    //   items.forEach(({ id, quantity }) => {
    //     const fullItem = fullItems.find((fi) => get(fi, 'id') === +id)
    //     if (get(fullItem, 'type') !== "kit") {
    //       if (!itemsToUpdate.has(+id)) {
    //         itemsToUpdate.set(+id, quantity)
    //       } else {
    //         itemsToUpdate.set(+id, itemsToUpdate.get(+id) + +quantity)
    //       }
    //     } else {
    //       const subitems = get(fullItem, 'subitems');
    //       subitems.forEach(({ item: subitem, quantity: subitemQuantity }) => {
    //         if (!itemsToUpdate.has(+get(subitem, 'id'))) {
    //           itemsToUpdate.set(+get(subitem, 'id'), (+quantity * +subitemQuantity))
    //         } else {
    //           itemsToUpdate.set(+get(subitem, 'id'), itemsToUpdate.get(+get(subitem, 'id')) + (+quantity * +subitemQuantity))
    //         }
    //       })
    //     }
    //   });

    //   await Promise.all([...itemsToUpdate.entries()].map(async (item) => {
    //     return (
    //       await dispatch(changeItemInventory({ id: item[0], quantity: -item[1] })),
    //       dispatch(refresh())
    //     )
    //   }))

    // } catch {
    // }
    await removeAllInvoicesFe();

    if (!firstInvoiceCreated) {
      await dispatch(fetchUser())
    }

    return get(response, `data.${nameMutation}`)
  }
}

const parsedPaymentMethod = (invoice) => {
  const totalRevieved = get(invoice, 'totalReceived', 0);
  const externalPayment = get(invoice, 'externalPayment', false);

  if (totalRevieved === 0) return 'credit';

  if (externalPayment?.provider === 'combined') return 'mixed';

  const originalPaymentMethod = get(invoice, 'originalPaymentMethod', 'cash');
  return toCamelCase(originalPaymentMethod);
}

/**
 * Determines the event invoice type based on the invoice, country, and company information.
 *
 * @param {Object} invoice - The invoice object.
 * @param {string} country - The country code.
 * @param {Object} company - The company object.
 * @returns {string} - The event invoice type.
 */
const getEventInvoiceType = (invoice, country, company) => {
  const defaultInvoiceType = invoice?.numberTemplate?.documentType;
  const isFromBizkaia = company?.address?.province === 'Bizkaia';
  const isSaleTicket = defaultInvoiceType === DOCUMENT_TYPES.SALE_TICKET;

  const invoiceTypeStrategies = {
    [COUNTRIES.COLOMBIA]: () => (isSaleTicket ? "docPOS" : defaultInvoiceType),
    [COUNTRIES.SPAIN]: () => (isFromBizkaia ? (isSaleTicket ? "ticketTicketbai" : "invoiceTicketbai") : defaultInvoiceType),
  };

  return invoiceTypeStrategies[country]?.() || defaultInvoiceType;
};

export const createInvoice = (invoice) => {
  return async (dispatch, getState) => {
    dispatch(startAction({ action: 'invoice' }));
    const state = getState();
    const station = stationSelector(state);
    const defaultAnotation = anotationSelector(state);
    const numberTemplate = !!get(state, 'activeInvoice.numeration')
      ? get(state, 'activeInvoice.numeration')
      : stationInvoiceNumeration(state);
    const isElectronic = electronicInvoicing(numberTemplate)(state);
    const firstInvoiceCreated = firstInvoiceCreatedSelector(state);
    const country = countrySelector(state);
    const calculateActionTime = calculateActionTimeSelectorWrapper(getState);
    const APIGraphql = APIGraphqlSelector(state);
    const company = companySelector(state);

    if (has(invoice, 'client.id') && isNaN(get(invoice, 'client.id')))
      invoice.client = await getContactByOfflineId(invoice.client.id);

    if (isEmpty(invoice.client))
      throw new Error(
        I18n.get(
          'clientSyncErrorMessage',
          'Error en la sincronización del cliente, por favor edite el cliente de la factura antes de sincronizarla nuevamente.'
        )
      );

    let economicActivity = invoice.economicActivity
      ? invoice.economicActivity
      : null;
    if (economicActivity && typeof economicActivity === 'object') {
      economicActivity = toNumber(economicActivity.code) || null;
    }

    const invoiceToTransform = {
      idStation: station.id,
      country,
      ...invoice,
    };

    const invoiceStrategy =
      InvoiceCountryStrategyFactory.createStrategy(country);

    const showNewSolution = false;
    let response;
    let currentInvoice;
    let nameMutation = '';
    let idPreInvoice = '';
    const transformInvoice = isCountryAvailableToPayInvoiceRefactored({ country }) ? invoiceStrategy.transform(invoiceToTransform) : invoiceTransformer(invoiceToTransform);
    let invoiceRequest = {
      ...transformInvoice, 
      economicActivity, 
      id: invoice.id
    };

    // GUARDAR Y RECUPERA EN LA DB LOCAL PARA REINTENTAR CON LOS MISMOS DATOS
    const invoicesFe = await getAllInvoicesFe();
    if (!!get(invoice, 'stamp.generateStamp')) {
      if (invoicesFe.length === 0) {
        await putInvoiceFe(invoiceRequest);
      } else {
        const localId = getLocalIdInvoiceFe(invoicesFe, invoiceRequest);
        if (!!localId) invoiceRequest = await getByIdInvoiceFe(localId);
        else await putInvoiceFe(invoiceRequest);
      }
    }

    let tryToInvoice = true;
    let tryNumber = 0;
    while (tryToInvoice) {
      try {
        dispatch(startAction({ action: 'tryToInvoice' }));
        tryNumber++;
        // guardar la factura en la base de datos POS
        if (
          invoice.id &&
          !get(invoice, 'stamp.generateStamp') &&
          get(invoice, 'statusInProcess') === STATUS_IN_PROCESS.inFrontend &&
          showNewSolution
        ) {
          const responsePreInvoice = await APIGraphql(
            graphqlOperation(mutations.createPreInvoice, {
              invoice: invoiceRequest,
            })
          );

          idPreInvoice = get(
            responsePreInvoice,
            'data.createPreInvoice.uuidInvoice',
            null
          );

          // actualizar estado de indexDB
          if (idPreInvoice) {
            await update(idPreInvoice, {
              statusInProcess: STATUS_IN_PROCESS.inBackend,
            });
          }
        }

        if (
          !get(invoice, 'stamp.generateStamp') &&
          invoice.id &&
          showNewSolution
        ) {
          currentInvoice = await getByIdInvoice(invoice.id);
        }

        // sincronizar la factura con AC
        if (
          !get(invoice, 'stamp.generateStamp') &&
          get(currentInvoice, 'statusInProcess') ===
          STATUS_IN_PROCESS.inBackend &&
          showNewSolution
        ) {
          response = await APIGraphql(
            graphqlOperation(mutations.createOffline, {
              uuidInvoice: invoice.id,
            })
          );
          nameMutation = 'createOffline';
        } else {
                    console.log("create invoice")
          response = await APIGraphql(
            graphqlOperation(mutations.createInvoice, {
              invoice: invoiceRequest,
            })
          );
          nameMutation = 'createInvoice';
          await removeInvoiceFe(invoiceRequest.id);
          console.log("new invoice")
          console.log(response)
        }

        tryToInvoice = false;
      } catch (error) {
        console.log("error")
        console.log(error)
        const errorCode = get(error, 'errors.0.errorInfo.code', 'unknow');
        var invoicingErrorMaxRetry = null;

        if (typeof errorCode === 'string' || errorCode instanceof String)
          invoicingErrorMaxRetry = invoicingErrorMaxRetryDictionary[errorCode];

        if (
          !!invoicingErrorMaxRetry &&
          tryNumber < invoicingErrorMaxRetryDictionary[errorCode]
        ) {
          tryToInvoice = true;
          await delay(1000);
        } else {
          tryToInvoice = false;

          dispatch(
            sendGTMEvent('invoice-created', {
              status: 'INVOICING_ERROR',
              errorCode: errorCode,
              errorType: get(error, 'errors.0.errorType', 'unknow'),
              errorMessage: get(error, 'errors.0.message', 'unknow'),
            })
          );

          const parseError = handleError(error, {
            country,
            extraInfo: {
              from: 'createInvoice',
              invoice: invoiceRequest,
            },
          });

          dispatch(endAction({ action: 'invoice' }));
          dispatch(
            sendNewGTMEvent('pos-invoice-created', {
              error: isElectronic ? '' : parseError,
              emissionError: isElectronic ? parseError : ''
            })
          );
          dispatch(
            sendNewGTMEvent('pos-sale-sale-paid', {
              error: isElectronic ? '' : parseError,
              emissionError: isElectronic ? parseError : '',
              amount: get(invoice, 'total', 0),
              methodType: parsedPaymentMethod(invoice),
            })
          );

          // detectar timeout (duplicidad de factura FE)
          throw error;
        }
      }
      finally {
        tryToInvoice = false;
      }
    }

      const additionalCharge = get(invoice, 'aditionalCharge', [])[0];
      const tip = get(additionalCharge, 'amount', null);
      dispatch(
        sendGTMEvent(
          firstInvoiceCreated ? 'invoice-created' : 'create-first-invoice-pos',
          {
            status: 'INVOICING_SUCCESSFUL',
            isElectronicInvoicer: isElectronic,
            documentType: get(invoice, 'numberTemplate.documentType'),
            emissionStatus: get(invoice, 'stamp.legalStatus', null),
            isElectronicInvoice: get(invoice, 'numberTemplate.isElectronic'),
            paymentTerms: get(invoice, 'paymentForm'),
            paymentMethod: get(invoice, 'paymentMethod'),
            currency: get(invoice, 'currency.code') || get(invoice, 'currency'),
            invoiceTotal: get(invoice, 'total'),
            totalItems: get(invoice, 'items').reduce(
              (acc, item) => acc + item.quantity,
              0
            ),
            notesEdited: get(invoice, 'anotation') !== defaultAnotation,
            isFirst: !!get(invoice, 'firstInvoice'),
            offlineInvoicing: !!get(invoice, 'offlineInvoicing', false),
            tip,
          }
        )
      );

      const genericContact = checkIfIsDefaultClient(invoice?.client, country);

      const posInvoiceCreatedDefaultParams = {
        error: 'no error',
        id: get(response, 'data.createInvoice.id', ''),
        paymentMethod: parsedPaymentMethod(invoice),
        currency: get(invoice, 'currency.code') || get(invoice, 'currency'),
        subtotal: get(invoice, 'subtotal', ''),
        total: get(invoice, 'total', ''),
        tip: tip || 'no-tip',
        genericContact,
        mainSale: get(invoice, 'mainSale', true),
        type: getEventInvoiceType(invoice, country, company),
        first: !firstInvoiceCreated,
      }


      if (country === COUNTRIES.MEXICO) {
        // In mex we don't have electronic numerations, so we need to check the documentType to know if it's an electronic invoice
        const isElectronicInvoice = get(invoice, 'numberTemplate.documentType') === 'invoice';
        if (isElectronicInvoice) {
          posInvoiceCreatedDefaultParams['emissionStatus'] = get(response, 'data.createInvoice.status', '');
        } else {
          posInvoiceCreatedDefaultParams['status'] = get(response, 'data.createInvoice.status', '');
        }
        dispatch(endAction({ action: 'invoice' }));
        dispatch(endAction({ action: 'tryToInvoice' }));
        dispatch(
          sendNewGTMEvent('pos-invoice-created', {
            ...posInvoiceCreatedDefaultParams,
            isElectronicInvoice,
            responseTime: calculateActionTime("invoice"),
            attemptResponseTime: calculateActionTime("tryToInvoice")
          })
        );
        dispatch(
          sendNewGTMEvent('pos-sale-sale-paid', {
            amount: posInvoiceCreatedDefaultParams['total'],
            methodType: posInvoiceCreatedDefaultParams['paymentMethod'],
          })
        );
      } else {
        const isElectronicInvoice = get(invoice, 'numberTemplate.isElectronic', false);
        if (isElectronicInvoice) {
          posInvoiceCreatedDefaultParams['emissionStatus'] = get(response, 'data.createInvoice.status', '');
        } else {
          posInvoiceCreatedDefaultParams['status'] = get(response, 'data.createInvoice.status', '');
        }
        dispatch(endAction({ action: 'invoice' }));
        dispatch(endAction({ action: 'tryToInvoice' }));
        dispatch(
          sendNewGTMEvent('pos-invoice-created', {
            ...posInvoiceCreatedDefaultParams,
            isElectronicInvoice,
            responseTime: calculateActionTime("invoice"),
            attemptResponseTime: calculateActionTime("tryToInvoice"),
            paymentMethod: get(invoice, 'paymentMethod', null)
          })
        );
        dispatch(
          sendNewGTMEvent('pos-sale-sale-paid', {
            amount: posInvoiceCreatedDefaultParams['total'],
            methodType: posInvoiceCreatedDefaultParams['paymentMethod'],
          })
        );
      }

      trackCreatedInvoice(
        get(response, `data.${nameMutation}`),
        idCompanySelector(getState())
      );
    

    await dispatch(fetchNumerations({ preloaded: null }));
    await updateNumerations();
    dispatch(updateOfflineInvoices());

    if (has(response, `data.${nameMutation}.monthIncome`))
      dispatch(
        updateCompany({
          monthIncome: get(response, `data.${nameMutation}.monthIncome`),
        })
      );

    if (!firstInvoiceCreated) {
      await dispatch(fetchUser());
    }

    console.log("invoice created")

    return get(response, `data.${nameMutation}`);
  };
};

export const syncOffline = (throwError = false, force = false) => {
  return async (dispatch, getState) => {
    const state = getState();
    const country = countrySelector(state);
    const numeration = numerationSelector(state);
    const isOnline = navigator.onLine;
    const syncInvoice = syncingInvoice(state);
    const synchronizingClient = syncingClient(state);
    const companyId = idCompanySelector(state);

    let current = null;

    console.log("sync invoice:", syncInvoice)
    if (syncInvoice && synchronizingClient && !force) return;

    try {
      await dispatch(setSyncingInvoice(true));
      await dispatch(syncClients(throwError, 'sideModal', false, true));

      const offlineInvoices = await getAll();

      for (let index = 0; index < offlineInvoices.length; index++) {
        const invoice = offlineInvoices[index];
        current = invoice.id;

        //cambio para que no se creen facturas con items vacios
        const items = get(invoice, 'items', []);
        if (items.length > 0) {
          invoice.items = invoice.items.filter((item) => item && item.id);
        }

        //cambio para añadir método de pago a la factura en México
        if (
          !get(invoice, 'paymentMethod', null) &&
          country === COUNTRIES.MEXICO &&
          companyId === '850321'
        ) {
          invoice.paymentMethod = get(invoice, 'payments', [])[0].paymentMethod;
        }

        //const tryInvoicingWhileOffline = invoice.error === 'Revisa tu conexión a internet e intenta emitir de nuevo tu documento'
        // delete invoice.id;
        delete invoice.offlineStatus;
        delete invoice.error;

        dispatch(
          updateInvoice({
            id: current,
            changes: { offlineStatus: 'syncing' },
          })
        );

        const newInvoice = ['515516', '385793'].includes(companyId)
          ? await dispatch(oldCreateInvoice(invoice))
          : await dispatch(createInvoice(invoice));

        console.log("set print")
        dispatch(setPrint({ type: 'invoice', value: newInvoice }));

        console.log("replace invoice")
        dispatch(
          replaceInvoice({
            id: current,
            new: newInvoice,
          })
        );
        console.log("remove current")
        await remove(current);
      }

      console.log("invoice sync finished")

      dispatch(setOfflineStatus({ invoices: false }));
      dispatch(
        sendGTMEvent('sync-attempted', {
          autoSyncStatus: 'success',
        })
      );

      console.log("notification send")

      // await dispatch(setSyncingInvoice(false));
    } catch (error) {
      console.log("error")
      console.log(error)
      const documentType = numeration?.documentType;

      dispatch(setOfflineStatus({ invoices: true }));

      dispatch(
        updateInvoice({
          id: current,
          changes: {
            offlineStatus: 'error',
            error: handleError(error),
          },
        })
      );
      await update(current, {
        offlineStatus: 'error',
        error: handleError(error),
        offlineInvoicing: get(error, 'errors.0.message') === 'Network Error',
      });
      // if (window.location.pathname !== '/invoices' && isOnline) {
      //   toast.error({
      //     title: I18n.get('invoiceSyncError', 'error al sincronizar factura'),
      //     subtitle: (
      //       <p className='text-left h4 text-capitalize-first'>
      //         {I18n.get(
      //           'invoiceSyncErrorSubtitle',
      //           'una de las facturas de venta no pudo sincronizarse. Más detalles'
      //         )}{' '}
      //         <NavLink
      //           to='/invoices'
      //           onClick={() => dispatch(closeModal({ modal: 'invoiceSaved' }))}
      //         >
      //           {I18n.get('here', 'aquí')}
      //         </NavLink>
      //       </p>
      //     ),
      //   });
      // }
      // if (window.location.pathname !== '/invoices' && !isOnline) {
      //   toast.warning({
      //     title: I18n.get(
      //       'invoiceSyncErrorOffline',
      //       'Se guardará cuando tengas internet ☝️'
      //     ),
      //     subtitle: (
      //       <p className='text-left h4 text-capitalize-first'>
      //         {['costaRica', 'peru', 'colombia'].includes(country) &&
      //           documentType === 'saleTicket'
      //           ? I18n.get(
      //             'ticketSyncErrorOfflineSubtitle',
      //             'Podrás consultar el ticket en el historial de ventas en cuanto recuperes tu conexión.'
      //           )
      //           : I18n.get(
      //             'invoiceSyncErrorOfflineSubtitle',
      //             'Podrás consultar la factura en el historial de ventas en cuanto recuperes tu conexión.'
      //           )}
      //       </p>
      //     ),
      //   });
      // }
      dispatch(
        sendGTMEvent('sync-attempted', {
          autoSyncStatus: 'failure',
        })
      );
      if (throwError) throw handleError(error);
    } finally {
      await dispatch(setSyncingInvoice(false));
    }
  };
};

const calculatePaymentMethod = ({
  greaterPaymentMethod,
  country,
  totalReceived,
  isElectronicPOSDocument,
}) => {
  // Handle the case where no payment method is specified
  if (!greaterPaymentMethod) return 'cash';

  // Special rules for Colombia with no total received
  if (country === 'colombia' && totalReceived <= 0) {
    return isElectronicPOSDocument ? 'INSTRUMENT_NOT_DEFINED' : null;
  }

  // Define country-specific payment method transformations
  const paymentMethods = {
    'colombia': {
      'cash': 'CASH',
      'debit-card': 'DEBIT_CARD',
      'credit-card': 'CREDIT_CARD',
      'transfer': 'DEBIT_TRANSFER',
      'instrument-not-defined': 'INSTRUMENT_NOT_DEFINED',
    },
    'costaRica': {
      'debit-card': 'card',
      'credit-card': 'card',
      'transfer': 'TRANSFER',
    },
    'mexico': {
      'transfer': 'transfer',
    },
    'argentina': {
      'debit-card': 'debit-card',
      'credit-card': 'credit-card',
      'transfer': 'transfer',
    }
  };

  // Get country-specific payment methods or default to generic ones
  const methodsForCountry = paymentMethods[country] || {};
  const defaultMethods = {
    'debit-card': 'debit-card',
    'credit-card': 'credit-card',
    'transfer': 'cash',
  };

  // Return the country-specific payment method or the default one
  return methodsForCountry[greaterPaymentMethod] || defaultMethods[greaterPaymentMethod] || 'cash';
};


export const calcultateSaleConcept = ({ items }) => {
  if (!items || !get(items, 'length')) return null;

  const services = items.find(
    (item) => get(item, 'inventory.unit') === 'service'
  );
  const products = items.find(
    (item) => get(item, 'inventory.unit') !== 'service'
  );

  if (!!services && !!products) return 'PRODUCTS_SERVICES';
  if (!!services) return 'SERVICES';
  return 'PRODUCTS';
};

export const calculatePaymentType = ({ totalReceived, total }) => {
  if (totalReceived === 0 && total === 0) return 'FREE';
  if (totalReceived < total) return 'CREDIT';

  return 'CASH';
};

export const calculatePayments = ({
  payments,
  banks,
  today,
  total,
  subtotal,
  country,
  decimal,
  cashReceiptNumberTemplate,
  currency,
  numberTemplate,
}) => {
  const methods = {
    cash: 'cash',
    debit: 'debit-card',
    credit: 'credit-card',
    transfer: 'transfer',
  };

  let greaterPayment = new BigNumber(-1);
  let greaterPaymentMethod = null;

  let totalReceived = new BigNumber(0);
  let totalInNotCash = new BigNumber(0);
  let cashIndex = 0;

  const finalPayments = Object.keys(payments)
    .map((key, index) => {
      const amount = new BigNumber(payments[key]).decimalPlaces(decimal);

      totalReceived = totalReceived.plus(amount);

      if (key !== 'cash') totalInNotCash = totalInNotCash.plus(amount);
      else cashIndex = index;

      if (amount.gt(greaterPayment)) {
        greaterPayment = amount;
        greaterPaymentMethod = methods[key];
      }

      let finalPayment = {
        date: today.format('YYYY-MM-DD'),
        account: key !== 'cash' ? banks[key] : null,
        amount: amount.toNumber(),
        paymentMethod: methods[key],
        numberTemplate: cashReceiptNumberTemplate,
      };

      if (!!get(currency, 'exchangeRate'))
        finalPayment = { ...finalPayment, currency };

      return finalPayment;
    })
    .map((payment, index) =>
      index === cashIndex && totalReceived.gt(total)
        ? {
          ...payment,
          amount: total
            .decimalPlaces(decimal)
            .minus(totalInNotCash)
            .toNumber(),
        }
        : payment
    )
    .filter((payment) => !!payment.amount);

  return {
    total: total.toNumber(),
    subtotal: subtotal.toNumber(),
    totalReceived: totalReceived.toNumber(),
    cashReturned: totalReceived.gt(total)
      ? total.minus(totalReceived).abs().toNumber()
      : 0,
    payments: finalPayments,
    originalPaymentMethod: greaterPaymentMethod,
    paymentMethod: calculatePaymentMethod({
      greaterPaymentMethod,
      country,
      totalReceived: totalReceived.toNumber(),
      isElectronicPOSDocument: country === COUNTRIES.COLOMBIA && get(numberTemplate, 'isElectronic') && get(numberTemplate, 'documentType') === 'saleTicket',
    }),
  };
};

export const prepareNumeration = async (numeration, timestamp) => {
  if (!!numeration) {
    const invoiceNumber = +get(numeration, 'nextInvoiceNumber', 0);
    try {
      const offlineInvoices = await getAll();
      const offlineInvoicesByNum = offlineInvoices.filter(
        (invoice) =>
          get(invoice, 'numberTemplate.id') === get(numeration, 'id') &&
          dayjs(get(invoice, 'timestamp')).isBefore(dayjs(timestamp))
      );
      return {
        ...numeration,
        number: invoiceNumber + get(offlineInvoicesByNum, 'length', 0),
      };
    } catch {
      return {
        ...numeration,
        number: invoiceNumber,
      };
    }
  }
  return numeration;
};

const prepareItemsNoIvaDay = () => {
  return (_dispatch, getState) => {
    const isClientIdNIT =
      get(getState(), 'activeInvoice.client.identificationObject.type') ===
      'NIT';
    const isIVADay = isActiveNoIvaDay(getState());
    const items = get(getState(), 'activeInvoice.items');
    const taxIVA0 = getIVA0Tax(getState());
    const priceList = get(getState(), 'activeInvoice.priceList');
    console.log("prepare items", items)

    if (isIVADay && !isClientIdNIT) {
      if (!taxIVA0) {
        toast.error({
          subtitle: (
            <p className='text-left h4 text-capitalize-first'>
              {I18n.get(
                'noTaxIva0Erro',
                'Asegúrate de tener creado el impuesto IVA del 0% para la venta de productos que están cubiertos en tu día sin IVA.'
              )}{' '}
              <a href='https://bit.ly/3BorNji' target='_blank' rel='noreferrer'>
                {I18n.get('seeMore', 'Ver más')}
              </a>
            </p>
          ),
        });
        // eslint-disable-next-line
        throw '';
      }

      return items.map((item) => {
        let hasIVATaxes = false;
        let itemTax = null;
        if (!!get(item, 'tax') && !!get(item, 'tax.length')) {
          hasIVATaxes = !!get(item, 'tax').filter((t) => !!t.noIVA);
          const hasIVA0 = !!get(item, 'tax').filter(
            (t) => get(t, 'type') === 'IVA' && +get(t, 'percentage') === 0
          );

          itemTax = get(item, 'tax').filter((t) => !t.noIVA);

          if (
            hasIVATaxes &&
            get(itemTax, 'length') !== get(item, 'tax.length') &&
            !hasIVA0
          )
            itemTax.push(taxIVA0);
        }

        return {
          ...item,
          tax:
            !!get(item, 'tax') && !!get(item, 'tax.length')
              ? itemTax
              : get(item, 'tax'),
        };
      });
    }

    return items.map((item) => {
      return {
        ...item,
      };
    });
  };
};

const prepareInvoice = ({
  payments,
  seller,
  anotation,
  banks,
  externalPayment,
  cfdiUse
}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const total = totalSelector(state);
    const subtotal = subtotalSelector(state);
    const subtotalToTip = rawSubtotal(state);
    // Check this selector on prepare invoice refactor
    const subtotalMexico = subtotalToTipSelector(state);
    const station = stationSelector(state);
    const country = countrySelector(state);
    const mainCurrency = getMainCurrency(state);
    const tips = tipSelector(state);
    const tipsSettings = tipsSelector(state)
    const isCompanyElectronic = companyElectronicInvoicing(state);
    const regime = invoiceRegimeSelector(state);
    const today =
      country === 'spain'
        ? dayjs(new Date()).tz(tzSelector(state))
        : dayjs(new Date(), tzSelector(state));;
    const decimal = decimalPrecision(state);
    let currency = get(state, 'activeInvoice.currency');
    const numberTemplate = !!get(state, 'activeInvoice.numeration')
      ? get(state, 'activeInvoice.numeration')
      : stationInvoiceNumeration(state);
    const isElectronic = electronicInvoicing(numberTemplate)(state);
    const activeCashReceipt = isCashReceiptNumerationActive(state);
    const cashReceiptNumberTemplate = !!activeCashReceipt
      ? { id: get(station, 'idCashReceiptNumberTemplate') }
      : null;

    if (get(currency, 'code', '') === get(mainCurrency, 'code', ''))
      currency = null;

    const includeTip = get(tips, 'include', false);
    const additionalChargeId = get(tipsSettings, "additionalChargeId", null);

    const aditionalCharge = includeTip ? [{
      "id": additionalChargeId,
      "percentage": 0,
      "amount": get(tips, "type") === 'PERCENTAGE'
        ? subtotalToTip.multipliedBy(get(tips, "percentage", 0)).dividedBy(100).decimalPlaces(decimal).toNumber() :
        new BigNumber(tips.value).decimalPlaces(decimal).toNumber(),
      "metadata": {
        "items": [],
        "thirdParty": {
          "thirdPartyName": "",
          "identificationNumber": ""
        }
      }
    }] : [];

    const numberTemplatePrepared = await prepareNumeration(numberTemplate, today.format());

    let invoice = {
      id: v4(),
      status: 'open',
      date: today.format('YYYY-MM-DD'),
      dueDate: today.format('YYYY-MM-DD'),
      timestamp: today.format('YYYY-MM-DD HH:mm:ss'),
      termsConditions: termsAndConditions(state),
      numberTemplate: numberTemplatePrepared,
      costCenter: get(station, 'idCostCenter', null),
      warehouse: get(station, 'idWarehouse', null),
      externalPayment,
      anotation,
      seller,
      ...calculatePayments({
        payments,
        banks,
        today,
        total,
        subtotal: country === COUNTRIES.MEXICO ? subtotalMexico : subtotal,
        country,
        decimal,
        cashReceiptNumberTemplate,
        currency,
        numberTemplate: numberTemplatePrepared,
      }),
      ...state.activeInvoice,
      aditionalCharge,
      regime,
      currency,
      originApp: 'POS',
      items: dispatch(prepareItemsNoIvaDay()),
    };

    if (country !== COUNTRIES.MEXICO) {
      delete invoice.regime;
    }

    //filter items undefined or null
    invoice = {
      ...invoice,
      items: get(invoice, 'items', []).filter((item) => item && item.id),
    }

    if (country === COUNTRIES.MEXICO && get(numberTemplate, 'documentType') === 'invoice') {
      const dateTimeInMexico = dayjs().tz('America/Mexico_City');
      invoice = {
        ...invoice,
        cfdiUse,
        regimeClient: get(invoice, 'client.regime', allRegimes.SIMPLIFIED_REGIME.value),
        timestamp: dateTimeInMexico.format('YYYY-MM-DD HH:mm:ss'),
        stamp: {
          generateStamp: numberTemplate?.documentType === 'invoice',
          version: '4.0'
        },
      }
      if (invoice?.paymentMethod === 'CASH') {
        invoice = {
          ...invoice,
          paymentMethod: PAYMENT_METHODS.CASH,
        }
      }
    }

    if (country !== 'colombia') {
      delete invoice.type;
      delete invoice.operationType;
      delete invoice.paymentTerm;
      delete invoice.deliveryTerm;
    }

    if (!get(tips, "include", false) || !get(tipsSettings, "enabled", false)) {
      delete invoice.aditionalCharge
    }

    if (country !== 'peru') {
      if (invoice.totalReceived < invoice.total) {
        invoice = {
          ...invoice,
          dueDate: today
            .add(get(invoice, 'client.term.days', 0), 'day')
            .format('YYYY-MM-DD'),
        };
      }
    }

    if (country === 'costaRica') {
      const economicActivity = economicActivitySelector(state);
      invoice = {
        ...invoice,
        economicActivity: economicActivity
          ? toNumber(economicActivity.code)
          : null,
        saleCondition:
          Object.values(payments).reduce((a, b) => a + b) >= invoice.total
            ? '01'
            : '02',
        type: 'NATIONAL',
        stamp: {
          generateStamp:
            !!isElectronic && !!numberTemplate
              ? !!numberTemplate.isElectronic
              : false,
        },
      };
    } else if (country === 'peru') {
      invoice = {
        ...invoice,
        operationType: 'INTERNAL_SALE',
        paymentMethod: 'CASH',
        stamp: {
          generateStamp:
            !!isElectronic && !!numberTemplate
              ? !!numberTemplate.isElectronic
              : false,
        },
      };
    } else if (country === 'colombia') {
      if (isElectronic) {
        invoice = {
          ...invoice,
          paymentForm:
            invoice.totalReceived < invoice.total ? 'CREDIT' : 'CASH',
          stamp: {
            generateStamp: !!numberTemplate
              ? !!numberTemplate.isElectronic
              : false,
          },
          dueDate: !!get(invoice, 'paymentTerm.days')
            ? today
              .add(get(invoice, 'client.term.days', 0), 'day')
              .format('YYYY-MM-DD')
            : invoice.dueDate,
        };
      }

      if ((get(numberTemplate, 'documentType') === 'saleTicket'
        || get(numberTemplate, 'documentType') === 'invoice') && !isElectronic) {
        invoice = {
          ...invoice,
          paymentForm: invoice.totalReceived > 0 ? 'CASH' : 'CREDIT',
        };
      }

      if (get(numberTemplate, 'documentType') === 'saleTicket' && isElectronic) {
        invoice = {
          ...invoice,
          paymentForm: 'CASH',
        };
      }

    } else if (country === 'argentina' && isCompanyElectronic) {
      let saleCondition = 'CASH';
      switch (invoice.paymentMethod) {
        case 'debit-card':
          saleCondition = 'DEBIT_CARD';
          break;
        case 'credit-card':
          saleCondition = 'CREDIT_CARD';
          break;
        case 'transfer':
          saleCondition = 'TRANSFER';
          break;
        default:
          break;
      }
      let saleConcept = calcultateSaleConcept(invoice);
      const dateService =
        saleConcept === 'PRODUCTS_SERVICES' || saleConcept === 'SERVICES';
      invoice = {
        ...invoice,
        saleCondition,
        saleConcept,
        startDateService: dateService ? today.format('YYYY-MM-DD') : null,
        endDateService: dateService ? today.format('YYYY-MM-DD') : null,
        stamp: {
          generateStamp: !!numberTemplate
            ? !!numberTemplate.isElectronic
            : false,
        },
      };
    } else if (country === 'panama') {
      if (isElectronic) {
        let saleCondition = invoice.totalReceived > 0 ? 'CASH' : 'CREDIT';
        switch (invoice.paymentMethod) {
          case 'debit-card':
            saleCondition = 'DEBIT_CARD';
            break;
          case 'credit-card':
            saleCondition = 'CREDIT_CARD';
            break;
          default:
            break;
        }

        invoice = {
          ...invoice,
          stamp: {
            generateStamp: true,
          },
          saleCondition,
          type: 'INTERNAL_OPERATION',
          operationType: 'SALE',
          saleType: 'ORDER',
          originApp: 'POS',
        };
      }
    } else if (country === 'republicaDominicana' && isCompanyElectronic) {
      invoice = {
        ...invoice,
        incomeType: '01',
        paymentType: calculatePaymentType(invoice),
        stamp: {
          generateStamp:
            !!isElectronic && !!numberTemplate
              ? !!numberTemplate.isElectronic
              : false,
        },
      };
    } else if (country === 'spain' && isCompanyElectronic) {
      invoice = {
        ...invoice,
        operationType: 'GENERAL_REGIME_AND_OTHERS',
        stamp: {
          generateStamp:
            !!isElectronic && !!numberTemplate
              ? !!numberTemplate.isElectronic
              : false,
        },
      };
    }

    return invoice;
  };
};

export const defaultClient = (country, address, isElectronic) => {
  switch (country) {
    case 'costaRica':
      return {
        name: 'Cliente de contado',
        identificationObject: { type: 'CF', number: '100000000' },
        address,
        type: ['client'],
        email: 'ejemplo@ejemplo.com',
        ignoreRepeated: true,
      };
    case 'peru':
      return {
        name: 'Cliente varios',
        identification: { type: 'DNI', number: '00000000' },
        address: null,
        type: ['client'],
        ignoreRepeated: true,
      };
    case 'colombia':
      return {
        nameObject: { firstName: 'Consumidor', lastName: 'final' },
        identificationObject: { type: 'CC', number: '222222222222' },
        kindOfPerson: 'PERSON_ENTITY',
        regime: 'SIMPLIFIED_REGIME',
        address,
        type: ['client'],
        ignoreRepeated: false,
      };
    case 'mexico':
      return {
        name: 'Público en General',
        identification: 'XAXX010101000',
        type: ['client'],
        ignoreRepeated: true,
        regime: !!isElectronic ? 'SIMPLIFIED_REGIME' : 'NO_REGIME',
        regimeObject: ['SIMPLIFIED_REGIME'],
        thirdType: 'NATIONAL',
        address: {
          country: "MEX",
          zipCode: '12345'
        }
      };
    case 'argentina':
      return {
        name: 'Consumidor final',
        identification: { type: 'DNI', number: '1' },
        ivaCondition: 'FINAL_CONSUMER',
        address,
        type: ['client'],
        ignoreRepeated: true,
      };
    case 'republicaDominicana':
      return {
        name: 'Consumidor final',
        address,
        type: ['client'],
        ignoreRepeated: true,
      };
    case 'panama':
      return {
        name: 'Consumidor final',
        address,
        thirdType: 'FINAL_CONSUMER',
        kindOfPerson: null,
        type: ['client'],
        ignoreRepeated: true,
      };
    case 'spain':
      return {
        name: 'Público en general',
        address: {
          city: 'Madrid',
          province: 'Madrid',
          address: 'Calle A 123',
        },
        type: ['client'],
        ignoreRepeated: true,
        identification: 'X0101010Y',
        identificationObject: {
          number: 'X0101010Y',
          type: 'NIF',
        },
      };
    default:
      return {
        name: 'POS',
        type: ['client'],
        address,
        ignoreRepeated: true,
      };
  }
};

export const checkClient = (invoice) => {
  return async (dispatch, getState) => {
    const state = getState();
    const country = countrySelector(state);
    const companyAddress = address(state);
    const isCompanyElectronic = companyElectronicInvoicing(state);
    const APIGraphql = APIGraphqlSelector(state);
    let client = null;

    if (!!get(invoice, 'client') && invoice?.client?.id) return invoice;

    try {
      client = await dispatch(getPOSClient());

      if (!!client) return { ...invoice, client };

      const postResponse = await APIGraphql(
        graphqlOperation(mutations.createClient, {
          client: clientTransformer(
            defaultClient(country, companyAddress, isCompanyElectronic)
          ),
        })
      );

      if (get(postResponse, 'data.createClient')) {
        putContact(get(postResponse, 'data.createClient'));
        return { ...invoice, client: get(postResponse, 'data.createClient') };
      }
    } catch {
      return invoice;
    }
  };
};

/**
 * Checks if the second string is a substring of the first string in a case-insensitive manner.
 *
 * @param {string} [a=''] - The string to search within.
 * @param {string} [b=''] - The substring to search for.
 * @returns {boolean} - Returns true if `b` is a substring of `a`, false otherwise.
 */
export const clientDataAreEquals = (a = '', b = '') => {
  const normalizedA = a?.toLowerCase();
  const normalizedB = b?.toLowerCase();

  return normalizedA.includes(normalizedB);
};

export const isDefaultClient = (client, country) => {
  const defaultC = defaultClient(country);
  try {
    switch (country) {
      case 'costaRica':
        return (
          clientDataAreEquals(defaultC.name, client.name) &&
          clientDataAreEquals(
            defaultC.identificationObject.type,
            client.identificationObject.type
          ) &&
          clientDataAreEquals(
            defaultC.identificationObject.number,
            client.identificationObject.number
          )
        );
      case 'peru':
        return (
          clientDataAreEquals(defaultC.name, client.name) &&
          clientDataAreEquals(
            defaultC.identification.type,
            client.identification.type
          ) &&
          clientDataAreEquals(
            defaultC.identification.number,
            client.identification.number
          )
        );
      case 'colombia':
        if (!!get(client, 'nameObject'))
          return (
            clientDataAreEquals(
              defaultC.nameObject.firstName,
              client.nameObject.firstName
            ) &&
            clientDataAreEquals(
              defaultC.nameObject.lastName,
              client.nameObject.lastName
            ) &&
            clientDataAreEquals(
              defaultC.identificationObject.type,
              client.identificationObject.type
            ) &&
            clientDataAreEquals(
              defaultC.identificationObject.number,
              client.identificationObject.number
            )
          );
        return (
          clientDataAreEquals(
            defaultC.nameObject.firstName,
            client.name.firstName
          ) &&
          clientDataAreEquals(
            defaultC.nameObject.lastName,
            client.name.lastName
          ) &&
          clientDataAreEquals(
            defaultC.identificationObject.type,
            client.identificationObject.type
          ) &&
          clientDataAreEquals(
            defaultC.identificationObject.number,
            client.identificationObject.number
          )
        );
      case 'mexico':
        return (
          clientDataAreEquals(defaultC.name, client.name) &&
          clientDataAreEquals(defaultC.identification, client.identification)
        );
      case 'argentina':
        return (
          clientDataAreEquals(defaultC.name, client.name) &&
          clientDataAreEquals(
            defaultC.identification.type,
            client.identification.type
          ) &&
          clientDataAreEquals(
            defaultC.identification.number,
            client.identification.number
          )
        );
      case 'republicaDominicana':
        return clientDataAreEquals(defaultC.name, client.name);
      case 'spain':
        return clientDataAreEquals(defaultC.name, client.name);
      default:
        return clientDataAreEquals(defaultC.name, client.name);
    }
  } catch {
    return false;
  }
};

const hasItemsWithIVA = (items) => {
  if (!items) return false;

  return !!items.filter((item) => get(item, 'hasNoIvaDays')).length;
};

export const validateInvoice = (invoice) => {
  return (_disaptch, getState) => {
    const state = getState();
    const can = hasPermissionTo(state);
    const isIVADay = isActiveNoIvaDay(state);
    const country = countrySelector(state);
    const regime = regimeSelector(state);
    const numberTemplate = getNumerationById(
      get(invoice, 'numberTemplate.id', null)
    )(state);
    const isElectronic = electronicInvoicing(numberTemplate)(state);
    const ivaCondition = ivaConditionSelector(state);
    const { registryDate } = companySelector(state);

    if (
      !!get(invoice, 'numberTemplate.number', null) &&
      !!checkNumerationBlock(numberTemplate, country, registryDate)
    )
      if (
        get(invoice, 'numberTemplate.number') >
        parseInt(get(numberTemplate, 'maxInvoiceNumber'))
      )
        throw new Error(
          I18n.get('numerationMaxNumberReached', 'Numeración sin consecutivos')
        );

    if (!can('view', 'contacts'))
      throw new Error(
        I18n.get(
          'userNotAllowed.contacts.view',
          'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.'
        )
      );

    if (!get(invoice, 'client'))
      throw new Error(
        I18n.get(
          'invoiceWithoutClientError',
          'No se pudo asociar el contacto genérico, intenta facturar de nuevo por favor'
        )
      );

    if (
      isIVADay &&
      isDefaultClient(invoice.client, country) &&
      hasItemsWithIVA(invoice.items)
    )
      throw new Error(
        I18n.get(
          'defaultClientIVAError',
          'Recuerda que para emitir facturas electrónicas con productos sin IVA, debes crear tu cliente con su identificación'
        )
      );

    if (country === 'argentina') {
      let showError = false;
      const clientIvaCondition = get(invoice, 'client.ivaCondition');

      if (ivaCondition === 'IVA_RESPONSABLE') {
        if (get(numberTemplate, 'subDocumentType') === 'INVOICE_A') {
          if (
            !(
              clientIvaCondition === 'IVA_RESPONSABLE' ||
              clientIvaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE'
            )
          )
            showError = true;
        } else if (get(numberTemplate, 'subDocumentType') === 'INVOICE_B') {
          if (
            !(
              clientIvaCondition === 'FINAL_CONSUMER' ||
              clientIvaCondition === 'IVA_EXEMPT'
            )
          )
            showError = true;
        }
      }

      if (
        ivaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE' ||
        ivaCondition === 'IVA_EXEMPT'
      ) {
        if (get(numberTemplate, 'subDocumentType') === 'INVOICE_C') {
          if (
            !(
              clientIvaCondition === 'IVA_RESPONSABLE' ||
              clientIvaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE' ||
              clientIvaCondition === 'FINAL_CONSUMER' ||
              clientIvaCondition === 'IVA_EXEMPT'
            )
          )
            showError = true;
        }
      }

      if (showError) {
        toast.error({
          title: I18n.get(
            'fvArgValidateErrorTitle',
            'Tu factura no se creó por el tipo de documento'
          ),
          subtitle: (
            <>
              <p className='text-left h4 text-capitalize-first'>
                {I18n.get(
                  'fvArgValidateError',
                  'Revisá que el tipo de documento que tiene tu numeración corresponda con tu condición frente al IVA y la de tu cliente.'
                )}
              </p>
              <p className='text-left h4 text-capitalize-first'>
                {I18n.get(
                  'fvArgValidateErrorMore',
                  'Más información haciendo clic'
                )}{' '}
                <a
                  href={
                    isElectronic
                      ? 'https://ayuda.alegra.com/es/crea-facturas-electr%C3%B3nicas-desde-el-punto-de-venta-argentina'
                      : 'https://ayuda.alegra.com/es/facturaci%C3%B3n-%C3%A1gil-en-el-punto-de-venta-pos-argentina'
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  {I18n.get('here', 'aquí')}
                </a>
              </p>
            </>
          ),
        });

        throw new Error(
          I18n.get('fvArgError', 'Revisá los datos de tu factura')
        );
      }
    }

    if (isElectronic && !!get(invoice, 'stamp.generateStamp')) {
      if (
        country === 'peru' &&
        get(numberTemplate, 'documentType') === 'invoice'
      ) {
        if (regime === 'Nuevo RUS')
          throw new Error(
            I18n.get(
              'itIsNotPossibleToIssueElectronicSalesInvoicesWithYourCurrentRegime',
              'No es posible emitir facturas electrónicas de venta con tu régimen actual'
            )
          );
        if (get(invoice, 'client.identificationObject.type') !== 'RUC')
          throw new Error(
            I18n.get(
              'toIssueTheSalesInvoiceYourClientMustHaveAValidRUCIdentification',
              'Para emitir la factura de venta tu cliente debe tener una identificación RUC válida'
            )
          );
      } else if (country === 'argentina') {
        let showError = false;
        const clientIvaCondition = get(invoice, 'client.ivaCondition');

        if (ivaCondition === 'IVA_RESPONSABLE') {
          if (get(numberTemplate, 'subDocumentType') === 'INVOICE_A') {
            if (
              !(
                clientIvaCondition === 'IVA_RESPONSABLE' ||
                clientIvaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE'
              )
            )
              showError = true;
          } else if (get(numberTemplate, 'subDocumentType') === 'INVOICE_B') {
            if (
              !(
                clientIvaCondition === 'FINAL_CONSUMER' ||
                clientIvaCondition === 'IVA_EXEMPT'
              )
            )
              showError = true;
          }
        }

        if (
          ivaCondition === 'UNIQUE_TRIBUTE_RESPONSABLE' ||
          ivaCondition === 'IVA_EXEMPT'
        ) {
          if (get(numberTemplate, 'subDocumentType') !== 'INVOICE_C')
            showError = true;
        }

        if (showError) {
          toast.error({
            title: I18n.get(
              'feArgValidateErrorTitle',
              'Tu factura no se emitió por el tipo de documento'
            ),
            subtitle: (
              <>
                <p className='text-left h4 text-capitalize-first'>
                  {I18n.get(
                    'feArgValidateError',
                    'Revisá que el tipo de documento que tiene tu numeración corresponda con tu condición frente al IVA y la de tu cliente.'
                  )}
                </p>
                <p className='text-left h4 text-capitalize-first'>
                  {I18n.get(
                    'feArgValidateErrorMore',
                    'Más información haciendo clic'
                  )}{' '}
                  <a
                    href='https://ayuda.alegra.com/es/crea-facturas-electr%C3%B3nicas-desde-el-punto-de-venta-argentina'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {I18n.get('here', 'aquí')}
                  </a>
                </p>
              </>
            ),
          });

          throw new Error(
            I18n.get('feArgError', 'Revisá los datos de tu factura')
          );
        }
      } else if (
        ![
          'colombia',
          'costaRica',
          'peru',
          'argentina',
          'panama',
          'republicaDominicana',
          'spain'
        ].includes(country)
      )
        throw new Error(
          I18n.get(
            'electronigInvoiceNotAvailable',
            'Para emitir facturas en POS debes tener una numeración no electrónica'
          )
        );
    }
  };
};

export const payInvoice = (props) => {
  return async (dispatch, getState) => {
    const state = getState();
    const onlyInvoicingPlan = isOnlyInvoicingPlan(state);
    const companyId = idCompanySelector(state);

    let invoice = await dispatch(prepareInvoice(props));
    invoice = await dispatch(checkClient(invoice));
    dispatch(validateInvoice(invoice));

    try {
      if (!!get(invoice, 'stamp.generateStamp') || onlyInvoicingPlan) {
        invoice = ['515516', '385793'].includes(companyId)
          ? await dispatch(oldCreateInvoice(invoice))
          : await dispatch(createInvoice(invoice));
      } else {
        await put(invoice, () => dispatch(syncOffline()));
      }
      await dispatch(removeInvoice());
      dispatch(setPrint({ type: 'invoice', value: invoice }));
    } catch (error) {
      throw handleError(error, {extraInfo: {
        from: 'createInvoice',
        invoice: invoice,
      }});
    }
  };
};
