import { Divider } from '@alegradev/smile-ui-react';
import InvoiceDetailSummary from '../../content/summary';
import InvoiceDetailTable from '../../table';
import AutoInvoiceDetailHeader from './content/header';

const InvoiceDetailAutoInvoiceModal = ({ invoice }) => {
  return (
    <div>
      <Divider />
      <AutoInvoiceDetailHeader invoice={invoice} />
      <InvoiceDetailTable invoice={invoice} />
      <InvoiceDetailSummary invoice={invoice} />
    </div>
  );
};

export default InvoiceDetailAutoInvoiceModal;
