import { Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { Field, useForm, useFormState } from 'react-final-form';
import { renderField } from '../../../../components/forms/fields/V0/Fields';
import { useFormat } from '../../../../hooks/useFormat';
import { get } from 'lodash';
import { useInvoiceDetail } from '../context';
import dayjs from 'dayjs';
import { getPaymentMethod } from '../payments/table/columns';

export const PaymentEditTable = () => {
  const { data } = useInvoiceDetail();
  const form = useForm();
  const { values } = useFormState();
  const { fmt } = useFormat();

  const payments = get(data, 'payments', []) || [];

  return (
    <div className='invoice-edit-table-container'>
      <table className='invoice-edit-table'>
        <thead>
          <tr>
            <th className='payment-number'>
              <div className='d-flex h-100 align-items-center'>
                <Typography
                  text={I18n.get('number', 'Número')}
                  type='label-3'
                />
              </div>
            </th>
            <th className='right payment-date'>
              <Typography text={I18n.get('date', 'Fecha')} type='label-3' />
            </th>
            <th className='right payment-method'>
              <Typography
                text={I18n.get('paymentMethod', 'Método de pago')}
                type='label-3'
              />
            </th>
            <th className='right total'>
              <Typography text={I18n.get('total', 'Total')} type='label-3' />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr className='position-relative w-100'>
            <td className='payment-number'>
              <Typography
                type='body-3-regular'
                text={payments.find((p) => p.paymentMethod === 'cash')?.number}
              />
            </td>
            <td className='payment-date right'>
              <Typography
                type='body-3-regular'
                text={dayjs(
                  payments.find((p) => p.paymentMethod === 'cash')?.date
                ).format('MMM D, YYYY')}
              />
            </td>
            <td className='payment-method right'>
              <Typography
                type='body-3-regular'
                text={getPaymentMethod('cash')}
              />
            </td>
            <td className='px-3'>
              <Field
                name='payments.cash'
                component={renderField}
                type='number'
                placeholder={fmt.prefix}
                hideErrorLabel
                min='0'
                onBlur={() => {
                  if (!get(values, 'payments.cash'))
                    form.change('payments.cash', '0');
                  else if (+get(values, 'payments.cash') < 0)
                    form.change('payments.cash', '0');
                }}
                textAlign='right'
              />
            </td>
          </tr>
          <tr className='position-relative w-100'>
            <td className='payment-number'>
              <Typography
                type='body-3-regular'
                text={
                  payments.find((p) => p.paymentMethod === 'debit-card')?.number
                }
              />
            </td>
            <td className='payment-date right'>
              <Typography
                type='body-3-regular'
                text={dayjs(
                  payments.find((p) => p.paymentMethod === 'debit-card')?.date
                ).format('MMM D, YYYY')}
              />
            </td>
            <td className='payment-method right'>
              <Typography
                type='body-3-regular'
                text={getPaymentMethod('debit-card')}
              />
            </td>
            <td className='px-3'>
              <Field
                name='payments.debit'
                component={renderField}
                type='number'
                placeholder={fmt.prefix}
                hideErrorLabel
                min='0'
                onBlur={() => {
                  if (!get(values, 'payments.debit'))
                    form.change('payments.debit', '0');
                  else if (+get(values, 'payments.debit') < 0)
                    form.change('payments.debit', '0');
                }}
                textAlign='right'
              />
            </td>
          </tr>
          <tr className='position-relative w-100'>
            <td className='payment-number'>
              <Typography
                type='body-3-regular'
                text={
                  payments.find((p) => p.paymentMethod === 'credit-card')
                    ?.number
                }
              />
            </td>
            <td className='payment-date right'>
              <Typography
                type='body-3-regular'
                text={dayjs(
                  payments.find((p) => p.paymentMethod === 'credit-card')?.date
                ).format('MMM D, YYYY')}
              />
            </td>
            <td className='payment-method right'>
              <Typography
                type='body-3-regular'
                text={getPaymentMethod('credit-card')}
              />
            </td>
            <td className='px-3'>
              <Field
                name='payments.credit'
                component={renderField}
                type='number'
                placeholder={fmt.prefix}
                hideErrorLabel
                min='0'
                onBlur={() => {
                  if (!get(values, 'payments.credit'))
                    form.change('payments.credit', '0');
                  else if (+get(values, 'payments.credit') < 0)
                    form.change('payments.credit', '0');
                }}
                textAlign='right'
              />
            </td>
          </tr>
          <tr className='position-relative w-100'>
            <td className='payment-number'>
              <Typography
                type='body-3-regular'
                text={
                  payments.find((p) => p.paymentMethod === 'transfer')?.number
                }
              />
            </td>
            <td className='payment-date right'>
              <Typography
                type='body-3-regular'
                text={dayjs(
                  payments.find((p) => p.paymentMethod === 'transfer')?.date
                ).format('MMM D, YYYY')}
              />
            </td>
            <td className='payment-method right'>
              <Typography
                type='body-3-regular'
                text={getPaymentMethod('transfer')}
              />
            </td>
            <td className='right px-3'>
              <Field
                name='payments.transfer'
                component={renderField}
                type='number'
                placeholder={fmt.prefix}
                hideErrorLabel
                min='0'
                onBlur={() => {
                  if (!get(values, 'payments.transfer'))
                    form.change('payments.transfer', '0');
                  else if (+get(values, 'payments.transfer') < 0)
                    form.change('payments.transfer', '0');
                }}
                textAlign='right'
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentEditTable;
