import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
  electronicInvoicing,
  multicurrency,
  shiftsEnabled,
} from '../../../../selectors/company';
import { useInvoiceDetail } from '../context';
import {
  Icon,
  PageHeading,
  Toast,
  Typography,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { capitalize, get, toUpper } from 'lodash';
import dayjs from 'dayjs';
import { allRegimes } from '../../../../components/countriesData/mexico/regimes';
import {
  activeCurrencies,
  getMainCurrency,
} from '../../../../selectors/currencies';
import { openModal } from '../../../../reducers/modals';
import { sendNewGTMEvent } from '../../../../reducers/company';
import { setPrint, setTypeToPrint } from '../../../../reducers/print';
import { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../../../components/common/Button';
import {
  IconAlertOctagonFilled,
  IconAlertTriangleFilled,
  IconCancel,
  IconCheck,
  IconDotsCircleHorizontal,
  IconFileSymlink,
  IconNote,
  IconPencil,
  IconPrinter,
  IconProgress,
  IconRotate,
  IconShare,
} from '@tabler/icons-react';
import { canEdit, canSendOrPrint } from '../../utils';
import { hasPermissionTo } from '../../../../selectors/auth';
import { Field, useForm, useFormState } from 'react-final-form';
import { COUNTRIES } from '../../../../utils/enums/countries';
import { invoiceClients } from '../../../../selectors/clients';
import { filter } from '../../../../reducers/clients';
import { renderClientName } from '../../../../components/invoices/utils';
import {
  renderDatePickerField,
  renderSelect,
} from '../../../../components/forms/fields/V0/Fields';
import { activeSellers } from '../../../../selectors/sellers';
import { setInvoice } from '../../../../reducers/editInvoice';
import { current, isOpen } from '../../../../selectors/shifts';
import { replaceAndParse } from '../../../../utils';
import { getNumber } from '../../../../utils/invoices';
import { syncOffline } from '../../../../reducers/activeInvoice';
import { canVoid } from '../utils';
import VoidInvoice from '../../components/VoidInvoice';

const InvoiceDetailHeader = ({ updateItemPrice }) => {
  const clients = useSelector(invoiceClients);
  const { isEditable, setIsEditable, data } = useInvoiceDetail();
  const invoice = data;
  const country = useSelector(countrySelector);
  const isMulticurrency = useSelector(multicurrency);
  const mainCurrency = useSelector(getMainCurrency);
  const can = useSelector(hasPermissionTo);
  const currencies = useSelector(activeCurrencies);
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const sellers = useSelector(activeSellers);
  const isShiftEnabled = useSelector(shiftsEnabled);
  const isShiftOpen = useSelector(isOpen);
  const currentShift = useSelector(current);
  const company = useSelector(companySelector);
  const companyElectronic = useSelector(electronicInvoicing);
  const form = useForm();
  const { values } = useFormState();

  const dispatch = useDispatch();

  const getDocumentType = () => {
    return getNumber(invoice, country);
  };

  useEffect(() => {
    if (invoice) {
      dispatch(setPrint({ type: 'invoice', value: data }));
    }
  }, [invoice, dispatch]);

  useEffect(() => {
    form.reset();
    setIsEditable(false);
  }, [data]);

  const getLegalStatus = () => {
    const documentType = get(invoice, 'numberTemplate.documentType');
    const globalInvoiceStamp = get(invoice, 'globalInvoice.stamp', null);
    const invoiceStamp = get(invoice, 'stamp.uuid', null);

    const associatedInvoice = get(invoice, 'associatedInvoice', null);

    if (associatedInvoice) {
      const originApp = get(associatedInvoice, 'originApp', '');
      if (originApp === 'SELF_INVOICING') {
        return (
          <div className='d-flex align-items-center gap-2'>
            <Icon icon={IconFileSymlink} color='#94A3B8' size='small' />
            <Typography type='caption-regular' text='Autofacturado' />
          </div>
        );
      }
    }

    if (documentType === 'saleTicket' && !!globalInvoiceStamp) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCheck} color='#16A34A' size='small' />
          <Typography type='caption-regular' text='Facturado' />
        </div>
      );
    } else if (documentType === 'saleTicket' && !globalInvoiceStamp) {
      return <Typography type='body-3-regular' variant='secondary' text='--' />;
    } else if (documentType === 'invoice' && invoiceStamp) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconCheck} color='#16A34A' size='small' />
          <Typography type='caption-regular' text='Timbrado' />
        </div>
      );
    } else if (documentType === 'invoice' && !invoiceStamp) {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconProgress} color='#94A3B8' size='small' />
          <Typography type='caption-regular' text='En proceso' />
        </div>
      );
    }

    return '--';
  };

  const getInvoiceStatus = () => {
    const { status, offlineStatus } = invoice;
    let icon, text;

    switch (offlineStatus) {
      case 'pending':
        icon = (
          <Icon icon={IconAlertTriangleFilled} color='#FBBF24' size='small' />
        );
        text = I18n.get('pending', 'pendiente');
        break;
      case 'syncing':
        icon = (
          <Icon icon={IconAlertTriangleFilled} color='#FBBF24' size='small' />
        );
        text = I18n.get('syncing', 'syncing');
        break;
      case 'error':
        icon = (
          <Icon icon={IconAlertOctagonFilled} color='#E11D48' size='small' />
        );
        text = I18n.get('error', 'error');
        break;
      default:
        icon =
          status === 'closed' ? (
            <Icon icon={IconCheck} color='#16A34A' size='small' />
          ) : (
            <Icon
              icon={IconDotsCircleHorizontal}
              color='#94A3B8'
              size='small'
            />
          );
        text = I18n.get(`invoiceStatus${status}`, `${status}`);
        break;
    }

    return (
      <div className='d-flex align-items-center gap-2'>
        {icon}
        <Typography type='body-3-regular' text={text} />
      </div>
    );
  };

  const search = useCallback(
    async (text = '') => {
      if (ref.current) {
        setLoading(true);
      }

      if (text.trim() || hasSearched) {
        await dispatch(filter({ text: hasSearched ? '' : text, limit: 35 }));

        if (!hasSearched) {
          setHasSearched(true);
        }
      }

      if (ref.current) {
        setLoading(false);
      }

      setSearchText(text);
    },
    [dispatch, hasSearched]
  );

  const detailActions = () => {
    const actions = [];

    {
      !invoice.offlineStatus &&
        canVoid({
          invoice,
          country,
          company,
          isShiftEnabled,
          isShiftOpen,
          currentShift,
          can,
        }) &&
        !isEditable &&
        actions.push(
          <Button
            type='button'
            variant='ghost'
            size='icon'
            onClick={() => {
              dispatch(openModal({ modal: 'voidInvoice' }));
            }}
          >
            <Icon icon={IconNote} size='small' />
          </Button>
        );
    }

    if (
      !!invoice.offlineStatus &&
      (invoice.offlineStatus === 'error' ||
        invoice.offlineStatus === 'syncing' ||
        (invoice.offlineStatus === 'pending' && invoice.statusInProcess === 1))
    ) {
      actions.push(
        <Button
          type='button'
          variant='ghost'
          size='icon'
          onClick={() => {
            dispatch(setInvoice(invoice));
            dispatch(openModal({ modal: 'editInvoice' }));
          }}
        >
          <Icon icon={IconPencil} size='small' />
        </Button>
      );

      actions.push(
        <Button
          type='button'
          variant='ghost'
          size='icon'
          disabled={
            invoice.offlineStatus !== 'error' &&
            !(
              invoice.offlineStatus === 'pending' &&
              invoice.statusInProcess === 1
            )
          }
          loading={invoice.offlineStatus === 'syncing'}
          onClick={() => dispatch(syncOffline(false, true))}
        >
          <Icon icon={IconRotate} size='small' />
        </Button>
      );
    } else {
      if (canEdit({ invoice, can }) && !isEditable) {
        actions.push(
          <Button
            type='button'
            variant='ghost'
            size='icon'
            onClick={() => setIsEditable(true)}
          >
            <Icon icon={IconPencil} size='small' />
          </Button>
        );
      }
    }

    if (canSendOrPrint({ invoice, country }) && !isEditable) {
      actions.push(
        <Button
          type='button'
          variant='ghost'
          size='icon'
          onClick={() => [
            dispatch(openModal({ modal: 'sendEmail', params: { invoice } })),
            dispatch(
              sendNewGTMEvent('pos-sale-managed', {
                id: get(invoice, 'id', ''),
                action: 'email',
              })
            ),
          ]}
        >
          <Icon icon={IconShare} size='small' />
        </Button>
      );
      actions.push(
        <Button
          type='button'
          variant='ghost'
          size='icon'
          onClick={() => [
            dispatch(setTypeToPrint('invoice')),
            dispatch(
              sendNewGTMEvent('pos-sale-managed', {
                id: get(invoice, 'id', ''),
                action: 'print',
              })
            ),
          ]}
        >
          <Icon icon={IconPrinter} size='small' />
        </Button>
      );
    }

    return actions;
  };

  return (
    <>
      <div className='mb-4'>
        {!!invoice.offlineStatus && invoice.offlineStatus === 'error' && (
          <Toast
            type='error'
            title='Error'
            description={replaceAndParse(invoice.error)}
            closable={false}
            shadow={false}
          />
        )}
        <div className='w-100 d-flex mt-4 mb-5 justify-content-between'>
          <div>
            <Typography
              type='body-1-bold'
              variant='secondary'
              text={getDocumentType()}
              withHtml
            />
            <Typography
              type='body-3-regular'
              variant='tertiary'
              text={I18n.get('', 'Creada el {{date}}').replace(
                '{{date}}',
                dayjs(
                  get(invoice, 'datetime', null) || get(invoice, 'timestamp')
                ).format('DD/MM/YYYY h:mm a')
              )}
            />
          </div>
          <div className='d-flex gap-3'>{detailActions()}</div>
        </div>

        <div className='w-100 d-flex justify-content-between'>
          <Typography
            type='body-1-bold'
            variant='secondary'
            text={I18n.get('', 'Datos generales')}
          />
        </div>
        <div className='row w-100'>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Cliente')}
            />
            <div className='mt-1' />
            {isEditable ? (
              <Field
                name='client'
                className='edit-field'
                height='2.4rem'
                fontSize='12px'
                options={
                  country !== COUNTRIES.MEXICO && can('view', 'contacts')
                    ? clients
                    : []
                }
                isLoading={loading}
                placeholder={toUpper(
                  I18n.get('selectAClient', 'selecciona un cliente')
                )}
                noOptionsMessage={() =>
                  can('view', 'contacts')
                    ? I18n.get(
                        'noResultsWereFound',
                        'No se encontraron resultados.'
                      )
                    : I18n.get(
                        'userNotAllowed.contacts.view',
                        'No tienes permiso para ver el detalle de contacto. Habla con tu administrador para que te habilite el permiso y así puedas usar el POS correctamente.'
                      )
                }
                getOptionLabel={(option) => `${renderClientName(option)}`}
                getOptionValue={(option) => option.id}
                hideDropdownIndicator
                cacheOptions={false}
              >
                {(props) =>
                  renderSelect({
                    ...props,
                    borderRadius: '10px',
                    height: '3.2rem',
                    fontSize: '14px',
                    borderColor: '#94A3B866',
                  })
                }
              </Field>
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={get(invoice, 'client.name')}
              />
            )}
          </div>
          {!isEditable && (
            <>
              <div className='col-md-4 col-sm-12 mt-4'>
                <Typography
                  type='label-2'
                  variant='secondary'
                  text={I18n.get('', 'RFC')}
                />
                <div className='mt-1' />
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={get(invoice, 'client.identification')}
                />
              </div>
              <div className='col-md-4 col-sm-12 mt-4'>
                <Typography
                  type='label-2'
                  variant='secondary'
                  text={I18n.get('regime', 'Régimen')}
                />
                <div className='mt-1' />
                <Typography
                  type='body-3-regular'
                  variant='secondary'
                  text={
                    get(invoice, 'regimeClient')
                      ? allRegimes[get(invoice, 'regimeClient')].label
                      : allRegimes.SIMPLIFIED_REGIME.label
                  }
                />
              </div>
            </>
          )}

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={capitalize(I18n.get('dueDate', 'Fecha de vencimiento'))}
            />
            <div className='mt-1' />
            {isEditable ? (
              <Field
                name='dueDate'
                className='edit-field'
                inputFormat='DD/MM/YYYY'
                component={renderDatePickerField}
                minDate={dayjs(get(invoice, 'dueDate', null))}
                disableMaxDate
              />
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={dayjs(get(invoice, 'dueDate', null)).format('DD/MM/YYYY')}
              />
            )}
          </div>
          {companyElectronic && (
            <div className='col-md-4 col-sm-12 mt-4'>
              <Typography
                type='label-2'
                variant='secondary'
                text={I18n.get('', 'Estado de timbre')}
              />
              <div className='mt-1' />
              {getLegalStatus()}
            </div>
          )}

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('', 'Estado')}
            />
            <div className='mt-1' />
            {getInvoiceStatus()}
          </div>

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('currency', 'Moneda')}
            />
            <div className='mt-1' />

            {isEditable &&
            !get(invoice, 'payments.length') &&
            !!isMulticurrency ? (
              <Field
                name='currency'
                className={`edit-field ${!get(invoice, 'currency.main') ? 'currency' : ''}`}
                options={currencies}
                height='2.4rem'
                fontSize='12px'
                getOptionLabel={(option) => option.code}
                getOptionValue={(option) => option.code}
                hideDropdownIndicator
                showClearIndicator
                onChange={(value) => {
                  const currency = !!value ? value : mainCurrency;
                  if (get(values, 'items.length')) {
                    values.items.map((item, index) => {
                      form.change(`items.${index}`, {
                        ...updateItemPrice(item, { ...values, currency }),
                      });
                      return null;
                    });
                  }
                  return currency;
                }}
              >
                {(props) =>
                  renderSelect({
                    ...props,
                    borderRadius: '10px',
                    height: '3.2rem',
                    fontSize: '14px',
                    borderColor: '#94A3B866',
                  })
                }
              </Field>
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={
                  !!get(invoice, 'currency.code')
                    ? get(invoice, 'currency.code')
                    : get(mainCurrency, 'code')
                }
              />
            )}
          </div>

          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('seller', 'Vendedor')}
            />
            <div className='mt-1' />
            {isEditable ? (
              <Field
                name='seller'
                component={renderSelect}
                options={sellers}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                hideDropdownIndicator
                showClearIndicator
                height='3.2rem'
                borderRadius='10px'
                borderColor='#94A3B866'
              />
            ) : (
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={get(invoice, 'seller.name', '--')}
              />
            )}
          </div>
          <div className='col-md-4 col-sm-12 mt-4'>
            <Typography
              type='label-2'
              variant='secondary'
              text={I18n.get('shiftNumber', 'Número de turno')}
            />
            <div className='mt-1' />
            <Typography
              type='body-3-regular'
              variant='secondary'
              text={get(invoice, 'idShift', '').toString()}
            />
          </div>
          {!!get(invoice, 'stamp.uuid') && (
            <div className='col-md-8 col-sm-12 mt-4'>
              <Typography
                type='label-2'
                variant='secondary'
                text={I18n.get('', 'Folio Fiscal')}
              />
              <div className='mt-1' />
              <Typography
                type='body-3-regular'
                variant='secondary'
                text={get(invoice, 'stamp.uuid', '').toString()}
              />
            </div>
          )}
        </div>
      </div>
      <div className='mb-4 mt-5'>
        <Typography
          type='body-1-bold'
          variant='secondary'
          text={I18n.get(
            'productsAndServicesSold',
            'Productos y servicios vendidos'
          )}
        />
      </div>

      <VoidInvoice invoice={invoice} setVoidLoading={setLoading} />
    </>
  );
};

export default InvoiceDetailHeader;
