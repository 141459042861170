import { capitalize } from 'lodash';
import { I18n } from '@aws-amplify/core';
import { TableCellRegular } from '@alegradev/smile-ui-react';
import { formatMoney } from '../../../../utils';

export const providerPaymentColumns = [
  {
    Header: capitalize(I18n.get('bankAccount', 'Cuenta bancaria')),
    accessor: 'bankAccountName',
    width: '50%',
    Cell: ({ value }) => <TableCellRegular value={value} />,
  },
  {
    Header: capitalize(I18n.get('value', 'Valor')),
    accessor: 'total',
    width: '50%',
    Cell: ({ value }) => <TableCellRegular value={formatMoney(value)} />,
  },
];
