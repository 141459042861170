import { Button, Icon, Typography } from '@alegradev/smile-ui-react';
import { IconPencil } from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import { useForm } from 'react-final-form';
import { useInvoiceDetail } from '../context';

export const InvoiceEditableFooter = ({ updateInvoiceLoading }) => {
  const { isEditable, setIsEditable } = useInvoiceDetail();
  const form = useForm();

  if (!isEditable) {
    return null;
  }
  return (
    <div className='invoice-detail-editable-footer'>
      <div className='d-flex align-items-center gap-2'>
        <Typography
          text='Editando factura'
          type='caption-regular'
          variant='secondary'
        />
        <Icon icon={IconPencil} size='small' />
      </div>
      <div className='d-flex align-items-center gap-3'>
        <Button
          type='button'
          emphasis='outline'
          text={I18n.get('cancel', 'cancelar')}
          onClick={() => {
            form.reset();
            setIsEditable(false);
          }}
          size='small'
        />
        <Button
          type='submit'
          text={I18n.get('save', 'Guardar')}
          size='small'
          loading={updateInvoiceLoading}
        />
      </div>
    </div>
  );
};

export default InvoiceEditableFooter;
