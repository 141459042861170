import {
  Divider,
  Table,
  Typography,
  useModal,
} from '@alegradev/smile-ui-react';
import { useEffect, useMemo, useState } from 'react';
import { useInvoiceDetail } from '../../context';
import { get } from 'lodash';
import InvoiceDetailAutoInvoicesTableColumns from './columns';
import { I18n } from 'aws-amplify';
import { useFormat } from '../../../../../hooks/useFormat';

const InvoiceDetailAutoInvoicesTable = () => {
  const { data } = useInvoiceDetail();
  const [associatedInvoice, setAssociatedInvoice] = useState({});
  const { openModal, closeModal } = useModal();
  const { fmt, decimal } = useFormat();

  const columns = useMemo(
    () =>
      InvoiceDetailAutoInvoicesTableColumns(
        decimal,
        fmt,
        openModal,
        closeModal,
        data
      ),
    [decimal, fmt, openModal, closeModal, data]
  );
  const validateIfHasSelfInvoices = (data) => {
    const associatedInvoice = get(data, 'associatedInvoice', {});

    if (associatedInvoice?.originApp !== 'SELF_INVOICING') {
      setAssociatedInvoice(null);
      return false;
    }
    setAssociatedInvoice(associatedInvoice);
    return true;
  };

  useEffect(() => {
    validateIfHasSelfInvoices(data);
  }, [data]);

  if (associatedInvoice?.originApp !== 'SELF_INVOICING') {
    return null;
  }

  return (
    <div className='mt-5'>
      <Typography
        type='label-2'
        text={I18n.get(
          'selfInvoicesByClient',
          'Facturas autogestionadas por el cliente'
        )}
        variant='secondary'
      />
      <Divider />
      <Table
        columns={columns}
        data={[
          {
            number: get(associatedInvoice, 'numberTemplate.fullNumber', ''),
            client: {
              name: get(data, 'client.name', ''),
            },
            status: 'Timbrado',
            amount: get(associatedInvoice, 'totalPaid', 0),
          },
        ]}
        simple
      />
    </div>
  );
};

export default InvoiceDetailAutoInvoicesTable;
