import { I18n } from 'aws-amplify';
import { get, lowerCase } from 'lodash';
import { handleError } from '../../../utils/errors';

const ERROR_CODES = {
  TERMINAL_WITH_SHIFTS: 'P01005',
};
export const getTerminalErrorTitle = (error) => {
  const errorCode = get(error, 'errors[0].errorInfo.code', '');
  if (errorCode === ERROR_CODES.TERMINAL_WITH_SHIFTS) {
    return I18n.get(
      'terminalHasAssociatedShifts',
      'Esta terminal tiene turnos asociados'
    );
  }
  return I18n.get('stationDeletedError', 'error eliminando terminal');
};

export const getTerminalErrorMessage = (error) => {
  const errorCode = get(error, 'errors[0].errorInfo.code', '');
  if (errorCode === ERROR_CODES.TERMINAL_WITH_SHIFTS) {
    return I18n.get(
      'terminalHasAssociatedShifts.message',
      'Solo puedes eliminar terminales en las que no hayas usado la función de turnos.'
    );
  }
  return handleError(error).replace(
    'terminal',
    lowerCase(I18n.get('station', 'terminal'))
  );
};
