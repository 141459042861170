import { useDispatch, useSelector } from 'react-redux';
import { ContactProvider } from '../contact/ContactProvider';
import { ContactForm } from '../contact/form';

import { closeSideModal } from '../../../reducers/sideModals';

import { get } from 'lodash';
import { SlideOver } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { country as countrySelector } from '../../../selectors/company';
import { useMemo } from 'react';

function ContactSideModal() {
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const isOpen = useSelector((state) =>
    get(state, 'sideModals.contact.isOpen', false)
  );
  const sidebarContact = useSelector((state) =>
    get(state, 'sideModals.contact.params.contact', null)
  );
  const sidebarFrom = useSelector((state) =>
    get(state, 'sideModals.contact.params.from', null)
  );
  const saveOnOffline = useSelector((state) =>
    get(state, 'sideModals.contact.params.saveOnOffline', null)
  );

  const handleSeeMore = () => {
    const LINK =
      country === 'colombia'
        ? 'https://ayuda.alegra.com/es/gestiona-tus-contactos-en-el-sistema-punto-de-venta-pos-colombia'
        : 'https://ayuda.alegra.com/es/gestiona-tus-contactos-en-alegra-pos';

    window.open(LINK, '_blank');
  };

  const title = useMemo(() => {
    return sidebarContact &&
      sidebarContact?.status === 'active' &&
      !sidebarContact?.ignoreRepeated
      ? I18n.get('editClient', 'Editar contacto')
      : I18n.get('newContact', 'Nuevo contacto');
  }, [sidebarContact]);

  const description = useMemo(() => {
    return sidebarContact &&
      sidebarContact?.status === 'active' &&
      !sidebarContact?.ignoreRepeated
      ? ''
      : I18n.get(
          'newClientSubtitle',
          'Crea los contactos que asociarás en tus facturas de venta.'
        );
  }, [sidebarContact]);

  return (
    <SlideOver
      isOpen={isOpen}
      title={title}
      description={description}
      descriptionExtra={{
        label: I18n.get('seeMore', 'Ver más'),
        onClick: handleSeeMore,
      }}
      onClose={() => dispatch(closeSideModal({ sideModal: 'contact' }))}
    >
      <ContactProvider from='sideModal'>
        <ContactForm
          saveOnOffline={saveOnOffline === 'false' ? false : true}
          from={sidebarFrom || 'sideModal'}
        />
      </ContactProvider>
    </SlideOver>
  );
}

export default ContactSideModal;
